import React, {Component} from 'react';
import Loader from "./Loader";
import {DEFAULT_ADDRESS, DEFAULT_EMAIL, DEFAULT_FAX, DEFAULT_PHONE, DEFAULT_TIMING, DEFAULT_TITLE} from "../constants";

class MembershipInfoAddress extends Component {
    render() {
        let settings = this.props.settings;
        let phone = settings ? "tel:" + settings.phone : "tel:" + DEFAULT_PHONE;
        let fax = settings ? "tel:" + settings.fax : "tel:" + DEFAULT_FAX;
        let email = settings ? "mailto:" + settings.email : "mailto:" + DEFAULT_EMAIL;
        return (
            <div>
                <section className="member-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Please Mail Completed Application Form To</h2>
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        <a href="#">{settings ? settings.translations[0].title + ", "
                                            + settings.translations[0].address : DEFAULT_TITLE + ", " + DEFAULT_ADDRESS}</a>
                                    </li>
                                    {/* <li>
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <span>Phone: </span>
                                        <a href={phone}>{settings ? settings.phone : DEFAULT_PHONE}</a>
                                    </li>*/}
                                    <li>
                                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                                        <a href="#">{settings ? settings.timing : DEFAULT_TIMING}</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-fax" aria-hidden="true"></i>
                                        <span>Phone: </span>
                                        <a href="#"> {settings ? settings.fax : DEFAULT_FAX}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default MembershipInfoAddress;