import React, {Component} from 'react';
import {toast} from "react-toastify";
import connect from "react-redux/es/connect/connect";
import {getMiscellaneousServices} from "../actions/general";
import {getDentist, updateDentist} from "../actions/user";
import Loader from "./Loader";
import {DAYS, MONTHS} from "../constants";

class DentistRegistrationFive extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            agreed: 1
        }
    }

    componentDidMount() {
        this.props.getDentist(this.props.user.dentist_id);

    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.user.dentist_id) {
            toast.error('unable to proceed your request');
        } else {
            this.props.updateDentist(this.props.user.dentist_id, this.state, 6);
        }
    }


    handleChangeText(key, txt) {
        this.setState({[key]: txt});
    }

    render() {
        let date = new Date();
        let dentist = this.props.dentist || null;
        if (dentist == null) {
            return <Loader/>;
        }
        return (
            <section className="sec-reg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm">MDP Participating Agreement</label>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="check-clr lin-22">WHEREAS, PLAN has organized a dental
                                            treatment program for both individuals and
                                            groups to provide access to a quality dental program for the benefit of
                                            members
                                            (the term MEMBER as hereinafter used in this AGREEMENT shall be deemed to
                                            include any eligible dependents); and WHEREAS, each MEMBER has entered into
                                            an
                                            AGREEMENT with the PLAN to receive the benefits conferred by Membership to
                                            the PLAN.
                                        </label>

                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm mrgn-top">NOW, THEREFORE, it is agreed as
                                            follows:</label>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="terms">
                                            <label className="check-clr lin-22">1. That the DENTIST agrees to require
                                                that all employees of DENTIST and all
                                                partners, associates, supervisors, and personnel under his control,
                                                render
                                                services to MEMBERS in accordance with this AGREEMENT.</label>
                                            <label className="check-clr lin-22">2. That the DENTIST agrees to perform
                                                all necessary dental services which he customarily renders, to each
                                                MEMBER during his regular office hours, subject to a prior appointment;
                                                provided, however, that DENTIST shall have the right within the
                                                framework of professional ethics to reject any patient seeking his
                                                services. If DENTIST should be absent from his practice for any reason
                                                for longer than ninety (90) days, the PLAN may terminate this AGREEMENT
                                                upon ten (10) days notice by certified or registered mail.</label>
                                            <label className="check-clr lin-22">3. That the DENTIST agrees to perform
                                                his obligations under this AGREEMENT in
                                                accordance with high standards of competence, care and concern for the
                                                welfare and needs of MEMBERS, who seek his professional services and in
                                                accordance with the “principles of ethics of the American Dental
                                                Association.
                                                " DENTIST further agrees not to differentiate or discriminate in the
                                                treatment of
                                                MEMBER patients by reason of the fact that they are MEMBERS.</label>
                                            <label className="check-clr lin-22">4. That the DENTIST shall maintain the
                                                Dentist-Patient relationship with MEMBERS who seek his professional
                                                services, and shall be responsible to the patient for
                                                quality dental advice and treatment. The parties hereto agree that the
                                                DENTIST is
                                                an independent contractor and that the PLAN shall not have any control
                                                over
                                                DENTIST’S practice, his personnel, or his facilities</label>
                                            <label className="check-clr lin-22">5. The PLAN shall be responsible for
                                                determining a person’s eligibility prior to their becoming a
                                                MEMBER</label>
                                            <label className="check-clr lin-22">6. That the DENTIST shall perform all
                                                services as required pursuant to the
                                                AGREEMENT and DENTIST agrees that all payments for all said services
                                                rendered
                                                by DENTIST to any MEMBER are required to be paid directly by the MEMBER.
                                                The
                                                DENTIST agrees to look solely to the MEMBER for payment and to bill at
                                                rates not to
                                                exceed those set forth in the schedule attached hereto. If any service
                                                provided to
                                                MEMBER by the DENTIST is not listed in said schedule, DENTIST hereby
                                                agrees to bill
                                                for said service at a rate not in excess of his usual and customary fee.
                                                No fee shall
                                                be due from DENTIST to PLAN with respect to the services required
                                                pursuant to this AGREEMENT.</label>
                                            <label className="check-clr lin-22">7. That the DENTIST will charge for his
                                                services at rates not to exceed those set forth in the attached
                                                schedule</label>
                                            <label className="check-clr lin-22">8. That the DENTIST hereby agrees that
                                                in the event of any unresolved dispute for payment claimed byDENTIST,
                                                under no circumstances will DENTIST make or have
                                                any claim against the PLAN.</label>
                                            <label className="check-clr lin-22">9. That the attached fee schedule may be
                                                revised annually by the PLAN to reflect increased costs of dental care.
                                                Such revision will be in the PLAN'S sole discretion.
                                                The PLAN agrees to notify the DENTIST in writing of the nature and
                                                extent of such
                                                revision.</label>
                                            <label className="check-clr lin-22">10. That this AGREEMENT is not exclusive
                                                in any respect, and the PLAN and the
                                                MEMBERS are entitled to enter into similar contracts with other dentists
                                                and
                                                DENTIST is free to enter into similar contracts with other parties, or
                                                with other
                                                groups not represented by the PLAN, and to maintain his private
                                                practice</label>
                                            <label className="check-clr lin-22">11. That DENTIST agrees to notify
                                                MAINLINE DENTAL PLAN, INC. in the event that he/she receives notice of
                                                any type of claim or litigation within thirty (30) days of
                                                receipt of such action brought by a PLAN MEMBER.</label>
                                            <label className="check-clr lin-22">12. That DENTIST agrees to carry
                                                Malpractice Insurance coverage for his/her
                                                practice in an amount not less than $1,000,000 per incident. DENTIST
                                                further
                                                agrees to provide the PLAN with a "Certificate of Insurance" which
                                                provides for ten
                                                (10) days notice of cancellation to the PLAN and agrees to indemnify and
                                                hold
                                                harmless PLAN or any of its agents from all liabilities, costs, and
                                                expenses that may
                                                be incurred by the PLAN or its agents in connection with any controversy
                                                proceeding
                                                or litigation arising from the execution of the AGREEMENT with DENTIST
                                                or arising from
                                                DENTIST'S performance of this AGREEMENT.</label>
                                            <label className="check-clr lin-22">13. That the PLAN shall appoint a Dental
                                                Director who will be responsible for
                                                assuring the standards of dentists who provide dental care to MEMBERS,
                                                and
                                                who will be a liaison between the PLAN and the DENTIST.</label>
                                            <label className="check-clr lin-22">14. That this AGREEMENT shall continue
                                                in effect until terminated by wither party upon sixty (60) days notice
                                                by registered or certified mail, except in cases of
                                                termination upon ten (10) days notice under Paragraph 2. Notices shall
                                                be mailed
                                                to DENTIST at the address set forth herein and to PLAN at such address
                                                as shall be
                                                designated by PLAN from time to time.</label>
                                            <label className="check-clr lin-22">15. That in the event this AGREEMENT is
                                                terminated by either party in accordance with the procedure set forth
                                                herein. DENTIST agrees that at the time the patient
                                                seeks an appointment, he will notify each patient who is a MEMBER prior
                                                to giving
                                                service that the DENTIST is no longer affiliated with the PLAN.</label>
                                            <label className="check-clr lin-22">16. That this AGREEMENT is intended to
                                                secure the personal services of DENTIST and shall not be assigned or
                                                transferred by DENTIST without the written consent
                                                of the PLAN.</label>
                                            <label className="check-clr lin-22">17. That a waiver of any breach of this
                                                AGREEMENT or of any of the terms or
                                                conditions by either party shall not be deemed a waiver of any
                                                repetition of such
                                                breach or in any way affect any other terms or conditions. No waiver
                                                shall be valid
                                                or binding unless it shall be in writing and signed by the
                                                parties.</label>
                                            <label className="check-clr lin-22">18. All written notices shall be deemed
                                                to have been made at the time of posting, and shall be sent to the
                                                following addresses as set forth below.</label>
                                            <label className="check-clr lin-22">19. That this AGREEMENT embodies the
                                                entire understanding of the parties and
                                                may be amended only in writing.</label>
                                            <label className="check-clr lin-22">20. That if any one or more of the
                                                provisions of this AGREEMENT should be found to be invalid, illegal, or
                                                unenforceable in any respect, the validity, legality and
                                                enforceability of the remaining provisions shall not in any way be
                                                affected or
                                                impaired. This AGREEMENT shall be governed by the laws of the State of
                                                New Jersey</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-top mrgn-btm">MDP Participating Agreement</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={DAYS[date.getDay()]}
                                               disabled/>
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <label className="mrgn-top-10"> day of</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={MONTHS[date.getMonth()]}
                                               disabled/>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={date.getFullYear()}
                                               disabled/>
                                    </div>
                                    {/* <div className="col-md-1 text-center">
                                        <label className="mrgn-top-10 ">at</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder=""/>
                                    </div>
                                      <div className="col-md-2 text-center">
                                        <label className="mrgn-top-10 ">New Jersey</label>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-top mrgn-btm">MAINLINE DENTAL PLAN, INC.</label>
                                    </div>
                                    <div className="col-md-1 text-center">
                                        <label className="mrgn-top-10 ">By:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="def" type="text" name="" placeholder=""/>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <label className="mrgn-top-10 ">Ryan C. Maher, President</label>
                                    </div>
                                    <div className="col-md-12 brdr"></div>*/}
                                    <div className="col-md-12">
                                        <label className="main mrgn-top mrgn-btm">DENTIST</label>
                                    </div>
                                    <div className="col-md-1 text-center">
                                        <label className="mrgn-top-10 ">By:</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder="" value={dentist.name}
                                               disabled/>
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <label className="mrgn-top-10 "> Print Name:</label>
                                    </div>
                                    <div className="col-md-5">
                                        <input className="def" type="text" name="" placeholder="" value={dentist.name}
                                               disabled/>
                                    </div>
                                    <div className="col-md-3 text-left">
                                        <label className="mrgn-top-10 ">Address</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={dentist.details.address}
                                               disabled/>
                                    </div>
                                    <div className="col-md-4 text-left">
                                        <label className="mrgn-top-10 ">Zip Code: </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input className="def" type="number" name="" placeholder=""
                                               value={dentist.details.zipcode}
                                               disabled/>
                                    </div>
                                    <div className="col-md-4 text-left">
                                        <label className="mrgn-top-10 ">Telephone: </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input className="def" type="number" name="" placeholder=""
                                               value={dentist.details.phone}
                                               disabled/>
                                    </div>

                                    <div className="field">
                                        {this.props.dentistDeleteRequest ?
                                            <i className="fas fa-spinner fa-spin custom-spinner"></i>
                                            : <input type="submit" name="login" value="Submit"
                                                     className="submit"/>}

                                    </div>
                                </div>
                                {/*form row end -->*/}
                            </form>
                        </div>
                        <div className="col-md-5">
                            <div className="image">
                                <img src={require("../../images/reg-5.png")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            dentist: state.user.dentist,
            request: state.user.updateDentistRequest,
            success: state.user.updateDentistSuccess || false,
            failed: state.user.updateDentistFailed,
            error: state.user.error,
            successMsg: "",
            dentistDeleteRequest: state.user.dentistDeleteRequest
        };
    },
    {updateDentist, getDentist}
)(DentistRegistrationFive);