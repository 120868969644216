import React, {Component} from 'react';
import {connect} from "react-redux"
import {login, socailLogin} from "../actions/user";
import {NavLink} from 'react-router-dom';
import Alerts from "../components/Alerts";
import SocialButton from '../components/SocialButton';
import {GoogleLogin} from 'react-google-login';
import LoginSignupHeader from "../components/loginSignupHeader";
import "../../css/custom.css";
import "../../css/rwd.css";

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleChangeEmail = this.handleChangeText.bind(this, "email");
        this.handleChangePassword = this.handleChangeText.bind(this, "password");
        this.handleLogin = this.handleLogin.bind(this);
        this.state = {
            email: "",
            password: "",
        }
    }

    responseGoogle = (response) => {
        let data = {
            "platform": 'google',
            "client_id": response.profileObj.googleId,
            "token": response.tokenObj.access_token,
            "username": response.profileObj.name,
            "email": response.profileObj.email,
            "image": response.profileObj.imageUrl,
            "device_type": "web",
            // "expires_at": user.token.expiresAt
        }
        this.props.socailLogin(data);
    }
    handleSocialLogin = (user) => {
        let data = {
            "platform": user.provider,
            "client_id": user.profile.id,
            "token": user.token.accessToken,
            "username": user.profile.name,
            "email": user.profile.email,
            // "image": user.profile.profilePicURL,
            "image": "https://graph.facebook.com/" + user.profile.id + "/picture?type=large&width=150&height=150",
            "device_type": "web",
            // "expires_at": user.token.expiresAt
        }
        this.props.socailLogin(data);

    }

    handleSocialLoginFailure = (err) => {
        console.error('failure:', err)
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleLogin(e) {
        e.preventDefault();
        let email = this.state.email;
        let pass = this.state.password;
        if (email.length > 0 && pass.length > 0) {
            this.setState({alert: false, message: ''})
            this.props.login(this.state.email, this.state.password);
        } else {
            this.setState({alert: true, message: 'Email and Password required'})
        }

        return false;
    }

    render() {
        return (
            <>
                <LoginSignupHeader/>
                <section className="sec-login">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 padright">
                                <div className="lf">
                                    <img src={require("../../images/acount-banner.png")}/>
                                </div>
                            </div>


                            <div className="col-md-7 padleft">
                                <div className="rt">
                                    <div className="inner">
                                        <NavLink to="/"><img className="logo"
                                                             src={require("../../images/logo.png")}/></NavLink>
                                        <h2>Welcome to Login</h2>
                                        {this.state.alert &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.state.message
                                        }]}/>}
                                        {this.props.failed &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.props.error.error.errors[0].message
                                        }]}/>}
                                        {this.props.error &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.props.error.message
                                        }]}/>}
                                        <form onSubmit={this.handleLogin}>
                                            <div className="field user">
                                                <input name="email" type="email" value={this.state.email}
                                                       onChange={this.handleChangeEmail} placeholder="Email" required/>
                                            </div>
                                            <div className="field pass">
                                                <input name="password" type="password" value={this.state.password}
                                                       onChange={this.handleChangePassword} placeholder="Password"
                                                       required/>
                                            </div>
                                            <div className="field">
                                                <a className="rem" href="#"><img
                                                    src={require("../../images/remember.png")}/>Remember
                                                    Me</a><NavLink to="/forgot-password" className="forgot">Forget your
                                                password?</NavLink>
                                            </div>
                                            <div className="field">
                                                {this.props.user.request ?
                                                    <input type="submit" name="submitting" value="submitting..."
                                                           className="btn_style"/> :
                                                    <input type="submit" name="login" value="Login"/>
                                                }
                                            </div>
                                            {/* <div className="field">
                                                <p>Don’t have an account?<NavLink to="/register">Sign Up</NavLink></p>
                                            </div>*/}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            request: state.user.socailLoginRequest,
            success: state.user.socailLoginSuccess,
            failed: state.user.socailLoginFailed,
            error: state.user.error,
            successMsg: state.user.socailLoginMsg
        };
    },
    {login, socailLogin}
)(Login);