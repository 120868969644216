import React, {Component} from 'react';
import {
    FAMILY,
    MEMBER_SPOUSE,
    MEMBERSHIP_TYPES,
    PARENT_CHILD,
    SINGLE_MEMBER,
    SUBSCRIPTION_TYPES,
    ANNUAL_SUBSCRIPTION
} from "../constants";
import {toast} from "react-toastify";
import connect from "react-redux/es/connect/connect";
import Loader from "./Loader";
import PaymentInfo from "./PaymentInfo";
import {verifyCoupon} from "../actions/general";

class SelectMemberShip extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCoupon = this.verifyCoupon.bind(this);
        this.applyDefaultCoupon = this.applyDefaultCoupon.bind(this);
        this.state = {
            amount: 0,
            membership_type: SINGLE_MEMBER,
            membership_id: 0,
            coupon_code: '',
            is_coupon_valid: false,
            months: SUBSCRIPTION_TYPES[ANNUAL_SUBSCRIPTION],
            discount_percentage: 10, 
            is_coupon_taken: false
        }
    }

    async handleMemberShipId(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        let subscription = await this.props.subscriptions.filter((subscription) => {
            return subscription.id == txt.target.value
        })
        //await this.verifyCoupon(null);
        subscription = subscription[0];
        state.months = SUBSCRIPTION_TYPES[subscription.subscription_type]
        let amount = 0;
        let discount_amount = 0;
        let membership_type = await MEMBERSHIP_TYPES[this.state.membership_type];
        if (membership_type == MEMBERSHIP_TYPES[SINGLE_MEMBER]) {
            amount = this.state.is_coupon_valid ? subscription.single_member - (subscription.single_member * this.state.discount_percentage) / 100 : subscription.single_member
            discount_amount = (subscription.single_member * this.state.discount_percentage) / 100
        } else if (membership_type == MEMBERSHIP_TYPES[MEMBER_SPOUSE]) {
            amount = this.state.is_coupon_valid ? subscription.member_spouse - (subscription.member_spouse * this.state.discount_percentage) / 100 : subscription.member_spouse
            discount_amount = (subscription.member_spouse * this.state.discount_percentage) / 100
        } else if (membership_type == MEMBERSHIP_TYPES[PARENT_CHILD]) {
            amount = this.state.is_coupon_valid ? subscription.parent_child - (subscription.parent_child * this.state.discount_percentage) / 100 : subscription.parent_child
            discount_amount = (subscription.parent_child * this.state.discount_percentage) / 100
        } else if (membership_type == MEMBERSHIP_TYPES[FAMILY]) {
            amount = this.state.is_coupon_valid ? subscription.family - (subscription.family * this.state.discount_percentage) / 100 : subscription.family
            discount_amount = (subscription.family * this.state.discount_percentage) / 100
        }
        state.amount = amount.toFixed(2);
        state.discount_amount = discount_amount.toFixed(2);
        this.setState(state);
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    async handleMemberShipType(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        let new_type = txt.target.value;
        let subscription = await this.props.subscriptions.filter((subscription) => {
            return subscription.id == this.state.membership_id
        })
        //await this.verifyCoupon(null);
        subscription = subscription[0];
        let amount = 0;
        let discount_amount = 0;

        if (new_type == SINGLE_MEMBER) {
            amount = this.state.is_coupon_valid ? subscription.single_member - (subscription.single_member * this.state.discount_percentage) / 100 : subscription.single_member
            discount_amount = (subscription.single_member * this.state.discount_percentage) / 100
        } else if (new_type == MEMBER_SPOUSE) {
            amount = this.state.is_coupon_valid ? subscription.member_spouse - (subscription.member_spouse * this.state.discount_percentage) / 100 : subscription.member_spouse
            discount_amount = (subscription.member_spouse * this.state.discount_percentage) / 100
        } else if (new_type == PARENT_CHILD) {
            amount = this.state.is_coupon_valid ? subscription.parent_child - (subscription.parent_child * this.state.discount_percentage) / 100 : subscription.parent_child
            discount_amount = (subscription.parent_child * this.state.discount_percentage) / 100
        } else if (new_type == FAMILY) {
            amount = this.state.is_coupon_valid ? subscription.family - (subscription.family * this.state.discount_percentage) / 100 : subscription.family
            discount_amount = (subscription.family * this.state.discount_percentage) / 100
        }
        state.amount = amount.toFixed(2);
        state.discount_amount = discount_amount.toFixed(2);
        this.setState(state);
    }

    verifyCoupon(e) {
        if (e) {
            e.preventDefault()
        }
        if (this.state.coupon_code.length > 0) {
            this.props.verifyCoupon({code: this.state.coupon_code});
        } else {
            toast.error('Enter coupon code.')
        }
        /*if (this.state.coupon_code != "web2011") {
            this.setState({is_coupon_valid: false})
            toast.error('Invalid Coupon Code. Please Try Another.')
        } else {
            this.setState({is_coupon_valid: true})
        }*/
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.stripe(this.state.membership_info);
    }
    async handleCouponMultipleTime(discountPercentage) {
        let state = {};
        let subscription = await this.props.subscriptions.filter((subscription) => {
            return subscription.id == this.state.membership_id
        })
        subscription = subscription[0];
        console.log('subscription', subscription);
        let new_type = this.state.membership_type;
        let discount_percentage = discountPercentage;
        let amount = 0;
        let discount_amount = 0;
        if (new_type == SINGLE_MEMBER) {
            amount = this.state.is_coupon_valid ? subscription.single_member - (subscription.single_member * discount_percentage) / 100 : subscription.single_member
            discount_amount = (subscription.single_member * discount_percentage) / 100
        } else if (new_type == MEMBER_SPOUSE) {
            amount = this.state.is_coupon_valid ? subscription.member_spouse - (subscription.member_spouse * discount_percentage) / 100 : subscription.member_spouse
            discount_amount = (subscription.member_spouse * discount_percentage) / 100
        } else if (new_type == PARENT_CHILD) {
            amount = this.state.is_coupon_valid ? subscription.parent_child - (subscription.parent_child * discount_percentage) / 100 : subscription.parent_child
            discount_amount = (subscription.parent_child * discount_percentage) / 100
        } else if (new_type == FAMILY) {
            amount = this.state.is_coupon_valid ? subscription.family - (subscription.family * discount_percentage) / 100 : subscription.family
            discount_amount = (subscription.family * discount_percentage) / 100
        }
        this.setState({
            is_coupon_valid: true,
            discount_percentage: discount_percentage,
            is_coupon_taken: true,
            amount: amount.toFixed(2),
            discount_amount: discount_amount.toFixed(2)
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.subscriptions && nextProps.subscriptions) {
            this.setState({
                amount: nextProps.subscriptions[0].single_member,
                membership_id: nextProps.subscriptions[0].id,
                discount_amount: 0
            })
        }
        if (!this.props.success && nextProps.success && nextProps.verify_coupon) {
            let couponUsed = this.state.is_coupon_taken;
            if(couponUsed) {
                setTimeout(() => {
                    this.handleCouponMultipleTime(nextProps.verify_coupon.value) 
                }, 500);
            } else {
                let prev_amount = this.state.amount;
                let calculate_amount = prev_amount - ((this.state.amount * nextProps.verify_coupon.value) / 100);
                let diiscount_amount = (this.state.amount * nextProps.verify_coupon.value) / 100;
                this.setState({
                    is_coupon_valid: true,
                    discount_percentage: nextProps.verify_coupon.value,
                    is_coupon_taken: true,
                    amount: calculate_amount.toFixed(2),
                    discount_amount: diiscount_amount.toFixed(2)
                })
            }
            toast('coupon verified successfully.')
        } else if (!this.props.failed && nextProps.failed) {
            this.setState({is_coupon_valid: false})
            toast.error('Invalid Coupon Code. Please Try Another.')
        }
    }

    applyDefaultCoupon(e) {
        let prev_amount = this.state.amount;
        if (!this.state.is_coupon_valid && prev_amount > 0 && !this.props.failed) {
            let amount = prev_amount - ((this.state.amount * 10) / 100);
            let discount_amount = (this.state.amount * 10) / 100
            this.setState({
                is_coupon_valid: true,
                discount_percentage: 10,
                is_coupon_taken: true,
                amount: amount.toFixed(2),
                discount_amount: discount_amount.toFixed(2)
            })
        }
    }

    render() {
        //this.applyDefaultCoupon()
        let subscriptions = this.props.subscriptions || null;
        if (subscriptions == null) {
            return <Loader/>;
        }
        let plans = subscriptions.map((plan, key) => {
            return (
                <option value={plan.id} key={key}>{plan.name}</option>
            );
        });
        return (
            <>
                <section className="member-1 mem-det">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect2">Subscription</label>
                                        <select className="form-control"
                                                id="exampleFormControlSelect2"
                                                onChange={this.handleMemberShipId.bind(this, 'membership_id')}>
                                            {plans}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Membership Type</label>
                                        <select className="form-control"
                                                id="exampleFormControlSelect1"
                                                onChange={this.handleMemberShipType.bind(this, 'membership_type')}
                                                value={this.state.membership_type}>
                                            <option value={SINGLE_MEMBER}>Single Member</option>
                                            <option value={MEMBER_SPOUSE}>Member Spouse</option>
                                            <option value={PARENT_CHILD}>Parent Child</option>
                                            <option value={FAMILY}>Family</option>
                                        </select>
                                    </div>

                                    {/* <div className="form-group">
                                        <label>Referral Code </label>
                                        <input type="text" className="form-control"
                                               value={this.state.coupon_code}
                                               onChange={this.handleChangeText.bind(this, 'coupon_code')}/>
                                        <div className="btn_apply">
                                            {this.props.request ?
                                                <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                                <a href="#" onClick={this.verifyCoupon}>
                                                    Apply
                                                </a>}
                                        </div>
                                    </div> */}
                                    <div className="row m-0 total_new">
                                        <div className="col-md-6">
                                            <div className="tit_mem">Membership Total:</div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="tit_price">${typeof this.state.amount === 'number' ? this.state.amount.toFixed(2) : this.state.amount} / {this.state.months}</div>
                                        </div>
                                    </div>

                                    {this.state.is_coupon_valid ? <div className="row m-0 total_new">
                                            <div className="col-md-12">
                                                Discount Applied:
                                                -${this.state.discount_amount} ({this.state.discount_percentage}% OFF)
                                            </div>
                                        </div>
                                        : null}

                                    {/* <div className="field">
                                        {this.state.amount == 0 ?
                                            <input type="submit" name="login" value="Register Now"
                                                   className="submit" disabled/> :
                                            <input type="submit" name="login" value="Register Now"
                                                   className="submit"/>}

                                    </div>*/
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <PaymentInfo
                    membership_info={this.state}
                    coupon={this.state.coupon_code}
                />
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            subscriptions: state.subscriptions.subscriptions,
            request: state.general.verifyCouponRequest || false,
            failed: state.general.verifyCouponFailed,
            success: state.general.verifyCouponSuccess,
            verify_coupon: state.general.verifyCoupon || false
        };
    },
    {verifyCoupon}
)(SelectMemberShip);