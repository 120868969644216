import React, {Component} from 'react';
import {connect} from 'react-redux';
// import {Router, Route, IndexRoute, hashHistory} from 'react-router';
// import logo from './logo.svg';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {ToastContainer} from "react-toastify";
import '../../css/custom.css';
import '../../css/dashboard.css';
import '../../css/rwd.css';

class App extends Component {

    render() {
        return (
            <div className="App">
                <Navigation/>
                {this.props.children}
                <Footer/>
                <ToastContainer position="top-center"/>
            </div>
        );
    }
}


export default connect(
    (state, ownProps) => {
        return {...ownProps, user: state.user};
    }
)(App);
