import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {getDegrees, getDentists, getStates} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import Loader from "../components/Loader";
import DentistDetailModal from "../components/DentistDetailModal";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {toast} from "react-toastify";
import {GENDER_MALE, RELATION_CHILD} from "../constants";

class FindDentist extends Component {
    constructor(props) {
        super(props)
        this.viewDetails = this.viewDetails.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            dentist_details: null,
            showModal: false
        }
    }

    componentWillMount() {
        this.props.getDegrees();
        this.props.getStates();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.getDentists(this.state);
    }

    viewDetails(user, index) {
        
        if (index) {
            let newuser = {
                ...user,
                idx: index
                
            }
            this.setState({dentist_details: newuser});
        } else {
            this.setState({dentist_details: user});
        }
        
        this.setState({showModal: true});
    }

    handleChangeText(key, txt) {
        this.setState({[key]: txt});
    }

    handleModal(status) {
        if (!status) {
            this.setState({showModal: status, dentist_details: null});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.success && nextProps.success) {
            document.getElementById("search-form").reset();
            this.setState({
                special_degrees_id: null,
                state: null,
                city: null,
                dentist_name: null,
            })
        }
    }

    render() {
        let degrees = this.props.degrees || null;
        let states = this.props.states || null;
        if (degrees == null) {
            return <Loader/>;
        }
        if (states == null) {
            return <Loader/>;
        }
        let degreeElements = degrees.map((degree, key) => {
            return (
                <option value={degree.id} key={key}>{degree.name}</option>
            );
        });

        let stateElements = states.map((state, key) => {
            return (
                <option value={state.name} key={key}>{state.name}</option>
            );
        });
        let dentists = this.props.dentists || [];
        if (dentists) {
            console.log("states:", dentists.length)
        }
        let dentistElement = dentists.map((user, key) => {
            return (
                <div key={key}>
                    {user.matching_practice_addresses && user.matching_practice_addresses.length > 0 ? (
                        user.matching_practice_addresses.map((practiceAddress, index) => (
                            <div className="row bg_search" key={user.id}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="image-boxs">
                                                <img src={user.details.image_url + "?w=100&h=100"} alt="Dentist"/>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="dentist_details">
                                                <p className="dentist_name">{practiceAddress.practice_name}</p>
                                                <p className="name">{user.details.full_name}</p>
                                                <span className="address">{practiceAddress.address + ', ' + practiceAddress.city + ', ' + practiceAddress.state + ' ' + practiceAddress.zipcode}</span>
                                                <p className="address"><strong>Phone: </strong>{practiceAddress.phone}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="call">
                                                <a href={"tel:" + (practiceAddress.phone)}><i className="fa fa-phone" aria-hidden="true"></i>Make a call</a>
                                            </div>
                                            <div className="call">
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.viewDetails(user, index)
                                                }}>View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>
                            {/* Check if user details are null */}
                            {user.details.address && user.details.city && user.details.phone ? (
                                <div className="row bg_search" key={user.id}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="image-boxs">
                                                    <img src={user.details.image_url + "?w=100&h=100"} alt="Dentist"/>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="dentist_details">
                                                    <p className="dentist_name">{user.dentist.practice_name}</p>
                                                    <p className="name">{user.details.full_name}</p>
                                                    <span className="address">{user.details.address + ', ' + user.details.city + ', ' + user.details.state + ' ' + user.details.zipcode}</span>
                                                    <p className="address"><strong>Phone: </strong>{user.details.phone}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="call">
                                                    <a href={"tel:" + (user.details.phone)}><i className="fa fa-phone" aria-hidden="true"></i>Make a call</a>
                                                </div>
                                                <div className="call">
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.viewDetails(user)
                                                    }}>View Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {/* Render data using user.dentist.practice_addresses_data if any of the user details are null */}
                                    {user.dentist.practice_addresses_data && user.dentist.practice_addresses_data.length > 0 ? (
                                        user.dentist.practice_addresses_data.map((practiceAddress, index) => (
                                            <div className="row bg_search" key={user.id}>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="image-boxs">
                                                                <img src={user.details.image_url + "?w=100&h=100"} alt="Dentist"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <div className="dentist_details">
                                                                <p className="dentist_name">{practiceAddress.practice_name}</p>
                                                                <p className="name">{user.details.full_name}</p>
                                                                <span className="address">{practiceAddress.address + ', ' + practiceAddress.city + ', ' + practiceAddress.state + ' ' + practiceAddress.zipcode}</span>
                                                                <p className="address"><strong>Phone: </strong>{practiceAddress.phone}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="call">
                                                                <a href={"tel:" + (practiceAddress.phone)}><i className="fa fa-phone" aria-hidden="true"></i>Make a call</a>
                                                            </div>
                                                            <div className="call">
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.viewDetails(user, index)
                                                                }}>View Details</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : null}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        });
        
        return (
            <div>
                <ScrollToTopOnMount/>
                {this.state.dentist_details != null ?
                    <DentistDetailModal dentist={this.state.dentist_details} showModal={this.state.showModal}
                                        handleModal={this.handleModal}/> : null}
                <section className="inner-banner inner-banner-fee-schedule-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Find A Dentist</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="find-dentist">Find Dentist</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="secure-desc example-sav">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <form onSubmit={this.handleSubmit} id="search-form">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <input type="text" className="form-control" placeholder="Dentist Name"
                                                   onChange={(e) => {
                                                       this.handleChangeText('dentist_name', e.target.value)
                                                   }}/>
                                        </div>
                                        <div className="col-md-2">
                                            <input type="text" className="form-control" placeholder="Enter City"
                                                   onChange={(e) => {
                                                       this.handleChangeText('city', e.target.value)
                                                   }}/>
                                        </div>
                                        <div className="col-md-2">
                                            <select id="inputState" className="form-control" onChange={(e) => {
                                                this.handleChangeText('state', e.target.value)
                                            }}>
                                                <option selected>Choose State</option>
                                                {stateElements}
                                            </select>
                                        </div>

                                        <div className="col-md-2">
                                            <input type="text" className="form-control" placeholder="Enter ZipCode"
                                                   onChange={(e) => {
                                                       this.handleChangeText('zipcode', e.target.value)
                                                   }}/>
                                        </div>
                                        <div className="col-md-2">
                                            <select id="inputState" className="form-control" onChange={(e) => {
                                                this.handleChangeText('special_degrees_id', e.target.value)
                                            }}>
                                                <option selected>Special Degree</option>
                                                {degreeElements}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            {this.props.request ?
                                                <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                                <button type="submit" className="btn btn-primary btn_news">
                                                    Search
                                                </button>}
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        {dentists.length > 0 ? dentistElement : <div className="row no_dentist">
                            <div className="col-md-12">
                                <h3>No Dentist Found</h3>
                            </div>
                        </div>}
                    </div>
                </section>
            </div>
        );
    }
}


export default connect(
    (state) => {
        return {
            degrees: state.general.degrees,
            dentists: state.general.dentists,
            states: state.general.states,
            request: state.general.dentistsRequest,
            success: state.general.dentistSuccess,
            failed: state.general.dentistsFailed,
            error: state.general.error
        };
    },
    {getDegrees, getDentists, getStates}
)(FindDentist);