import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import connect from "react-redux/es/connect/connect";
import {AVAILABLE_DAYS} from "../constants";
import {reportDentist} from "../actions/user";


class DentistDetailModal extends Component {
    constructor(props) {
        super(props);
    }

    handleClose() {
        this.props.handleModal(false);
    }


    render() {
        let {dentist} = this.props;
        let tableElements = dentist.availabilities.map((value, key) => {
            return (
                <tr key={key}>
                    <td>{AVAILABLE_DAYS[value.day - 1]}</td>
                    <td>{value.start_time}</td>
                    <td>{value.end_time}</td>
                </tr>
            );
        });
        return (
            <Modal show={this.props.showModal} onHide={() => {
                this.props.handleModal(false);
            }} dialogClassName="modal-150w"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered animation>
                <form role="form" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Dentist Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                       
                            <label htmlFor="exampleInputEmail1" className="font-weight-bold">Name:</label>
                            <p className="float-right">{dentist.name}</p>
                        </div>
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="font-weight-bold">Address:</label>
                        <p className="float-right">
                            {dentist.details.address && dentist.details.city && dentist.details.state ? (
                                // Render address details from dentist.details if available
                                dentist.details.address + ', ' + dentist.details.city + ', ' + dentist.details.state + ' ' + dentist.details.zipcode
                            ) : (
                                dentist.details.matching_practice_addresses && dentist.details.matching_practice_addresses.length > 0 ? (
                                    // Render address details from matching_practice_addresses if available
                                    dentist.idx ? (
                                        // Render address details if dentist.idx exists
                                        <span>
                                            {dentist.details.matching_practice_addresses[dentist.idx].address}, {dentist.details.matching_practice_addresses[dentist.idx].city}, {dentist.details.matching_practice_addresses[dentist.idx].state} {dentist.details.matching_practice_addresses[dentist.idx].zipcode}
                                        </span>
                                    ) : (
                                        // If dentist.idx does not exist, render data from the first element (index 0)
                                        <span>
                                            {dentist.details.matching_practice_addresses[0].address}, {dentist.details.matching_practice_addresses[0].city}, {dentist.details.matching_practice_addresses[0].state} {dentist.details.matching_practice_addresses[0].zipcode}
                                        </span>
                                    )
                                ) : (
                                    dentist.dentist.practice_addresses_data && dentist.dentist.practice_addresses_data.length > 0 ? (
                                        // Render address details from matching_practice_addresses if available
                                        dentist.idx ? (
                                            // Render address details if dentist.idx exists
                                            <span>
                                                {dentist.dentist.practice_addresses_data[dentist.idx].address}, {dentist.dentist.practice_addresses_data[dentist.idx].city}, {dentist.dentist.practice_addresses_data[dentist.idx].state} {dentist.dentist.practice_addresses_data[dentist.idx].zipcode}
                                            </span>
                                        ) : (
                                            // If dentist.idx does not exist, render data from the first element (index 0)
                                            <span>
                                                {dentist.dentist.practice_addresses_data[0].address}, {dentist.dentist.practice_addresses_data[0].city}, {dentist.dentist.practice_addresses_data[0].state} {dentist.dentist.practice_addresses_data[0].zipcode}
                                            </span>
                                        )
                                    ) : 
                                    " "
                                )
                            )}

                            </p>
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="exampleInputEmail1" className="font-weight-bold">Address:</label>
                            <p className="float-right">{dentist.details.address + ', ' + dentist.details.city + ', ' + dentist.details.state + ' ' + dentist.details.zipcode}</p>
                        </div> */}
                        {dentist.availabilities.length > 0 ?
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Day</th>
                                    <th scope="col">Start Time</th>
                                    <th scope="col">End Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableElements}
                                </tbody>
                            </table>
                            : null}
                    </Modal.Body>

                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            this.props.handleModal(false);
                        }}>Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default connect(
    (state) => {
        return {};
    },
    {}
)(DentistDetailModal);