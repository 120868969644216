import React, {Component} from 'react';
import {Link} from "react-router-dom";

class DentistNavigation extends Component {
    render() {
        return (
            <div>
                <div className="scroller_anchor"></div>
                <section className="reg-steps scroller">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="text-center">
                                    <li className={this.props.step == 1 ? "active" : "done"}>
                                        <Link to={"1"}>
                                            <span>1</span>
                                            <p>General Information</p>
                                        </Link>
                                    </li>
                                    <li className={this.props.step > 2 ? "done" : this.props.step == 2 ? "active" : null}>
                                        <Link to={"2"}>
                                            <span>2</span>
                                            <p>Equipment Section</p>
                                        </Link>
                                    </li>
                                    <li className={this.props.step > 3 ? "done" : this.props.step == 3 ? "active" : null}>
                                        <Link to={"3"}>
                                            <span> 3 </span>
                                            <p>Specialties & Referrals</p>
                                        </Link>
                                    </li>
                                    <li className={this.props.step > 4 ? "done" : this.props.step == 4 ? "active" : null}>
                                        <Link to={"4"}>
                                            <span>4</span>
                                            <p>Miscellaneous</p>
                                        </Link>
                                    </li>
                                    <li className={this.props.step > 5 ? "done" : this.props.step == 5 ? "active" : null}>
                                        <Link to={"5"}>
                                            <span>5</span>
                                            <p>Witnesseth</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default DentistNavigation;