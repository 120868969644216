import React, {Component} from 'react';
import {Alert} from "react-bootstrap";

class Alerts extends Component {


    render() {
        return this.props.alerts.map((obj, index) => {
            return <Alert key={index} variant={obj.type}>{obj.message}</Alert>;
        });
    }
}


export default Alerts;