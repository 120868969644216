import user from "./user";
import general from "./general";
import subscription from "./subscription";

export const USER_ACTIONS = user;
export const GENERAL_ACTIONS = general;
export const SUBSCRIPTION_ACTIONS = subscription;

// Export All Actions.
export default {
    USER_ACTIONS: user,
    GENERAL_ACTIONS: general,
    SUBSCRIPTION_ACTIONS: subscription,
};