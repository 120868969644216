import React, {Component} from 'react';
import {deleteMember, getUsers} from "../actions/user";
import connect from "react-redux/es/connect/connect";
import {toast} from "react-toastify";

class FamilyMemberListings extends Component {
    constructor(props) {
        super(props)
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleEdit(e) {
        e.preventDefault();
        console.log(this.props.member.id)
    }

    handleDelete(e) {
        e.preventDefault();
        this.props.deleteMember(this.props.user.access.token, this.props.member.id, this.props.user.user_id);
    }

    /*componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.success && nextProps.success) {
            toast(nextProps.successMsg);
        }
    }*/

    render() {
        let {member} = this.props;
        return (
            <tr>
                <td>{member.name}</td>
                <td>{member.details.age}</td>
                <td>{member.details.relation_ship_text}</td>
                <td>{member.details.gender_text}</td>
                <td>{member.details.other_details}</td>
                <td>
                    <a href="#" onClick={this.handleDelete}>
                        <i className="fas fa-trash"></i>
                    </a>
                </td>
            </tr>

        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            request: state.user.deleteMemberRequest,
            success: state.user.deleteMemberSuccess,
            failed: state.user.deleteMemberFailed,
            error: state.user.error,
            successMsg: 'Family member has been deleted.',
        };
    },
    {deleteMember}
)(FamilyMemberListings);