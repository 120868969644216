import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {resetpassword} from "../actions/user";
import Alerts from "../components/Alerts";
import DashboardHeader from "../components/DashboardHeader";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.handleChangePassword = this.handleChangeText.bind(this, "password");
        this.handleConfirmationPassword = this.handleChangeText.bind(this, "password_confirmation");
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.state = {
            password: "",
            password_confirmation: ""
        }
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleResetPassword(e) {
        e.preventDefault();
        let pass = this.state.password;
        let cpass = this.state.password_confirmation;
        if (pass.length > 0 && cpass.length > 0) {
            this.setState({alert: false, message: ''})
            this.props.resetpassword(this.props.email, this.props.code, this.state.password, this.state.password_confirmation);
        } else {
            this.setState({alert: true, message: 'fill required fields'})
        }

        return false;
    }

    render() {
        // console.log('reset:', this.props);
        return (
            <div>

                <section className="sec-login">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 padright">
                                <div className="lf">
                                    <img src={require("../../images/acount-banner.png")}/>
                                </div>
                            </div>

                            <div className="col-md-7 padleft">
                                <div className="rt">
                                    <div className="inner">
                                        {/*<img src={header_logo}/>*/}
                                        {this.state.alert &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.state.message
                                        }]}/>}
                                        {this.props.failed &&
                                        <Alerts alerts={[{type: "danger", message: this.props.error.message}]}/>}
                                        {this.props.success &&
                                        <Alerts alerts={[{type: "success", message: this.props.successMsg}]}/>}
                                        <h3 className="tit_sign">Reset Password</h3>
                                        <p className="txt_sign">Enter your new password below.</p>
                                        <form onSubmit={this.handleResetPassword}>
                                            <div className="field pass">
                                                <input type="password" name="password"
                                                       onChange={this.handleChangePassword}
                                                       placeholder="New Password"/>
                                            </div>
                                            <div className="field pass">
                                                <input type="password" name="password_confirmation"
                                                       onChange={this.handleConfirmationPassword}
                                                       placeholder="Re-type New Password"/>
                                            </div>
                                            <div className="btn_forgot field">
                                                {this.props.request
                                                    ?
                                                    <input type="submit" name="Submit" value="Submitting"
                                                           className="btn_style"
                                                           disabled/>
                                                    : <input type="submit" name="Submit" value="Submit"
                                                             className="btn_style"/>

                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

ResetPassword.propTypes = {};

export default connect(
    (state) => {
        return {
            request: state.user.resetPasswordRequest,
            success: state.user.resetPasswordSuccess,
            failed: state.user.resetPasswordFailed,
            error: state.user.error,
            successMsg: state.user.resetPasswordMsg,
            email: state.user.forgotEmail,
            code: state.user.verifyCode,
        };
    },
    {resetpassword}
)(ResetPassword);
