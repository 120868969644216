import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Carousel from "react-bootstrap/Carousel";
import Rater from "react-rater";
import 'react-rater/lib/react-rater.css'

class TestimonialItem extends Component {
    render() {
        let {testimonials} = this.props;
        return (
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={testimonials.image_url}
                    alt={testimonials.name}
                />

                <Carousel.Caption>
                    <p>{testimonials.description}</p>
                    <div className="rating">
                        <Rater rating={testimonials.rating} total={5} interactive={false}/>
                    </div>
                    <h3>{testimonials.name}</h3>
                    <h6>Satisfied Mainline Member</h6>
                </Carousel.Caption>
            </Carousel.Item>

        );
    }
}

export default TestimonialItem;