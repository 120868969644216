import React, {Component} from 'react';
import Map from "./Map";
import DentistMap from "./DentistMap";
import TimePicker from 'react-times';

// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';
import {dentistRegistration, login, socailLogin} from "../actions/user";
import connect from "react-redux/es/connect/connect";
import {FRIDAY, MONDAY, SATURDAY, SUNDAY, THURSDAY, TUESDAY, WEDNESDAY} from "../constants";
import DentistSidebar from "./DentistSidebar";
import {toast} from "react-toastify";

class DentistRegistrationOne extends Component {
    constructor(props) {
        super(props)
        this.handleName = this.handleChangeText.bind(this, "name");
        this.handlePracticeName = this.handleChangeText.bind(this, "practice_name");
        this.handleEmail = this.handleChangeText.bind(this, "email");
        this.handlePhone = this.handleChangeText.bind(this, "phone");
        this.handleFax = this.handleChangeText.bind(this, "fax");
        this.handlePracticeSpeciality = this.handleChangeText.bind(this, "limited_speciality");
        this.handleSpecifySpeciality = this.handleChangeText.bind(this, "specify_speciality");
        this.handleDentalSchool = this.handleChangeText.bind(this, "dental_school");
        this.handleGraduation = this.handleChangeText.bind(this, "year_of_graduation");
        this.handleLicenseNumber = this.handleChangeText.bind(this, "license_number");
        this.handleOfficeManager = this.handleChangeText.bind(this, "office_manager");
        this.handleAuxiliaries = this.handleChangeText.bind(this, "no_of_auxiliaries");

        this.getMapData = this.getMapData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            city: '',
            state: '',
            country: '',
            zipcode: '',
            latitude: 40.71475956110875,
            longitude: -74.00288289521484,
            time1: '09:00',
            time2: '17:00',
            time3: '09:00',
            time4: '17:00',
            time5: '09:00',
            time6: '17:00',
            time7: '09:00',
            time8: '17:00',
            time9: '09:00',
            time10: '17:00',
            time11: '09:00',
            time12: '17:00',
            time13: '09:00',
            time14: '17:00',
            any_speciality: false,
            hygienist: false,
        }
    }

    getMapData(data) {
        this.setState({
            latitude: data.markerPosition.lat,
            longitude: data.markerPosition.lng,
            city: data.city,
            state: data.state,
            country: data.country,
            zipcode: data.postalCode,
            address: data.address

        })
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleChangeField(key, txt) {
        this.setState({[key]: txt});
    }

    handleSubmit(e) {
        e.preventDefault();
        let form_data = new FormData();
        let any_speciality = this.state.any_speciality ? 1 : 0;
        let hygienist = this.state.hygienist ? 1 : 0;
        form_data.append('name', this.state.name)
        form_data.append('practice_name', this.state.practice_name)
        form_data.append('address', this.state.address)
        form_data.append('city', this.state.city)
        form_data.append('state', this.state.state)
        form_data.append('country', this.state.country)
        form_data.append('zipcode', this.state.zipcode)
        form_data.append('latitude', this.state.latitude)
        form_data.append('longitude', this.state.longitude)
        form_data.append('email', this.state.email)
        form_data.append('phone', this.state.phone)
        form_data.append('fax', this.state.fax)
        form_data.append('speciality', any_speciality)
        form_data.append('hygienist', hygienist)
        if (any_speciality) {
            form_data.append('specify_speciality', this.state.specify_speciality)
            form_data.append('limited_speciality', this.state.limited_speciality)
            form_data.append('dental_school', this.state.dental_school)
            form_data.append('year_of_graduation', this.state.year_of_graduation)
            form_data.append('license_number', this.state.license_number)
            form_data.append('office_manager', this.state.office_manager)
        }
        if (hygienist) {
            form_data.append('no_of_auxiliaries', this.state.no_of_auxiliaries)
        }
        form_data.append('dental_license', this.state.dental_license)
        form_data.append('dea', this.state.dea)
        form_data.append('cds', this.state.cds)
        form_data.append('insurance_coverage', this.state.insurance_coverage)
        form_data.append('image', this.state.image)
        form_data.append('availabilities', JSON.stringify([
            {day: MONDAY, start_time: this.state.time1, end_time: this.state.time2},
            {day: TUESDAY, start_time: this.state.time3, end_time: this.state.time4},
            {day: WEDNESDAY, start_time: this.state.time5, end_time: this.state.time6},
            {day: THURSDAY, start_time: this.state.time7, end_time: this.state.time8},
            {day: FRIDAY, start_time: this.state.time9, end_time: this.state.time10},
            {day: SATURDAY, start_time: this.state.time11, end_time: this.state.time12},
            {day: SUNDAY, start_time: this.state.time13, end_time: this.state.time14},
        ]))
        this.props.dentistRegistration(form_data);
    }

    onTimeChange(data, options) {
        // do something
        let time = options.hour + ':' + options.minute;
        this.setState({[data]: time})
    }

    onFocusChange(focusStatue) {
        // do something
    }

    handleSpeciality() {
        let any_speciality = this.state.any_speciality;
        this.setState({any_speciality: !any_speciality});
    }

    handleHygienist() {
        let hygienist = this.state.hygienist;
        this.setState({hygienist: !hygienist});
    }

    handleFile(data, e) {
        this.setState({
            [data]: e.target.files[0]
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.error && nextProps.error) {
            toast.error(nextProps.error.message);
        }
    }

    render() {
        let any_speciality = this.state.any_speciality ? "checked" : null;
        return (
            <section className="sec-reg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form className="brdr" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <input className="def" type="text" name="" placeholder="Name *"
                                               onChange={this.handleName} required/>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="def" type="text" name="" placeholder="Practice Name *"
                                               onChange={this.handlePracticeName} required/>
                                    </div>
                                    <div className="col-md-12">
                                        <DentistMap
                                            google={this.props.google}
                                            center={{
                                                lat: this.state.latitude,
                                                lng: this.state.longitude
                                            }}
                                            height='300px'
                                            zoom={15}
                                            mapData={this.getMapData}
                                        />
                                        {/*<input className="def" type="text" name="" placeholder="Office Address *"/>*/}
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" value={this.state.city} name=""
                                               placeholder="City *" onChange={(e) => {
                                            this.handleChangeField('city', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" value={this.state.state} type="text" name=""
                                               placeholder="State *" onChange={(e) => {
                                            this.handleChangeField('state', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" value={this.state.zipcode} type="number" name=""
                                               placeholder="ZIP *" onChange={(e) => {
                                            this.handleChangeField('zipcode', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="def" value={this.state.country} type="text" name=""
                                               placeholder="Country *" onChange={(e) => {
                                            this.handleChangeField('country', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="def" type="email" name=""
                                               placeholder="Email*" onChange={this.handleEmail}
                                               required/>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="def" type="number" name=""
                                               placeholder="Telephone: (Office) *" onChange={this.handlePhone}
                                               required/>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="def" type="number" name="" placeholder="Fax"
                                               onChange={this.handleFax}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main">Office Hours *</label>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Monday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label " htmlFor="timepicker1-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time1')}
                                                time={this.state.time1}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker1-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time2')}
                                                time={this.state.time2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Tuesday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker2-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time3')}
                                                time={this.state.time3}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker2-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time4')}
                                                time={this.state.time4}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Wednesday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker3-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time5')}
                                                time={this.state.time5}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker3-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time6')}
                                                time={this.state.time6}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Thursday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker4-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time7')}
                                                time={this.state.time7}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker4-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time8')}
                                                time={this.state.time8}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Friday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker5-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time9')}
                                                time={this.state.time9}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker5-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time10')}
                                                time={this.state.time10}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Saturday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker6-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time11')}
                                                time={this.state.time11}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker6-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time12')}
                                                time={this.state.time12}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top">Sunday:</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker7-start">Start
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time13')}
                                                time={this.state.time13}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label" htmlFor="timepicker7-end">End
                                                Time</label>
                                            <TimePicker
                                                onFocusChange={this.onFocusChange.bind(this)}
                                                onTimeChange={this.onTimeChange.bind(this, 'time14')}
                                                time={this.state.time14}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <label className="mrgn-top">Board certified or qualified in any specialty?
                                            *</label>
                                    </div>
                                    <div className="col-md-4 mrgn-btm text-right">
                                        <div className="rad-box">
                                            <div
                                                className="custom-control custom-radio pmd-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline1" name="customRadio"
                                                       className="custom-control-input"
                                                       onClick={this.handleSpeciality.bind(this)}
                                                       checked={this.state.any_speciality}/>
                                                <label className="custom-control-label pmd-radio-ripple-effect"
                                                       htmlFor="customRadioInline1">Yes</label>
                                            </div>
                                            <div
                                                className="custom-control custom-radio pmd-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline2" name="customRadio"
                                                       className="custom-control-input"
                                                       onClick={this.handleSpeciality.bind(this)}
                                                       checked={!this.state.any_speciality}/>
                                                <label className="custom-control-label pmd-radio-ripple-effect"
                                                       htmlFor="customRadioInline2">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.any_speciality ? <div className="row">
                                        <div className="col-md-12">
                                            <input className="def" type="text" name=""
                                                   placeholder="If yes, please specify"
                                                   onChange={this.handleSpecifySpeciality}/>
                                        </div>
                                        <div className="col-md-12">
                                            <input className="def" type="text" name=""
                                                   placeholder="Practice Limited to specialty of *"
                                                   onChange={this.handlePracticeSpeciality}/>
                                        </div>
                                        <div className="col-md-12">
                                            <input className="def" type="text" name="" placeholder="Dental School *"
                                                   onChange={this.handleDentalSchool}/>
                                        </div>
                                        <div className="col-md-6">
                                            <select className="def" onChange={this.handleGraduation}>
                                                <option value="">Year of Graduation *</option>
                                                <option value="2001">2001</option>
                                                <option value="2002">2002</option>
                                                <option value="2003">2003</option>
                                                <option value="2004">2004</option>
                                                <option value="2005">2005</option>
                                                <option value="2006">2006</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <input className="def" type="number" name=""
                                                   placeholder="License Number *" onChange={this.handleLicenseNumber}/>
                                        </div>
                                        <div className="col-md-12">
                                            <input className="def" type="text" name="" placeholder="Office Manager *"
                                                   onChange={this.handleOfficeManager}/>
                                        </div>
                                    </div> : null}


                                    <div className="col-md-8">
                                        <label className="mrgn-top">Hygienist *</label>
                                    </div>
                                    <div className="col-md-4 mrgn-btm text-right">
                                        <div className="rad-box">
                                            <div
                                                className="custom-control custom-radio pmd-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline3" name="customRadio1"
                                                       className="custom-control-input"
                                                       onClick={this.handleHygienist.bind(this)}
                                                       checked={this.state.hygienist}/>
                                                <label className="custom-control-label pmd-radio-ripple-effect"
                                                       htmlFor="customRadioInline3">Yes</label>
                                            </div>
                                            <div
                                                className="custom-control custom-radio pmd-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline4" name="customRadio1"
                                                       className="custom-control-input"
                                                       onClick={this.handleHygienist.bind(this)}
                                                       checked={!this.state.hygienist}/>
                                                <label className="custom-control-label pmd-radio-ripple-effect"
                                                       htmlFor="customRadioInline4">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.hygienist ? <div className="col-md-12">
                                        <select className="def" onChange={this.handleAuxiliaries}>
                                            <option>Number of auxiliaries *</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div> : null}


                                </div>


                                <div className="upload-area">
                                    <h5>IMPORTANT: You must attach a copy of your dental license, DEA, CDS, and the
                                        schedule page indicating malpractice insurance coverage.</h5>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="ar-col">
                                                <p>Dental License</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="file" name=""
                                                   onChange={this.handleFile.bind(this, 'dental_license')}
                                                   accept="image/*, application/pdf"/>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ar-col">
                                                <p>DEA</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="file" name="" onChange={this.handleFile.bind(this, 'dea')}
                                                   accept="image/*, application/pdf"/>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ar-col">
                                                <p>CDS</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="file" name="" onChange={this.handleFile.bind(this, 'cds')}
                                                   accept="image/*, application/pdf"/>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ar-col">
                                                <p>Schedule page indicating malpractice insurance coverage</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="file" name=""
                                                   onChange={this.handleFile.bind(this, 'insurance_coverage')}
                                                   accept="image/*, application/pdf"/>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ar-col">
                                                <p>Dentist Image/Clinic Logo</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="file" name=""
                                                   onChange={this.handleFile.bind(this, 'image')} accept="image/*"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="field">

                                    {this.props.request ?
                                        <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                        <input type="submit" name="login" value="Next" className="submit"/>
                                    }
                                </div>
                                {/*form row end -->*/}

                            </form>
                        </div>
                        {/* <DentistSidebar/> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            request: state.user.dentistRegistrationRequest,
            success: state.user.dentistRegistrationSuccess,
            failed: state.user.dentistRegistrationFailed,
            error: state.user.error,
            successMsg: ""
        };
    },
    {dentistRegistration}
)(DentistRegistrationOne);