export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const AUTHORIZE_NET_LOGIN_ID = process.env.REACT_APP_AUTHORIZE_NET_LOGIN_ID;
export const AUTHORIZE_NET_PUBLIC_KEY = process.env.REACT_APP_AUTHORIZE_NET_PUBLIC_KEY;
export const AUTHORIZE_NET_ENV = process.env.REACT_APP_AUTHORIZE_NET_ENV;

export const ENDPOINTS = {
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT: "/forget-password",
    VERIFY_CODE: "/verify-reset-code",
    RESET_PASSWORD: "/reset-password",
    GET_SETTINGS: "/settings",
    GET_BLOGS: "/blogs",
    ADD_QUERY: "/contactus",
    GET_TESTIMONIALS: "/testimonials",
    GET_FAQS: "/faqs",
    GET_SUBSCRIPTIONS: "/subscriptions",
    GET_SERVICES: "/services",
    USER_REGISTRATION: "/register",
    GET_BLOG_DETAIL: "/blogs",
    GET_USERS: "/users",
    REFRESH_TOKEN: "/refresh",
    UPDATE_PROFILE: "/profile",
    ADD_FAMILY: "/register",
    GET_MEMBERS: "/users",
    DENTIST_REGISTRATION: "/dentist-registration",
    UPDATE_DENTIST: "/update-dentist",
    GET_EQUIPMENTS: "/equipment",
    GET_DEGREES: "/special-degrees",
    GET_MISCELLANEOUS: "/miscellaneous-services",
    GET_DENTIST: "/get-dentist",
    STRIPE_CHARGE: "/charge",
    STRIPE_CHARGE_WITHOUT_LOGIN: "/register-and-charge",
    ME: "/me",
    GET_MEMBER: "/get-member",
    CHANGE_PASSWORD: "/change-password",
    GET_USER_SUBSCRIPTION: "/user-subscriptions",
    GET_SAVINGS: "/savings",
    GET_DENTISTS: "/dentists",
    GET_STATES: "/states",
    VERIFY_COUPON: "/verify-coupon",
    REPORT_DENTIST: "/report-dentists"
};

export const PAGINATION = {
    HOME_PAGE: 10,
};

export const PAGES = {
    ABOUT: "about"
}

export const RELATION_CHILD = 10;
export const RELATION_PARENT = 20;
export const RELATION_PARTNER = 30;
export const RELATION_SIBLING = 40;
export const RELATIONSHIP_TEXT = {
    [RELATION_CHILD]: "Child",
    [RELATION_PARENT]: "Parent",
    [RELATION_PARTNER]: "Partner",
    [RELATION_SIBLING]: "Sibling",
}

export const GENDER_MALE = 10;
export const GENDER_FEMALE = 20;
export const GENDER_OTHER = 30;
export const GENDER_TEXT = {
    [GENDER_MALE]: "Male",
    [GENDER_FEMALE]: "Female",
    [GENDER_OTHER]: "Other"
}


export const SUNDAY = 1;
export const MONDAY = 2;
export const TUESDAY = 3;
export const WEDNESDAY = 4;
export const THURSDAY = 5;
export const FRIDAY = 6;
export const SATURDAY = 7;

export const EXCELLENT = 10;
export const GOOD = 20;
export const FAIR = 30;
export const POOR = 40;

export const DAYS = ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur'];
export const AVAILABLE_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const MONTHS = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const DATE_FORMAT_OPTIONS = {year: 'numeric', month: 'short', day: 'numeric'};

export const SINGLE_MEMBER = 10;
export const MEMBER_SPOUSE = 20;
export const PARENT_CHILD = 30;
export const FAMILY = 40;


export const ANNUAL_SUBSCRIPTION = 10;
export const SEMI_ANNUAL_SUBSCRIPTION = 20;
export const QUARTERLY_SUBSCRIPTION = 30;

export const MEMBERSHIP_TYPES = {
    [SINGLE_MEMBER]: "single_member",
    [MEMBER_SPOUSE]: "member_spouse",
    [PARENT_CHILD]: "parent_child",
    [FAMILY]: "family",
};
export const SUBSCRIPTION_TYPES = {
    [ANNUAL_SUBSCRIPTION]: "12 months",
    [SEMI_ANNUAL_SUBSCRIPTION]: "6 months",
    [QUARTERLY_SUBSCRIPTION]: "3 months"
};
export const PACKAGE_STATUSES = {
    1: "Active",
    0: "Inactive"
};

export const DEFAULT_EMAIL = "info@mdpsmile.com"
export const DEFAULT_PHONE = "1-877-880-PLAN (7526)"
export const DEFAULT_FAX = "(201) 357-7147"
export const DEFAULT_TITLE = "Mainline Dental Inc"
export const DEFAULT_ADDRESS = "9 Furler Street Totowa, NJ 07512"
export const DEFAULT_TIMING = "8am-5pm EST"

