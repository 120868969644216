import React, {Component} from "react"
import {compose} from "recompose"
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps"
import {GOOGLE_API_KEY} from "../constants";
import Loader from "./Loader";


const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
    if (!props.currentUser) {
        return <Loader/>;
    }

    let lat = props.selectedMarker ? props.selectedMarker.details.latitude : props.currentUser.details.latitude;
    let lng = props.selectedMarker ? props.selectedMarker.details.longitude : props.currentUser.details.longitude;
    return (
        <GoogleMap defaultZoom={15} defaultCenter={{lat: 29.5, lng: -95}} center={{lat: Number(lat), lng: Number(lng)}}>
            {props.markers.map(marker => {
                const onClick = props.onClick.bind(this, marker)
                const onClose = props.onClose.bind(this, marker)
                return (
                    <Marker
                        key={marker.id}
                        onClick={onClick}
                        position={{lat: Number(marker.details.latitude), lng: Number(marker.details.longitude)}}
                    >
                        {props.selectedMarker === marker &&
                        <InfoWindow onCloseClick={onClose}>
                            <div className="info-window">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/*<i className="image-box">
                                                <img src={marker.details.image_url} alt=""/>
                                            </i>*/}
                                            {marker.details.full_name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>}

                    </Marker>
                )
            })}
        </GoogleMap>
    )
})

export default class MapPointers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: this.props.users,
            selectedMarker: false
        }
    }

    componentDidMount() {
        /* fetch("https://api.harveyneeds.org/api/v1/shelters?limit=20")
             .then(r => r.json())
             .then(data => {
                 this.setState({shelters: data.shelters})
             })*/
    }

    handleClick = (marker, event) => {
        this.setState({selectedMarker: marker})
    }
    handleClose = (marker, event) => {
        this.setState({selectedMarker: false})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({selectedMarker: nextProps.marker})
    }

    render() {
        let url = "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_API_KEY + "&libraries=places";

        return (
            <MapWithAMarker
                selectedMarker={this.state.selectedMarker}
                markers={this.props.users}
                currentUser={this.props.currentUser}
                onClick={this.handleClick}
                onClose={this.handleClose}
                googleMapURL={url}
                loadingElement={<div style={{height: `100%`}}/>}
                containerElement={<div style={{height: `500px`}}/>}
                mapElement={<div style={{height: `100%`}}/>}
            />
        )
    }
}