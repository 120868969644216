import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {getBlogs} from "../actions/general";
import Loader from "./Loader";
// import BlogItem from "./BlogItem";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import {NavLink} from "react-router-dom";

class HomeBlog extends Component {
    constructor(props) {
        super(props);
    }

    /*    componentWillMount() {
            this.props.getBlogs();
        }*/

    render() {

        // CODE FOR DYNAMIC BLOG

        // let blogs = this.props.blogs || null;
        // // console.log(blogs);
        // if (blogs == null) {
        //     return <Loader/>;
        // }
        // let BlogItems = blogs.map((blog, key) => {
        //     return (
        //         <BlogItem blog={blog} key={key}/>
        //     )
        // });


        return (
            <section className="sec-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="top-desc text-center">
                                <h3>How It Works</h3>
                                <h2>Join Today, Start Saving Tomorrow</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="main">
                                <div className="thumb">
                                    <img src={require("../../images/work-1.png")}/>
                                </div>
                                <div className="desc text-center">
                                    <h4>Find A Dentist</h4>
                                    <p>MDP continues to build our network of Dentists and Specialists</p>
                                    <NavLink to="/find-dentist" className="btn-bl">Read More</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="main">
                                <div className="thumb">
                                    <img src={require("../../images/work-2.png")}/>
                                </div>
                                <div className="desc text-center">
                                    <h4>Examples of Savings</h4>
                                    <p>See how MDP can benefit you</p>
                                    <NavLink to="/example-of-savings" className="btn-bl">Read More</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="main">
                                <div className="thumb">
                                    <img src={require("../../images/work-3.png")}/>
                                </div>
                                <div className="desc text-center">
                                    <h4>Verify Eligibility</h4>
                                    <p>Verify Your Membership Status & Eligibility</p>
                                    <NavLink to="/verify-eligibility" className="btn-bl">Read More</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            blogs: state.general.blogs
        };
    },
    {getBlogs}
)(HomeBlog);