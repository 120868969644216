import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {login, verifycode} from "../actions/user";
import Alerts from "../components/Alerts";

class VerifyResetCode extends Component {
    constructor(props) {
        super(props);
        this.handleChangeCode = this.handleChangeText.bind(this, "code");
        this.handleVerifyCode = this.handleVerifyCode.bind(this);
        this.state = {
            code: ""
        }
    }


    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleVerifyCode(e) {
        e.preventDefault();
        let code = this.state.code;
        if (code.length > 0) {
            this.setState({alert: false, message: ''})
            this.props.verifycode(this.state.code);
        } else {
            this.setState({alert: true, message: 'Email field is required'})
        }

        return false;
    }

    render() {
        // console.log("PROPS: ", this.props)
        return (
            <div>
                <section className="sec-login">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 padright">
                                <div className="lf">
                                    <img src={require("../../images/acount-banner.png")}/>
                                </div>
                            </div>


                            <div className="col-md-7 padleft">
                                <div className="rt">
                                    <div className="inner">
                                        {/*<img src={header_logo}/>*/}
                                        {this.state.alert &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.state.message
                                        }]}/>}
                                        {this.props.failed &&
                                        <Alerts alerts={[{type: "danger", message: this.props.error.message}]}/>}
                                        {this.props.success &&
                                        <Alerts alerts={[{type: "success", message: this.props.successMsg}]}/>}
                                        <h3 className="tit_sign">Verify Reset Code</h3>
                                        <p className="txt_sign">Enter your password reset code below.</p>
                                        <form onSubmit={this.handleVerifyCode}>
                                            <div className="input_div field pass">
                                                <input name="code" type="text" value={this.state.code}
                                                       onChange={this.handleChangeCode}
                                                       placeholder="Verification Code"/>
                                            </div>

                                            <div className="btn_forgot field">
                                                {this.props.request
                                                    ?
                                                    <input type="submit" name="Submit" value="Submitting"
                                                           className="btn_style"
                                                           disabled/>
                                                    : <input type="submit" name="Submit" value="Submit"
                                                             className="btn_style"/>

                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

VerifyResetCode.propTypes = {};

export default connect(
    (state) => {
        return {
            request: state.user.verifyCodeRequest,
            success: state.user.verifyCodeSuccess,
            failed: state.user.verifyCodeFailed,
            error: state.user.error,
            successMsg: state.user.verifyCodeMsg
        };
    },
    {verifycode}
)(VerifyResetCode);
