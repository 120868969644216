import React, {Component} from 'react';

class MembershipInformation extends Component {
    render() {
        return (
            <>
                <section className="member-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-desc text-center">
                                    <h2>Our Membership Information</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-4 padleft">
                                <h5>Single Membership</h5>
                            </div>
                            <div className="col-md-8">
                                <p>Single coverage is for the member only.</p>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-4 padleft">
                                <h5>Member & Spouse</h5>
                            </div>
                            <div className="col-md-8">
                                <p>Includes member and spouse or domestic partner residing in the same household.</p>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-4 padleft">
                                <h5>Parent/Child</h5>
                            </div>
                            <div className="col-md-8">
                                <p>Parent/Child coverage includes member and 1 child under the age of 18 residing in the
                                    same household.</p>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-4 padleft">
                                <h5>Family Membership</h5>
                            </div>
                            <div className="col-md-8">
                                <p>Family coverage includes member, spouse or domestic partner, and all children under
                                    the age of 18 residing in the same household.</p>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-4 padleft">
                                <h5>Group Membership</h5>
                            </div>
                            <div className="col-md-8">
                                <p>Group coverage can be provided for employers with 4 or more employees.</p>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-4 padleft">
                                <h5></h5>
                            </div>
                            <div className="col-md-8">
                                <p>Please call our office for a quote.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default MembershipInformation;