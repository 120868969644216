import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {addMember, getMembers} from "../actions/user";
import Loader from "../components/Loader";
import FamilyMemberListings from "../components/FamilyMemberListings";
import {
    GENDER_FEMALE,
    GENDER_MALE, GENDER_OTHER,
    GOOGLE_API_KEY,
    RELATION_CHILD,
    RELATION_PARENT,
    RELATION_PARTNER,
    RELATION_SIBLING
} from "../constants"
import Sidebar from "../components/Sidebar";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddFamily extends Component {
    constructor(props) {
        super(props);
        this.handleName = this.handleChangeText.bind(this, "name");
        this.handleOtherDetails = this.handleChangeText.bind(this, "other_details");
        this.handleRelationship = this.handleChangeText.bind(this, "relationship");
        // this.handleAge = this.handleChangeText.bind(this, "dob");
        this.handleGender = this.handleChangeText.bind(this, "gender");
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            name: '',
            relationship: RELATION_CHILD,
            date_of_birth: '',
            dob: '',
            gender: GENDER_MALE,
            other_details: ''
        }
    }


    componentDidMount() {
        this.props.getMembers(this.props.user.access.token, this.props.user.user_id);
    }

    handleAge = date => {
        this.setState({
            dob: date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear(),
            date_of_birth: date
        });
    };

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    async handleSubmit(e) {
        e.preventDefault();
        let rand = Math.random().toString(36).substring(7);
        let name = this.state.name;
        let email = name.replace(/\s/g, '') + rand + "@mainlinedental.com";
        await this.setState({
            parent_id: this.props.user.user_id,
            email: email,
            password: "123456",
            password_confirmation: "123456",
            device_type: "web"
        });
        await this.props.addMember(this.props.user.access.token, this.state);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.success && nextProps.success) {
            toast(this.props.successMsg);
            let table = document.getElementById('family-members');
            table.scrollIntoView();
            document.getElementById("add-family-member").reset();
            this.setState(this.state = {
                name: '',
                relationship: RELATION_CHILD,
                date_of_birth: '',
                dob: '',
                gender: GENDER_MALE,
                other_details: ''
            })
        }
        if (!this.props.deleteSuccess && nextProps.deleteSuccess) {
            toast('Family member has been deleted.');
        }
    }

    render() {
        const ExampleCustomInput = ({value, onClick}) => (
            <input type="text" className="example-custom-input" onClick={onClick} value={value} readOnly/>
        );
        let user = this.props.user || null;
        if (user == null) {
            return <Loader/>;
        }

        let members = this.props.familyMembers || null;

        if (members == null) {
            return <Loader/>;
        }

        let membersElement = members.map((member, key) => {
            return (
                <FamilyMemberListings member={member} key={key}/>
            )
        });
        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="dash-ban">
                    <section className="dash-ban">
                        <div className="prof-det wd-95 pd-top-60">
                            <div className="container-fluid">
                                <div className="row">
                                    <Sidebar/>
                                    {/*Right Side Section*/}
                                    <div className="col-md-9">
                                        <div className="main-area add-mem">
                                            <div className="area-top">
                                                <h3>Add Family Member</h3>
                                            </div>
                                            <div className="area-btm">
                                                <form id="add-family-member" onSubmit={this.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>Name</label>
                                                            <input type="text" name="name"
                                                                   placeholder="Enter Name"
                                                                   onChange={this.handleName}
                                                                   autoComplete="off"
                                                                   required/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Relationship</label>
                                                            <select onChange={this.handleRelationship} required>
                                                                <option value={RELATION_CHILD}>Child</option>
                                                                <option value={RELATION_PARENT}>Parent</option>
                                                                <option value={RELATION_PARTNER}>Partner</option>
                                                                <option value={RELATION_SIBLING}>Sibling</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Date of Birth</label>
                                                            {/*<input type="date" name="dob"
                                                                   onChange={this.handleAge} required/>*/}
                                                            <DatePicker
                                                                selected={this.state.date_of_birth}
                                                                onChange={this.handleAge}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                customInput={<ExampleCustomInput/>}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Gender</label>
                                                            <select onChange={this.handleGender} required>
                                                                <option disabled selected value={GENDER_MALE}>Select
                                                                    Gender
                                                                </option>
                                                                <option value={GENDER_MALE}>Male</option>
                                                                <option value={GENDER_FEMALE}>Female</option>
                                                                <option value={GENDER_OTHER}>Other</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label>Description</label>
                                                            <textarea name="other_details" id="other_details"
                                                                      onChange={this.handleOtherDetails}></textarea>
                                                        </div>
                                                        <div className="col-md-12">
                                                            {this.props.request ?
                                                                <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                                                <input type="submit" name="" value="Submit"/>}

                                                        </div>
                                                        <div id="family-members" style={{width: '100%'}}>
                                                            {members.length > 0 ?

                                                                <table className="add-member">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Age</th>
                                                                        <th>Relationship</th>
                                                                        <th>Gender</th>
                                                                        <th>Description</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {membersElement}
                                                                    </tbody>
                                                                </table>

                                                                : null}
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users,
            familyMembers: state.user.familyMembers,
            request: state.user.familyRequest,
            success: state.user.familySuccess,
            failed: state.user.familyFailed,
            error: state.user.error,
            successMsg: 'Family member has been added.',
            deleteSuccess: state.user.deleteMemberSuccess,
            settings: state.settings ? state.settings.settings : state.general.settings,
        };
    }, {addMember, getMembers}
)(AddFamily);