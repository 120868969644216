import {SUBSCRIPTION_ACTIONS} from "../actions";

export default function subscription(state = {}, action) {
    switch (action.type) {
        //get Subscriptions
        case SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTIONS_REQUEST: {
            return {...state, subscriptionRequest: true, subscriptionFailed: false};
        }
        case SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTIONS: {
            return {
                ...state,
                subscriptionRequest: false,
                subscriptionFailed: false,
                subscriptionSuccess: true,
                subscriptions: action.payload.data.data
            };

        }
        case SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTIONS_FAILED: {
            return {...state, subscriptionRequest: false, error: action.error, subscriptionFailed: true};
        }
        //get Subscription
        case SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_REQUEST: {
            return {...state, subscriptionRequest: true, subscriptionFailed: false};
        }
        case SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION: {
            return {
                ...state,
                subscriptionRequest: false,
                subscriptionFailed: false,
                subscriptionSuccess: true,
                subscription: action.payload.data.data
            };

        }
        case SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_FAILED: {
            return {...state, subscriptionRequest: false, error: action.error, subscriptionFailed: true};
        }
        //get User Subscription
        case SUBSCRIPTION_ACTIONS.GET_USER_SUBSCRIPTION_REQUEST: {
            return {...state, userSubscriptionRequest: true, userSubscriptionFailed: false};
        }
        case SUBSCRIPTION_ACTIONS.GET_USER_SUBSCRIPTION: {
            return {
                ...state,
                userSubscriptionRequest: false,
                userSubscriptionFailed: false,
                userSubscriptionSuccess: true,
                user_subscription: action.payload.data.data
            };

        }
        case SUBSCRIPTION_ACTIONS.GET_USER_SUBSCRIPTION_FAILED: {
            return {...state, userSubscriptionRequest: false, error: action.error, userSubscriptionFailed: true};
        }
        // stripe request
        case SUBSCRIPTION_ACTIONS.STRIPE_REQUEST: {
            return {
                ...state,
                stripeRequest: true,
                stripeFailed: false,
                membership_info: action.payload.membership_info
            };
        }

        // stripe charge
        case SUBSCRIPTION_ACTIONS.STRIPE_CHARGE_REQUEST: {
            return {
                ...state,
                error: false,
                stripeChargeRequest: true,
                stripeChargeSuccess: false,
                stripeChargeFailed: false
            };
        }
        case SUBSCRIPTION_ACTIONS.STRIPE_CHARGE: {
            return {
                ...state,
                stripeChargeRequest: false,
                stripeChargeFailed: false,
                stripeChargeSuccess: true,
                successMsg: action.payload.message,
                error: false
            };

        }
        case SUBSCRIPTION_ACTIONS.STRIPE_CHARGE_FAILED: {
            return {
                ...state,
                stripeChargeSuccess: false,
                stripeChargeRequest: false,
                error: action.error,
                stripeChargeFailed: true
            };
        }
        default:
            return state;
    }
}