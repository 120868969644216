import React, {Component} from 'react';
import MembershipInfoRegisterationSection from "../components/MembershipInfoRegisterationSection";
import SubscriptionPlans from "../components/SubscriptionPlans";
import MembershipInformation from "../components/MembershipInformation";
import MembershipInfoQuote from "../components/MembershipInfoQuote";
import MembershipInfoAddress from "../components/MembershipInfoAddress";
import connect from "react-redux/es/connect/connect";
import {getSubscriptions} from "../actions/subscription";
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import SelectMemberShip from "../components/SelectMemberShip";


class MembershipInfo extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.getSubscriptions();
    }

    render() {
        let settings = this.props.settings || null;
        return (
            <>
                <ScrollToTopOnMount/>
                {/*Banner*/}
                <section className="inner-banner inner-banner-membership-info-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Membership Info</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/membership">Membership Info</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*SUBSCRIPTION Plans*/}
                <SubscriptionPlans/>
                <SelectMemberShip/>
                {/*Register Online, Printable Form, Cancellation Policy */}
                <MembershipInfoRegisterationSection/>
                {/*Our Membership Information Section*/}
                <MembershipInformation/>
                {/*Quote section*/}
                <MembershipInfoQuote/>
                {/*Address Section*/}
                <MembershipInfoAddress settings={settings}/>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            settings: state.settings ? state.settings.settings : state.general.settings
        };
    },
    {getSubscriptions}
)(MembershipInfo);