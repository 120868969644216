import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import connect from "react-redux/es/connect/connect";
import {getSavings} from "../actions/general";
import Savings from "../components/Savings";

class ExampleOfSavings extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getSavings();
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="inner-banner inner-banner-faq-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Examples Of Savings</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/example-of-savings">Examples Of Savings</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <Savings/>


                <section className="secure-desc example-sav">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="desc">
                                    <p>How does Mainline Dental Plan, Inc. (MDP) benefit you? MDP provides reduced rates
                                        for quality dentistry to individuals and groups. MDP works in cooperation with a
                                        network of dentists and specialists who offer our members dental services at
                                        reduced rates. MDP is NOT insurance, it is an alternative to insurance that
                                        allows members to pay reduced fees for dental services. Based upon our fee
                                        schedule, you can enjoy savings from 25% to 100% off the regular dentist’s fee
                                        by joining MDP.</p>
                                    <p>Dental insurance can be very costly. With most insurance plans, there are annual
                                        maximums, deductibles, and waiting periods for certain treatments. There can
                                        also be clauses that do not cover missing teeth, no coverage for Cosmetic
                                        Dentistry and various forms to fill out for claim submission. In addition, you
                                        may still have to pay a portion of the dentist’s charges.</p>
                                    <p>MDP has NO annual maximum. NO deductibles, NO pre-existing condition clauses, NO
                                        paperwork to fill out for a claim, and NO waiting periods. Plus, Cosmetic
                                        Dentistry is offered at 25% less the regular fee. The best thing is that you
                                        know what you have to pay out of pocket before you go!</p>
                                    <p>Our goal is to make certain everyone has the opportunity to have the affordable
                                        dental treatments they need and want. MDP can accommodate individuals, couples,
                                        and families, as well as employers with small or large groups.</p>
                                    <p>Choose the plan that is appropriate for you: <a href="#">Individual, Couple,
                                        Parent & Child, or Family.</a> The couple plan allows two people in the same
                                        household (i.e. domestic partners or roommates) to participate. The family plan
                                        includes member, spouse and all dependent children under 18 years of age. <a
                                            href="#"> Group plans</a> are also available for unions or businesses with
                                        four or more employees.</p>
                                    <p>It’s easy to sign up with MDP. Just complete the <a href="#">application</a>,
                                        either online or a printable version, and when we receive it with your payment,
                                        we will mail you a membership card which entitles you to our special reduced
                                        fees. Then you simply select a dentist convenient for you from our directory of
                                        dentists and schedule your appointment. (Just don’t forget to bring your
                                        membership card.) You pay the <a href="#">dentist directly </a>according to the
                                        MDP fee schedule for services rendered and save up to 100% off of their
                                        traditional fees. Not comfortable with applying online or is your printer out of
                                        ink? No problem, just <a href="#">give us a call</a> and we will send you an
                                        application.</p>
                                    <p>Members are active as soon as payment and application are received. Coverage is
                                        valid for one year to the date of enrollment. Coverage will then be renewed on
                                        an annual basis.</p>
                                    <p>MDP continues to add Dentists and Specialists to our Directory of Dentists on a
                                        regular basis. If there is a particular Dentist you would like to see added as a
                                        participating provider of MDP, just call us and we will gladly contact your
                                        provider to see if they are interested in joining MDP.</p>
                                    <p>Not only does MDP benefit members, but participating doctors, as well. MDP can
                                        help increase the dentist’s volume of patients, while at the same time offering
                                        quality dental care to our members at affordable prices! We all win!</p>
                                    <p>We at MDP are sure you will benefit from our services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            savings: state.general.savings
        };
    },
    {getSavings}
)(ExampleOfSavings);