import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {updateProfile} from "../actions/user";
import Loader from "./Loader";
import Map from "./Map";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {checkString} from "../helpers";

class ProfileDetail extends Component {
    constructor(props) {
        super(props);
        this.handlefName = this.handleChangeText.bind(this, "first_name");
        this.handlelName = this.handleChangeText.bind(this, "last_name");
        this.handlePhone = this.handleChangeText.bind(this, "phone");
        this.handleEmail = this.handleChangeText.bind(this, "email");
        // this.handleAge = this.handleChangeText.bind(this, "dob");
        this.handleGender = this.handleChangeText.bind(this, "gender");
        this.handleOtherDetails = this.handleChangeText.bind(this, "other_details");
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getMapData = this.getMapData.bind(this);
        this.state = {
            first_name: this.props.user.details.first_name,
            last_name: this.props.user.details.last_name,
            phone: this.props.user.details.phone,
            email: this.props.user.email,
            dob: this.props.user.details.dob ? new Date(this.props.user.details.dob) : '',
            gender: this.props.user.details.gender,
            other_details: this.props.user.details.other_details,
            latitude: this.props.user.details.latitude,
            longitude: this.props.user.details.longitude,
        }
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleAge = date => {
        this.setState({
            dob: date
        });
    };

    componentDidMount() {
        document.getElementById("edit-profile-form").onkeypress = function (e) {
            var key = e.charCode || e.keyCode || 0;
            if (key == 13) {
                e.preventDefault();
            }
        }
    }

    async handleUpdate(e) {
        e.preventDefault();
        let form_data = new FormData();
        let date = this.state.dob;
        form_data.append('first_name', checkString(this.state.first_name));
        form_data.append('last_name', checkString(this.state.last_name));
        form_data.append('phone', checkString(this.state.phone));
        form_data.append('email', this.state.email);
        form_data.append('dob', date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
        form_data.append('gender', this.state.gender);
        form_data.append('other_details', checkString(this.state.other_details));
        form_data.append('latitude', checkString(this.state.latitude));
        form_data.append('longitude', checkString(this.state.longitude));
        form_data.append('address', checkString(this.state.address));
        form_data.append('city', checkString(this.state.city));
        form_data.append('state', checkString(this.state.state));
        form_data.append('zipcode', checkString(this.state.zipcode));
        if (this.props.image != null) {
            form_data.append('image', this.props.image);
        }
        await this.props.updateProfile(this.props.user.access.token, form_data);
    }

    getMapData(data) {
        this.setState({
            latitude: data.markerPosition.lat,
            longitude: data.markerPosition.lng,
            address: data.address,
            city: data.city,
            state: data.state,
            zipcode: data.zipcode
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.success && nextProps.success) {
            toast(this.props.successMsg);
        }
    }

    render() {
        let user = this.props.user || null;
        if (user == null) {
            return <Loader/>;
        }
        const ExampleCustomInput = ({value, onClick}) => (
            <input type="text" className="example-custom-input" onClick={onClick} value={value} readOnly/>
        );
        return (
            <div id="prof-det" className="tab-pane active">
                <form id="edit-profile-form" onSubmit={this.handleUpdate}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>First Name</label>
                            <input type="text" name="first_name" placeholder="First Name" onChange={this.handlefName}
                                   value={this.state.first_name} required/>
                        </div>
                        <div className="col-md-6">
                            <label>Last Name</label>
                            <input type="text" name="last_name" placeholder="Last Name" onChange={this.handlelName}
                                   value={this.state.last_name} required/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone Number</label>
                            <input type="number" name="" placeholder="Phone Number" onChange={this.handlePhone}
                                   value={this.state.phone} required/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address</label>
                            <input type="email" name="email"
                                   placeholder="example@gmail.com" onChange={this.handleEmail}
                                   value={this.state.email} disabled/>
                        </div>
                        <div className="col-md-6">
                            <label>Date Of Birth</label>
                            {/*<input type="date" name="dob" id="dob" placeholder="30" onChange={this.handleAge}
                                   value={this.state.dob} required/>*/}
                            <DatePicker
                                selected={this.state.dob}
                                onChange={this.handleAge}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={<ExampleCustomInput/>}
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Gender</label>
                            <select onChange={this.handleGender} value={this.state.gender} name="gender" required>
                                <option disabled selected value="0">Select Gender</option>
                                <option value={10}>Male</option>
                                <option value={20}>Female</option>
                            </select>
                        </div>
                        <div className="col-md-12">
                            <label>Other Medical Details</label>
                            <textarea name="other_details"
                                      placeholder="Enter Medical Details Here" onChange={this.handleOtherDetails}
                                      value={this.state.other_details ? this.state.other_details : ""}></textarea>
                        </div>
                        <div className="col-md-12">
                            <label>Address</label>
                            <Map
                                google={this.props.google}
                                center={{lat: Number(user.details.latitude), lng: Number(user.details.longitude)}}
                                height='300px'
                                zoom={15}
                                mapData={this.getMapData}
                            />

                        </div>
                        <div className="col-md-12">
                            {this.props.request ?
                                <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                <input type="submit" name="" value="Update"/>}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users,
            request: state.general.profileRequest,
            success: state.user.updateProfile || false,
            failed: state.general.profileFailed,
            error: state.general.error,
            successMsg: 'Your Profile has been Updated',
            settings: state.settings ? state.settings.settings : state.general.settings,
        };
    }, {updateProfile}
)(ProfileDetail);