import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="inner-banner inner-banner-faq-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Privacy Policy</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="secure-desc privacy-policy">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="desc">
                                    <p>This privacy policy applies to the personal information that Mainline Dental Plan
                                        uses and protects any information that you give Mainline Dental Plan when you
                                        use this website.</p>
                                    <p>Mainline Dental Plan is committed to ensuring that your privacy is protected.
                                        Should we ask you to provide certain information by which you can be identified
                                        when using this website, you can be assured that it will only be used in
                                        accordance with this privacy policy.Mainline Dental Plan may revise this policy
                                        from time to time by updating this page. You should check this page to ensure
                                        that you are happy with any changes. We will notify you about any significant
                                        changes in the way we treat personal information by sending you a notice to the
                                        primary email address specified in your Mainline Dental Plan account or by
                                        placing a prominent notice on our site. This policy is effective from today.</p>
                                    <h3>What we collect</h3>
                                    <p>When you join Mainline Dental Plan, we ask for information such as your name,
                                        street address, email address, birth date, gender, and zip code. Once you join
                                        with Mainline Dental Plan and sign into our services, you are not anonymous to
                                        us.</p>
                                    <h3>What we do with the information we gather</h3>
                                    <p>Mainline Dental Plan uses this information for the following general purposes:
                                        internal record keeping, improve our product and services, improve our customer
                                        service, contact you, conduct research, and customize the advertising and
                                        content you see.</p>
                                    <h3>Security</h3>
                                    <p>We are committed to ensuring that your information is secure. In order to prevent
                                        unauthorized access or disclosure we have put in place suitable physical,
                                        electronic and managerial procedures to safeguard and secure the information we
                                        collect online.</p>
                                    <p>Mainline Dental Plan also automatically receives and records information on our
                                        server logs from your browser, including your IP address, Mainline Dental Plan
                                        cookie information, and the page you request. “Cookies” are small pieces of
                                        information that are stored by an Internet User’s browser on the Internet User’s
                                        hard drive. Most web browsers automatically accept cookies, but you can usually
                                        modify your browser setting to decline cookies if you prefer. This may prevent
                                        you from taking full advantage of the website.</p>
                                    <h3>Controlling your personal information</h3>
                                    <p>We will not sell, distribute or lease your personal information to third
                                        parties.</p>
                                    <p>You may request details of personal information which we hold about you under the
                                        Data Protection Act 1998. A small fee will be applicable. If you would like a
                                        copy of the information held on you please contact us.</p>
                                    <p>If you believe that any information we are holding on you is incorrect or
                                        incomplete, please contact us as soon as possible. We will promptly correct any
                                        information found to be incorrect.</p>
                                    <h3>Questions and Suggestions</h3>
                                    <p>Mainline Dental Plan wants you to enjoy all of the dental savings discounts that
                                        are available to you as a member of Mainline Dental Plan. However, just as
                                        importantly, we want you to be assured that the information that you entrust to
                                        us stays private and confidential.</p>
                                    <p><strong>Thank you for coming to our web site. We hope to have the honor of
                                        serving you as a member of Mainline Dental Plan for many years to come.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default PrivacyPolicy;