import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {getMember} from "../actions/user";
import Loader from "../components/Loader";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Alerts from "../components/Alerts";
import {toast, ToastContainer} from "react-toastify";
import {PACKAGE_STATUSES} from "../constants";

import { NumericFormat } from 'react-number-format';

class VerifyEligibility extends Component {
    constructor(props) {
        super(props);
        this.handleId = this.handleChangeText.bind(this, "phone");
        this.handleVerify = this.handleVerify.bind(this);
        this.handleNumber = this.handleNumber.bind(this);
        this.state = {
            phone: '',
            show_phone: '',
        }
    }

    handleNumber(values) {
        this.setState({
            phone: values.value,
            show_phone: values.formattedValue,
        })
    }

    handleChangeText(key, txt) {
        let state = {};
        let phone = txt.target.value;
        let first = "(" + phone.slice(0, 3) + ")";
        let second = "";
        let third = "";
        if (phone.length > 3) {
            second = "-" + phone.slice(3, 6);
        }
        if (phone.length > 6) {
            third = "-" + phone.slice(6, -1);
        }
        let final = first + second + third;
        state[key] = txt.target.value;
        state['show_phone'] = final;
        this.setState(state);
    }

    handleVerify(e) {
        e.preventDefault();
        this.props.getMember(this.state.phone);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.error && nextProps.error) {
            toast.error(nextProps.error.message)
        }
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                {/*Banner Section*/}
                <section className="inner-banner inner-banner-eligibility-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Verify Eligibility</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="verify-eligibility">Verify Eligibility</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/*if id == this.props.user.id .... show table*/}
                {/* <table className="eligibility-info-table">
                    <tr>
                        <th>Primary Membership Name:</th>
                        <td>Test Name</td>
                    </tr>
                    <tr>
                        <th>Membership Status:</th>
                        <td>Active</td>
                    </tr>
                    <tr>
                        <th>Membership Expiration:</th>
                        <td>23-03-20</td>
                    </tr>
                </table>*/}
                {/*else show invalid member id */}


                {/*Verification Section*/}
                <section className="ver-1">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-12">
                                <form onSubmit={this.handleVerify}>

                                    <div className="box" style={{textAlign: 'center'}}>
                                        <img src={require("../../images/verify-icon.png")}/>
                                        <h2 className="mb-5">Verify a Patient's Eligibility</h2>
                                        <div className="row inner">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2">
                                                <h4>Membership ID:</h4>
                                            </div>
                                            <div className="col-md-4">
                                               <NumericFormat id="phone-num" placeholder="Example: (000) 000-0000"
                                                              required format="(###) ###-####"
                                                              onValueChange={(values) => {
                                                                  this.handleNumber(values)
                                                              }}/>
                                            </div>
                                            <div className="col-md-2 nopad">
                                                {this.props.getMemberRequest ?
                                                    <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                                    <input type="submit" name="" value="Verify Eligibility"
                                                    />}
                                            </div>
                                        </div>
                                        {this.props.member ? <div className="row mt-5">
                                            <div className="col-md-6">
                                                <strong>Primary Membership Name:</strong>
                                            </div>
                                            <div className="col-md-6">
                                                {this.props.member.details.full_name}
                                            </div>
                                            {this.props.member.sub_member && this.props.member.sub_member.length > 0 && (
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>Plan Members:</strong>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div>
                                                                {this.props.member.sub_member.map((subMember, index) => (
                                                                    <span key={index}>
                                                                        {subMember.first_name} {subMember.last_name}
                                                                        {index !== this.props.member.sub_member.length - 1 && <br />}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-6">
                                                <strong>Membership Status:</strong>
                                            </div>
                                            <div className="col-md-6">
                                            {this.props.member.package_status === 0 ? 
                                                <span style={{ color: 'red' }}>
                                                    {PACKAGE_STATUSES[this.props.member.package_status]}
                                                </span>
                                                : this.props.member.package_status === 1 ?
                                                <span style={{ color: 'green' }}>
                                                    {PACKAGE_STATUSES[this.props.member.package_status]}
                                                </span>
                                                :
                                                PACKAGE_STATUSES[this.props.member.package_status]
                                            }                                                                                         
                                            </div>

                                            <div className="col-md-6">
                                                <strong>Membership Type:</strong>
                                            </div>
                                            <div className="col-md-6">

                                                 {/* {this.props.member.user_subscription?.membership_type_text}  */} 
                                                 <div dangerouslySetInnerHTML={{ __html: this.props.member.user_subscription? 
                                                 this.props.member.user_subscription.membership_type_css : 'N/A' }} />
                                            </div>

                                            <div className="col-md-6">
                                                <strong>Membership Expiration:</strong>
                                            </div>
                                            <div className="col-md-6">
                                                {this.props.member.package_expire_at}
                                            </div>
                                            
                                        </div> : null}
                                        

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>
                {/*<ToastContainer position="top-center"/>*/}
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users,
            member: state.user.member,
            getMemberRequest: state.user.getMemberRequest,
            success: state.user.getMemberSuccess,
            error: state.user.error,
            failed: state.user.getMemberRequestFailed,
        };
    },
    {getMember}
)
(VerifyEligibility);