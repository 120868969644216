import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Loader from "./Loader";
import SubscriptionItem from "./SubscriptionItem";
import {DEFAULT_FAX, DEFAULT_PHONE} from "../constants";

class SubscriptionPlans extends Component {

    render() {
        let subscriptions = this.props.subscriptions || null;
        if (subscriptions == null) {
            return <Loader/>;
        }
        let plans = subscriptions.map((plan, key) => {
            return (
                <SubscriptionItem plan={plan} key={key}/>
            );
        });
        return (
            <>
                <section className="secure-desc">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="desc">
                                    <h2>Secure Online Registration Form</h2>
                                    <div className="text-center">
                                        <h4>MAINLINE DENTAL PLAN, INC. ®</h4>
                                        <h5>9 Furler Street, Totowa, NJ 07512</h5>
                                        <ul>
                                            <li><i className="fa fa-fax" aria-hidden="true"></i><a href="#">{ DEFAULT_FAX }</a></li>
                                        </ul>
                                        <h3>MEMBERSHIP AGREEMENT</h3>
                                    </div>

                                    <p>This agreement between Mainline Dental Plan, Inc. (MDP, Inc.) and member entitles
                                        member to dental care services for the reduced fees as listed in the Fee
                                        Schedule in accordance with the following terms and conditions:</p>
                                    <ul className="points">

                                        <li><p>Membership is for one year and will be billed monthly, quarterly, or
                                            annually.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>MDP is NOT
                                            Insurance.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>If you choose
                                            NOT to renew or cancel your membership, billing will be stopped immediately.
                                        </p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member
                                            understands that these reduced fees are only valid if treatment is provided
                                            by a licensed participating MDP, Inc. dentist.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member
                                            understands that if annual payment is not current, treatment will not be
                                            provided at the MDP, Inc. fees until membership payment is made current. If
                                            MDP, Inc. membership is terminated, plan fees are no longer in effect and
                                            Member is responsible for entire dentist's fee.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member must
                                            contact MDP, Inc. to renew plan when membership expires.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>MDP, Inc.
                                            reserves the right to review fees and adjust accordingly in the first
                                            quarter of each calendar year. Member has the right to terminate membership
                                            at that time without any penalties.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Payment must
                                            be made to the MDP, Inc. participating Dentist when services are rendered
                                            unless other financial arrangements have been made in advance and member's
                                            signature is on file agreeing to payment terms.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Treatment must
                                            be started and completed while Member is enrolled in MDP, Inc.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="member-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-desc text-center">
                                    <h2>Our Plans Make It Even Simpler For You!</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {plans}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            subscriptionRequest: state.subscriptions.subscriptionRequest || false,
            subscriptionFailed: state.subscriptions.subscriptionFailed || false,
            subscriptionSuccess: state.subscriptions.subscriptionSuccess || false,
            error: state.subscriptions.error || false,
            subscriptions: state.subscriptions.subscriptions
        };
    },
    {}
)(SubscriptionPlans);