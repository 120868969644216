import React, {Component} from 'react';
import DentistRegistrationBanner from "../components/DentistRegistrationBanner";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class DentistRegistrationCompleted extends Component {
    render() {
        return (
            <>
                <section className="sec-reg-1">

                    {/*<DentistRegistrationBanner/>*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-success" role="alert">
                                    Your application has been successfully submitted.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default DentistRegistrationCompleted;