import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {getSubscription, stripe} from "../actions/subscription";
import connect from "react-redux/es/connect/connect";
import Loader from "../components/Loader";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {FAMILY, MEMBER_SPOUSE, PARENT_CHILD, SINGLE_MEMBER, DEFAULT_FAX, DEFAULT_PHONE} from "../constants";
import {toast} from "react-toastify";


class MembershipDetails extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            membership_info: {
                amount: this.props.subscription ? this.props.subscription.single_member : 0,
                membership_type: SINGLE_MEMBER,
                membership_id: this.props.match.params.id
            }
        }
    }

    componentDidMount() {
        this.props.getSubscription(this.props.match.params.id);
    }

    handleChangeText(key, txt) {
        this.setState({[key]: JSON.parse(txt.target.value)});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.user.hasOwnProperty('loggedIn') || !this.props.user.loggedIn) {
            toast.error('Login to proceed')
        } else {
            this.props.stripe(this.state.membership_info);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.subscription) {
            this.setState({
                membership_info: {
                    amount: nextProps.subscription.single_member,
                    membership_type: SINGLE_MEMBER,
                    membership_id: this.props.match.params.id
                }
            })
        }
    }

    render() {
        let subscription = this.props.subscription || null;
        if (subscription == null) {
            return <Loader/>;
        }
        console.log(this.state)
        return (
            <>
                <ScrollToTopOnMount/>
                <section className="inner-banner inner-banner-membership-info-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Secure Online Registration Form</h1>
                                <ul className="bread">
                                    <li><a href="/">Home</a></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/membership">Membership Info</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="secure-desc">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="desc">
                                    <h2>Secure Online Registration Form</h2>
                                    <div className="text-center">
                                        <h4>MAINLINE DENTAL PLAN, INC. ®</h4>
                                        <h5>9 Furler Street, Totowa, NJ 07512</h5>
                                        <ul>
                                            <li><i className="fa fa-fax" aria-hidden="true"></i><a href="#">{ DEFAULT_FAX }</a></li>
                                        </ul>
                                        <h3>MEMBERSHIP AGREEMENT</h3>
                                    </div>
                                    <p>This agreement between Mainline Dental Plan, Inc. (MDP, Inc.) and member entitles
                                        member to dental care services for the reduced fees as listed in the Fee
                                        Schedule in accordance with the following terms and conditions:</p>
                                    <ul className="points">
                                        <li><p>Membership is for one year and will be billed monthly, quarterly, or
                                            annually.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>If you choose
                                            NOT to renew or cancel your membership, billing will be stopped immediately.
                                        </p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member
                                            understands that these reduced fees are only valid if treatment is provided
                                            by a licensed participating MDP, Inc. dentist.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member
                                            understands that if annual payment is not current, treatment will not be
                                            provided at the MDP, Inc. fees until membership payment is made current. If
                                            MDP, Inc. membership is terminated, plan fees are no longer in effect and
                                            Member is responsible for entire dentist's fee.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member must
                                            contact MDP, Inc. to renew plan when membership expires.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>MDP, Inc.
                                            reserves the right to review fees and adjust accordingly in the first
                                            quarter of each calendar year. Member has the right to terminate membership
                                            at that time without any penalties.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Payment must
                                            be made to the MDP, Inc. participating Dentist when services are rendered
                                            unless other financial arrangements have been made in advance and member's
                                            signature is on file agreeing to payment terms.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Treatment must
                                            be started and completed while Member is enrolled in MDP, Inc.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="member-1 mem-det">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">Membership</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1"
                                               value={subscription.name} disabled/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Membership Type</label>
                                        <select className="form-control"
                                                id="exampleFormControlSelect1"
                                                onChange={this.handleChangeText.bind(this, 'membership_info')}>
                                            {/*<option value="" disabled selected>Select Membership Type</option>*/}
                                            <option value={JSON.stringify({
                                                amount: subscription.single_member,
                                                membership_type: SINGLE_MEMBER,
                                                membership_id: this.props.match.params.id
                                            })}>Single
                                                Member
                                            </option>
                                            <option value={JSON.stringify({
                                                amount: subscription.member_spouse,
                                                membership_type: MEMBER_SPOUSE,
                                                membership_id: this.props.match.params.id
                                            })}>Member
                                                Spouse
                                            </option>
                                            <option value={JSON.stringify({
                                                amount: subscription.parent_child,
                                                membership_type: PARENT_CHILD,
                                                membership_id: this.props.match.params.id
                                            })}>parent
                                                Child
                                            </option>
                                            <option value={JSON.stringify({
                                                amount: subscription.family,
                                                membership_type: FAMILY,
                                                membership_id: this.props.match.params.id
                                            })}>Family
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">Amount </label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1"
                                               value={this.state.membership_info.amount} disabled/>
                                    </div>
                                    <div className="field">
                                        {this.state.membership_info.amount == 0 ?
                                            <input type="submit" name="login" value="Register Now"
                                                   className="submit" disabled/> :
                                            <input type="submit" name="login" value="Register Now"
                                                   className="submit"/>}

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            subscription: state.subscriptions.subscription || null
        };
    },
    {getSubscription, stripe}
)(MembershipDetails);