import React, {Component} from 'react';
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import {NavLink} from "react-router-dom";

class HomeSignUpSection extends Component {
    render() {
        return (
            <section className="sec-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="lf">
                                <h2>Keep Smiling and Start Saving Today!</h2>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="rt">
                                <NavLink to="/membership" className="btn-bl">Sign Up Now</NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default HomeSignUpSection;