import React, {Component} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import connect from "react-redux/es/connect/connect";
import {getTestimonials} from "../actions/general";
import TestimonialItem from "./TestimonialItem";
import Loader from "./Loader";
import Rater from "react-rater";

class Testimonials extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getTestimonials();
    }

    render() {
        let testimonials = this.props.testimonials || null;
        if (testimonials == null) {
            return <Loader/>;
        }
        let TestimonialItems = testimonials.map((testimonials, key) => {
            return (
                <Carousel.Item key={key}>
                    <div className="test-image-box">
                        <img
                            className="d-block"
                            src={testimonials.image_url}
                            alt={testimonials.name}
                        />
                    </div>


                    <Carousel.Caption>
                        <p>{testimonials.description}</p>
                        <div className="rating">
                            <Rater rating={testimonials.rating} total={5} interactive={false}/>
                        </div>
                        <h3>{testimonials.name}</h3>
                        <h6>Satisfied Mainline Member</h6>
                    </Carousel.Caption>
                </Carousel.Item>
            )
        });
        return (
            <section className="sec-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="top-desc text-center">
                                <h2>Testimonials</h2>
                                <p>Becoming a member of MDP has helped people receive the dental care they need to maintain proper oral health and save money with our reduced fees.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box text-center">
                                <Carousel>
                                    {TestimonialItems}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            testimonials: state.general.testimonials
        };
    },
    {getTestimonials}
)
(Testimonials);