import React from 'react'
import {withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker} from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import {GOOGLE_API_KEY} from "../constants";

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            zipcode: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            }
        }
    }

    /**
     * Get the current address from the default map position and set those values in the state
     */
    componentDidMount() {
        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    zipcode = this.getPostalCode(addressArray);

                this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    zipcode: (zipcode) ? zipcode : '',
                })
                this.props.mapData(this.state);
            },
            error => {
                console.error(error);
            }
        );
    };

    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.markerPosition.lat !== this.props.center.lat ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state
        ) {
            return true
        } else if (this.props.center.lat === nextProps.center.lat) {
            return false
        }
    }

    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };
    getPostalCode = (addressArray) => {
        let zipcode = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
                zipcode = addressArray[i].long_name;
                return zipcode;
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };
    /**
     * And function for city,state and address input
     * @param event
     */
    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    onAddressChange = (event) => {
        this.setState({address: event.target.value})
    }
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = (event) => {
    };
    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = async (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray),
            zipcode = this.getPostalCode(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
// Set these values in the state.
        await this.setState({
            address: (address) ? address : '',
            area: (area) ? area : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            zipcode: (zipcode) ? zipcode : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
        await this.props.mapData(this.state);
    };
    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng(),
            addressArray = [];
        Geocode.fromLatLng(newLat, newLng).then(
            async response => {
                console.log('place:', response)
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    zipcode = this.getPostalCode(addressArray);
                await this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    zipcode: (zipcode) ? zipcode : '',
                    markerPosition: {lat: newLat, lng: newLng}
                })
                await this.props.mapData(this.state);
            },
            error => {
                console.error(error);
            }
        );

    };

    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap style={{
                        position: 'relative',
                    }} google={this.props.google}
                               defaultZoom={this.props.zoom}
                               defaultCenter={{lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng}}>
                        {/* For Auto complete Search Box */}
                        <Autocomplete
                            style={{
                                width: '97%',
                                height: '40px',
                                paddingLeft: '16px',
                                marginTop: '2px',
                                marginBottom: '100px',
                                position: 'absolute',
                                top: '30px'
                            }}
                            placeholder={this.state.address}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['(regions)']}
                        />
                        <Marker google={this.props.google}
                                name={'Dolores park'}
                                draggable={true}
                                onDragEnd={this.onMarkerDragEnd}
                                position={{lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng}}
                        />
                        <Marker/>
                        {/* InfoWindow on top of marker */}
                        {/*<InfoWindow
                            onClose={this.onInfoWindowClose}
                            position={{
                                lat: (this.state.markerPosition.lat + 0.0018),
                                lng: this.state.markerPosition.lng
                            }}
                        >
                            <div>
                                <span style={{padding: 0, margin: 0}}>{this.state.address}</span>
                            </div>
                        </InfoWindow>*/}
                    </GoogleMap>
                )
            )
        );
        let map;
        let url = "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_API_KEY + "&libraries=places";
        if (this.props.center.lat !== undefined) {
            map = <div style={{marginTop: '50px', marginBottom: "30px"}}>
                <input type="hidden" id="latitude" value={this.state.markerPosition.lat}/>
                <input type="hidden" id="longitude" value={this.state.markerPosition.lng}/>
                <AsyncMap
                    googleMapURL={url}
                    loadingElement={
                        <div style={{height: `100%`}}/>
                    }
                    containerElement={
                        <div style={{height: this.props.height}}/>
                    }
                    mapElement={
                        <div style={{height: `100%`}}/>
                    }
                />
            </div>
        } else {
            map = <div style={{height: this.props.height}}/>
        }
        return (map)
    }
}

export default Map