import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class HomeBanner extends Component {
    render() {
        return (
            <section className="sec-1">
                <div className="sec-1-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="desc">
                                    <h2>Individual, Family & Group Dental Plans</h2>
                                    <h5>An Affordable Alternative To Dental Insurance</h5>
                                    <p>Mainline Dental Plan is an alternative to traditional dental insurance. Our fee for service dental plan, provides a network of dentists to individuals and groups with reduced rates on all procedures. Our goal has always been to make certain that everyone can receive affordable dental care to maintain great oral health.</p>
                                    <p>Mainline Dental Plan (MDP) allows members to utilize a network of dental providers that will offer dental services at reduced fees as listed on our website. You simply pay the dentist directly at the agreed upon fee schedule, for the services provided. Any service not listed will be offered at a 25% discount from the dentist’s usual and customary fees. </p>
                                    <p> The savings with MDP versus a dentist's usual and customary fees can offer our members substantial savings. </p>
                                    <h5>Savings From 25% To 100% Off the Regular Dentist's Fees	</h5>
                                    <NavLink to="/membership" className="btn-wt">Sign Up Now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HomeBanner;