import React, {Component} from 'react';
import {Markup} from 'interweave';
import {NavLink} from "react-router-dom";

class SubscriptionItem extends Component {
    render() {
        let {plan} = this.props;
        return (
            <div className="col-md-4">
                <div className="outter text-center">
                    <div className="inner-1">
                        <div className="thumb">
                            <img src={plan.icon_url}/>
                        </div>
                        <h3>{plan.name}</h3>
                        <span>Subscription</span>
                    </div>
                    <div className="inner-2">
                        <div className="inner-row">
                            <h4><sup>$</sup>{plan.member_spouse.toFixed(2)}</h4>
                            <h6>Member and Spouse <span>(or domestic partner)</span></h6>
                        </div>
                        <div className="inner-row">
                            <h4><sup>$</sup>{plan.single_member.toFixed(2)}</h4>
                            <h6>Single Member</h6>
                        </div>
                        <div className="inner-row">
                            <h4><sup>$</sup>{plan.parent_child.toFixed(2)}</h4>
                            <h6>Parent/Child</h6>
                        </div>
                        <div className="inner-row">
                            <h4><sup>$</sup>{plan.family.toFixed(2)}</h4>
                            <h6>Family</h6>
                        </div>
                    </div>
                    {/*<Markup content={plan.description}/>*/}
                    {/*<NavLink to={"membership/" + plan.id} className="btn-bl">View Details</NavLink>*/}
                </div>
            </div>
        );
    }
}

export default SubscriptionItem;