import React, {Component} from 'react';
import FaqsQuestions from "../components/FaqsQuestions";
import {NavLink} from "react-router-dom";

class FaQs extends Component {

    render() {
        let hash = this.props.location.hash;
        return (
            <>
                {/*Banner Section*/}
                <section className="inner-banner inner-banner-faq-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>FAQs</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/faqs">FAQs</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*FAQsSection*/}
                <FaqsQuestions hash={hash}/>


                <section className="faq-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="desc">
                                    <p>All MDP membership fees are fully refundable within 48 business hours of your application receipt. Requests for cancellation must be by calling us at 201-357-7147, between the hours of 8am-5pm EST. If
                                        you find you need to cancel beyond the allowable 48 hour period, requests must
                                        be made in writing and is subject to a 25% cancellation processing fee and
                                        monthly prorate. Final determinations are at the discretion of the
                                        management.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FaQs;