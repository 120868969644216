import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {getSavings} from "../actions/general";
import Loader from "./Loader";

class Savings extends Component {
    render() {
        let savings = this.props.savings || null;
        if (savings == null) {
            return <Loader/>;
        }
        let savingElement = savings.map((saving, key) => {
            return <tr>
                <td>{saving.dental_procedure}</td>
                <td>{saving.regular_fee}</td>
                <td>{saving.mdp_fee}</td>
                <td>{saving.savings}</td>
            </tr>
        });
        return (
            <section className="sec-savings">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="add-member">
                                <thead>
                                <tr>
                                    <th>Dental Procedure</th>
                                    <th>Regular Fee*</th>
                                    <th>MDP Fee**</th>
                                    <th>Savings</th>
                                </tr>
                                </thead>
                                <tbody>
                                {savingElement}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            savings: state.general.savings
        };
    },
    {}
)(Savings);