import {requestSuccessAction, requestFailureAction, curl_get, curl_post, curl_delete, curl_update} from "./http";
import {BASE_URL, ENDPOINTS} from "../constants";
import {
    gotoHome,
    gotoVerifyResetCode,
    gotoResetPassword,
    gotoLogin,
    gotoEditProfile,
    gotoStep,
    gotoThanks,
    gotoProfile
} from "./router";

// Action Types
const types = {
    USER_LOGIN: "USER_LOGIN",
    USER_LOGOUT: "USER_LOGOUT",
    USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    USER_LOGIN_FAILED: "USER_LOGIN_FAILED",

    USER_FORGOT: "USER_FORGOT",
    USER_FORGOT_REQUEST: "USER_FORGOT_REQUEST",
    USER_FORGOT_FAILED: "USER_FORGOT_FAILED",

    VERIFY_CODE: "VERIFY_CODE",
    VERIFY_CODE_REQUEST: "VERIFY_CODE_REQUEST",
    VERIFY_CODE_FAILED: "VERIFY_CODE_FAILED",

    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

    USER_REGISTRATION: "USER_REGISTRATION",
    USER_REGISTRATION_REQUEST: "USER_REGISTRATION_REQUEST",
    USER_REGISTRATION_FAILED: "USER_REGISTRATION_FAILED",

    SOCIAL_LOGIN: "SOCIAL_LOGIN",
    SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
    SOCIAL_LOGIN_FAILED: "SOCIAL_LOGIN_FAILED",

    REFRESH_TOKEN: "REFRESH_TOKEN",
    REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
    REFRESH_TOKEN_FAILED: "REFRESH_TOKEN_FAILED",

    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",

    GET_USERS: "GET_USERS",
    GET_USERS_REQUEST: "GET_USERS_REQUEST",
    GET_USERS_FAILED: "GET_USERS_FAILED",

    GET_MEMBERS: "GET_MEMBERS",
    GET_MEMBERS_REQUEST: "GET_MEMBERS_REQUEST",
    GET_MEMBERS_FAILED: "GET_MEMBERS_FAILED",

    GET_MEMBER: "GET_MEMBER",
    GET_MEMBER_REQUEST: "GET_MEMBER_REQUEST",
    GET_MEMBER_FAILED: "GET_MEMBER_FAILED",

    SEARCH_DENTISTS: "SEARCH_DENTISTS",
    SEARCH_DENTISTS_REQUEST: "SEARCH_DENTISTS_REQUEST",
    SEARCH_DENTISTS_FAILED: "SEARCH_DENTISTS_FAILED",

    UPDATE_PROFILE: "UPDATE_PROFILE",
    UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
    UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",

    ADD_FAMILY_REQUEST: "ADD_FAMILY_REQUEST",
    ADD_FAMILY: "ADD_FAMILY",
    ADD_FAMILY_FAILED: "ADD_FAMILY_FAILED",

    DENTIST_REGISTRATION: "DENTIST_REGISTRATION",
    DENTIST_REGISTRATION_REQUEST: "DENTIST_REGISTRATION_REQUEST",
    DENTIST_REGISTRATION_FAILED: "DENTIST_REGISTRATION_FAILED",

    DELETE_MEMBER: "DELETE_MEMBER",
    DELETE_MEMBER_REQUEST: "DELETE_MEMBER_REQUEST",
    DELETE_MEMBER_FAILED: "DELETE_MEMBER_FAILED",

    UPDATE_DENTIST: "UPDATE_DENTIST",
    UPDATE_DENTIST_REQUEST: "UPDATE_DENTIST_REQUEST",
    UPDATE_DENTIST_FAILED: "UPDATE_DENTIST_FAILED",

    GET_DENTIST: "GET_DENTIST",
    GET_DENTIST_REQUEST: "GET_DENTIST_REQUEST",
    GET_DENTIST_FAILED: "GET_DENTIST_FAILED",

    DELETE_DENTIST: "DELETE_DENTIST",
    DELETE_DENTIST_REQUEST: "DELETE_DENTIST_REQUEST",

    GET_USER_REQUEST: "GET_USER_REQUEST",
    GET_USER: "GET_USER",
    GET_USER_FAILED: "GET_USER_FAILED",

    REPORT_DENTIST_REQUEST: "REPORT_DENTIST_REQUEST",
    REPORT_DENTIST: "REPORT_DENTIST",
    REPORT_DENTIST_FAILED: "REPORT_DENTIST_FAILED",
};
export default types;

export function logout() {

    return (dispatch) => {
        dispatch(requestSuccessAction({}, types.USER_LOGOUT))
        dispatch(gotoHome());
    }
}

export function login(email, password, props) {
    let config = {
        headers: {"Access-Control-Allow-Origin": "*"}
    };
    return (dispatch) => {
        dispatch({type: types.USER_LOGIN_REQUEST})
        let data = {
            email,
            password,
            device_type: "web"
        };
        curl_post(dispatch, BASE_URL + ENDPOINTS.LOGIN, data, types.USER_LOGIN_FAILED, (data) => {
            dispatch(requestSuccessAction(data, types.USER_LOGIN))
            dispatch(getUser(data.data.user.access_token));
            // Set Success Message then Redirect To Home
            dispatch(gotoProfile());
        }, config);
    }
}

export function forgot(email) {

    return (dispatch) => {

        dispatch({type: types.USER_FORGOT_REQUEST, payload: {email}});

        curl_get(dispatch, BASE_URL + ENDPOINTS.FORGOT, {email}, types.USER_FORGOT_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.USER_FORGOT));

            setTimeout(() => {
                dispatch(gotoVerifyResetCode());
            }, 2000)
        })
    }
}

export function verifycode(code) {
    return (dispatch) => {
        dispatch({type: types.VERIFY_CODE_REQUEST, payload: {code}});
        let data = {
            verification_code: code,
            device_type: "web"
        };
        curl_post(dispatch, BASE_URL + ENDPOINTS.VERIFY_CODE, data, types.VERIFY_CODE_FAILED, (data) => {
            dispatch(requestSuccessAction(data, types.VERIFY_CODE))
            // Set Success Message then Redirect To RESET PASSWORD SCREEN
            setTimeout(() => {
                dispatch(gotoResetPassword());
            }, 2000)

        });
    }
}

export function resetpassword(email, code, password, password_confirmation) {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_REQUEST});
        let data = {
            email,
            verification_code: code,
            password,
            password_confirmation,
            device_type: "web"
        };
        curl_post(dispatch, BASE_URL + ENDPOINTS.RESET_PASSWORD, data,
            types.RESET_PASSWORD_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.RESET_PASSWORD))
                // Set Success Message then Redirect To RESET PASSWORD SCREEN
                setTimeout(() => {
                    dispatch(gotoLogin());
                }, 2000)

            });
    }
}

export function registration(first_name, last_name, email, password, password_confirmation) {
    let name = first_name + ' ' + last_name;
    return (dispatch) => {
        dispatch({type: types.USER_REGISTRATION_REQUEST});
        let data = {
            name,
            first_name,
            last_name,
            email,
            password,
            password_confirmation,
            device_type: "web"
        };
        curl_post(dispatch, BASE_URL + ENDPOINTS.USER_REGISTRATION, data,
            types.USER_REGISTRATION_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.USER_REGISTRATION))

                // Set Success Message then Redirect To HOME SCREEN
                setTimeout(() => {
                    dispatch(gotoEditProfile());
                }, 2000)

            });
    }
}

export function socailLogin(data) {

    return (dispatch) => {
        dispatch({type: types.SOCIAL_LOGIN_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.SOCIAL_LOGIN, data,
            types.SOCIAL_LOGIN_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.SOCIAL_LOGIN))
                // Set Success Message then Redirect To HOME SCREEN
                setTimeout(() => {
                    dispatch(gotoHome());
                }, 2000)

            });
    }
}

//change password
export function changePassword(current_password, password, password_confirmation, token) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.CHANGE_PASSWORD_REQUEST});

        curl_post(dispatch, BASE_URL + ENDPOINTS.CHANGE_PASSWORD + '?current_password=' + current_password + '&password=' + password + '&password_confirmation=' + password_confirmation, {},
            types.CHANGE_PASSWORD_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.CHANGE_PASSWORD))

            }, config);
    }
}

export function getUsers(token, payload) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.GET_USERS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_USERS, payload, types.GET_USERS_FAILED, (data) => {
            dispatch(requestSuccessAction(data, types.GET_USERS))
        }, config)
    }
}

// METHOD is in general.js
export function updateProfile(token, data) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.UPDATE_PROFILE_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.UPDATE_PROFILE, data,
            types.UPDATE_PROFILE_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.UPDATE_PROFILE))
                dispatch(gotoProfile())
            }, config);
    }
}

// GET FAMILY MEMBERS
export function getMembers(token, parent_id) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.GET_MEMBERS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_USERS, {
            parent_id: parent_id,
            orderBy: 'id',
            sortedBy: 'desc'
        }, types.GET_MEMBERS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_MEMBERS))
        }, config)
    }
}

// ADD FAMILY MEMBERS
export function addMember(token, payload) {
    return (dispatch) => {
        dispatch({type: types.ADD_FAMILY_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.ADD_FAMILY, payload,
            types.ADD_FAMILY_FAILED, (data) => {
                // get all family member here
                dispatch(getMembers(token, payload.parent_id));
                dispatch(requestSuccessAction(data, types.ADD_FAMILY))
            });
    }
}

export function deleteMember(token, id, parent_id) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.DELETE_MEMBER_REQUEST});
        curl_delete(dispatch, BASE_URL + ENDPOINTS.GET_USERS + '/' + id,
            types.DELETE_MEMBER_FAILED, (data) => {
                // get all family member here
                dispatch(getMembers(token, parent_id));
                dispatch(requestSuccessAction(data, types.DELETE_MEMBER))
            }, config);
    }
}

export function dentistRegistration(data) {
    return (dispatch) => {
        dispatch({type: types.DENTIST_REGISTRATION_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.DENTIST_REGISTRATION, data,
            types.DENTIST_REGISTRATION_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.DENTIST_REGISTRATION))
                dispatch(gotoStep(2));
            });
    }
}

export function updateDentist(id, data, step) {
    return (dispatch) => {
        dispatch({type: types.UPDATE_DENTIST_REQUEST});
        curl_update(dispatch, BASE_URL + ENDPOINTS.UPDATE_DENTIST + '/' + id, data,
            types.UPDATE_DENTIST_FAILED, (data) => {
                if (step == 6) {
                    dispatch({type: types.DELETE_DENTIST_REQUEST});
                    setTimeout(function () {
                        dispatch({type: types.DELETE_DENTIST});
                        dispatch(gotoThanks());
                    }, 3000);
                } else {
                    dispatch(gotoStep(step));
                }

            });
    }
}

export function getDentist(dentist_id) {
    return (dispatch) => {
        dispatch({type: types.GET_DENTIST_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_DENTIST + '/' + dentist_id, {}, types.GET_DENTIST_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_DENTIST))
        })
    }
}

export function getUser(token) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.GET_USER_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.ME, {}, types.GET_USER_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_USER))
        }, config)
    }
}

// get member
export function getMember(member_id) {
    return (dispatch) => {
        dispatch({type: types.GET_MEMBER_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_MEMBER + '/' + member_id, {role: 3}, types.GET_MEMBER_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_MEMBER))
        })
    }
}
const initialState = {
    member: null,
    getMemberRequest: false,
    getMemberSuccess: false,
    getMemberFailed: false,
    error: null,
  };
export function userReducer(state = initialState, action) {
    switch (action.type) {
      case types.GET_MEMBER_REQUEST:
        return {
          ...state,
          getMemberRequest: true,
          getMemberFailed: false,
          error: null,
        };
      case types.GET_MEMBER:
        return {
          ...state,
          member: action.payload.data,
          getMemberRequest: false,
          getMemberSuccess: true,
        };
      case types.GET_MEMBER_FAILED:
        return {
          ...state,
          getMemberRequest: false,
          getMemberFailed: true,
          error: action.error,
        };
      default:
        return state;
    }
  }

export function reportDentist(payload, token) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.REPORT_DENTIST_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.REPORT_DENTIST, payload, types.REPORT_DENTIST_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.REPORT_DENTIST))
        }, config)
    }
}