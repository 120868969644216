import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class DentistRegistrationBanner extends Component {

    render() {
        return (
            <section className="inner-banner dentist-registration-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Registration Form</h1>
                            <ul className="bread">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><span><i className="fa fa-angle-right"></i></span></li>
                                <li>
                                    <NavLink to="/dentist-registration/1">Registration Form</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DentistRegistrationBanner;