import React, {Component} from 'react';

class PaymentSection extends Component {
    render() {
        return (
            <div>
                <div className="col-md-6">
                    <img style={{width: "100%", height: "auto"}} className="pad-15"
                         src={require("../../images/accepted-cards.png")}/>
                </div>
                <div className="col-md-6">

                    <a href="https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/"
                       onMouseOver="window.status='http://www.authorize.net/'; return true;"
                       onMouseOut="window.status=''; return true;"
                       onClick="window.open('https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;"
                       rel="noopener noreferrer" target="_blank">
                        <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" width="90"
                             height="72" border="0" alt="Authorize.Net Merchant - Click to Verify"/>
                    </a>
                </div>
                <div className="col-md-12 mrgn-top">
                    <label>Credit Card Type: *</label>
                    <label className="sm-b"><input type="radio" name="creditcardtype" value="Visa"
                                                   checked=""/>Visa</label>
                    <label className="sm-b"><input type="radio" name="creditcardtype"
                                                   value="MasterCard"/>MasterCard</label>
                    <label className="sm-b"><input type="radio" name="creditcardtype" value="Amex"/>Amex</label>
                    <label className="sm-b"><input type="radio" name="creditcardtype" value="Discover"/>Discover</label>
                </div>
                <div className="col-md-6">
                    <label>Credit Card Number: *</label>
                    <input className="def" type="number" name="" placeholder="Credit Card Number"/>
                </div>
                <div className="col-md-6">
                    <label>City: *</label>
                    <input className="def" type="number" name="" placeholder="City"/>
                </div>
                <div className="col-md-6">
                    <label>State: *</label>
                    <input className="def" type="number" name="" placeholder="State"/>
                </div>
                <div className="col-md-6">
                    <label>ZIP Code: *</label>
                    <input className="def" type="number" name="" placeholder="ZIP Code"/>
                </div>
            </div>
        );
    }
}

export default PaymentSection;