import React, {Component} from 'react';
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import BlogDetailItem from "../components/BlogDetailItem";
import Loader from "../components/Loader";
import connect from "react-redux/es/connect/connect";
import {getBlogDetails} from "../actions/general";

class BlogDetail extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getBlogDetails(this.props.match.params.id);
    }

    render() {
        let blogDetails = this.props.blogDetail || null;
        if (blogDetails == null) {
            return <Loader/>;
        }
        // console.log("blogdetial", blogDetails);
        return false;

        return (
            <section className="blog-main blog-det">
                <ScrollToTopOnMount/>
                <div className="container">
                    <div className="row">

                        {/*<BlogDetailItem/>*/}


                        {/*This section remains constant on every page*/}
                        <div className="col-md-4">
                            <div className="rt">
                                <div className="search">
                                    <input type="search" name="" placeholder="Search.." required/>
                                    <input type="submit" name="" value="Search"/>
                                </div>
                                <div className="side-box">
                                    <div className="upper">
                                        <h3>Recent Posts</h3>
                                    </div>
                                    <div className="inner">
                                        <div className="inner-row">
                                            <h4>It is a long established fact that a reader.</h4>
                                            <h5>By Inhype January 10, 2020, 5 Mins read</h5>
                                        </div>
                                        <div className="inner-row">
                                            <h4>It is a long established fact that a reader.</h4>
                                            <h5>By Inhype January 10, 2020, 5 Mins read</h5>
                                        </div>
                                        <div className="inner-row">
                                            <h4>It is a long established fact that a reader.</h4>
                                            <h5>By Inhype January 10, 2020, 5 Mins read</h5>
                                        </div>
                                        <div className="inner-row">
                                            <h4>It is a long established fact that a reader.</h4>
                                            <h5>By Inhype January 10, 2020, 5 Mins read</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end search & recent Posts section*/}

                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            blogDetail: state.general.blogDetails
        };
    },
    {getBlogDetails}
)(BlogDetail);