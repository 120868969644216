import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import PrintableForm from "../../files/appform.pdf";

class MembershipInfoRegisterationSection extends Component {
    render() {
        return (
            <>
                <section className="member-2">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-6 nopad">
                                <a href="/faqs#cancellation" target="_blank">
                                    <div className="box">
                                        <div className="icon">
                                            <img src={require("../../images/mem-2-icon-3.png")}/>
                                        </div>
                                        <h3>Cancellation Policy</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default MembershipInfoRegisterationSection;