import React, {Component} from 'react';
import DashboardHeader from "./DashboardHeader";
import connect from "react-redux/es/connect/connect";
import {NavLink} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "../../css/dashboard/custom.css";
import "../../css/dashboard/rwd.css";

class Auth extends Component {
    componentWillMount() {
        if (!this.props.user.hasOwnProperty('loggedIn') || !this.props.user.loggedIn) {
            return window.location.href = "/login";
        }
    }

    render() {
        return (
            <div>
                <DashboardHeader/>
                {this.props.user.package_status == 0 && this.props.location.pathname != "/payment" ?
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-info" role="alert">
                                    You have not subscribed to any package.
                                    <NavLink to="/membership" className="ml-3">Subscribe Now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                {this.props.children}
                <ToastContainer position="top-center"/>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user
        };
    },
    {}
)(Auth);