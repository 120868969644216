import {GENERAL_ACTIONS, USER_ACTIONS, SUBSCRIPTION_ACTIONS} from "../actions";

export default function user(state = {}, action) {
    switch (action.type) {
        // Loop Example
        case USER_ACTIONS.USER_LOGIN: {
            let {user} = action.payload.data;
            let newState = {
                ...state,
                loggedIn: true,
                failed: false,
                details: user.details,
                user_id: user.id,
                email: user.email,
                package_status: user.package_status,
                package_expire_at: user.package_expire_at,
                role: user.roles_csv,
                access: {
                    token: user.access_token,
                    expires: user.expires_in,
                    type: user.token_type
                },
                message: action.payload.message,
                request: false,
                error: false
            };
            return newState;
        }
        case USER_ACTIONS.USER_LOGOUT: {
            return {...state, loggedIn: false, failed: false, details: {}};
        }
        case USER_ACTIONS.USER_LOGIN_FAILED: {
            return {
                ...state,
                loggedIn: false,
                error: action.error,
                failed: true,
                request: false
            };
        }
        case USER_ACTIONS.USER_LOGIN_REQUEST : {
            return {...state, request: true, failed: false, error: false}
        }

        case USER_ACTIONS.REFRESH_TOKEN: {
            // TODO: Handle Other Fields (Possibility of changed values from other client);
            let {user} = action.payload.data;
            return {
                ...state,
                access: {
                    token: user.access_token,
                    expires: user.expires_in,
                    type: user.token_type
                }
            }
        }

        case USER_ACTIONS.USER_FORGOT_REQUEST: {
            return {...state, forgotRequest: true, forgotFailed: false, forgotEmail: action.payload.email};
        }
        case USER_ACTIONS.USER_FORGOT_FAILED: {
            return {...state, forgotRequest: false, forgotFailed: true, error: action.error};
        }
        case USER_ACTIONS.USER_FORGOT: {
            return {
                ...state,
                forgotRequest: false,
                forgotFailed: false,
                forgotSuccess: true,
                forgotMsg: action.payload.data.message
            };
        }

        case USER_ACTIONS.VERIFY_CODE_REQUEST: {
            return {...state, verifyCodeRequest: true, verifyCodeFailed: false, verifyCode: action.payload.code};
        }
        case USER_ACTIONS.VERIFY_CODE_FAILED: {
            return {...state, verifyCodeRequest: false, verifyCodeFailed: true, error: action.error};
        }
        case USER_ACTIONS.VERIFY_CODE: {
            return {
                ...state,
                verifyCodeRequest: false,
                verifyCodeFailed: false,
                verifyCodeSuccess: true,
                verifyCodeMsg: action.payload.message
            };
        }

        case USER_ACTIONS.RESET_PASSWORD_REQUEST: {
            return {
                ...state,
                resetPasswordRequest: true,
                resetPasswordFailed: false
            };
        }
        case USER_ACTIONS.RESET_PASSWORD_FAILED: {
            return {...state, resetPasswordRequest: false, resetPasswordFailed: true, error: action.error};
        }
        case USER_ACTIONS.RESET_PASSWORD: {
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordFailed: false,
                resetPasswordSuccess: true,
                resetPasswordMsg: action.payload.message
            };
        }

        case USER_ACTIONS.USER_REGISTRATION_REQUEST: {
            return {
                ...state,
                userRegistrationRequest: true,
                userRegistrationFailed: false,
                error: false
            };
        }
        case USER_ACTIONS.USER_REGISTRATION_FAILED: {
            return {...state, userRegistrationRequest: false, userRegistrationFailed: true, error: action.error};
        }
        case USER_ACTIONS.USER_REGISTRATION: {
            let {user} = action.payload.data;
            let newState = {
                ...state,
                loggedIn: true,
                failed: false,
                details: user.details,
                user_id: user.id,
                email: user.email,
                package_status: user.package_status,
                package_expire_at: user.package_expire_at,
                role: user.roles_csv,
                access: {
                    token: user.access_token,
                    expires: user.expires_in,
                    type: user.token_type
                },
                request: false,

                userRegistrationRequest: false,
                userRegistrationFailed: false,
                userRegistrationSuccess: true,
                userRegistrationMsg: action.payload.message,
                error: false
            };
            return newState;
        }

        //social login
        case USER_ACTIONS.SOCIAL_LOGIN_REQUEST: {
            return {
                ...state,
                socailLoginRequest: true,
                socailLoginFailed: false
            };
        }
        case USER_ACTIONS.SOCIAL_LOGIN_FAILED: {
            return {...state, socailLoginRequest: false, socailLoginFailed: true, error: action.error};
        }
        case USER_ACTIONS.SOCIAL_LOGIN: {
            let {user} = action.payload.data;
            let newState = {
                ...state,
                loggedIn: true,
                failed: false,
                details: user.details,
                user_id: user.id,
                email: user.email,
                role: user.roles_csv,
                access: {
                    token: user.access_token,
                    expires: user.expires_in,
                    type: user.token_type
                },
                request: false,

                socailLoginRequest: false,
                socailLoginFailed: false,
                socailLoginSuccess: true,
                socailLoginMsg: action.payload.message
            };
            return newState;
        }
        //end

        //chnage password
        case USER_ACTIONS.CHANGE_PASSWORD: {
            return {
                ...state,
                passwordRequest: false,
                passwordRequestFailed: false,
                passwordSuccess: true,
                passwordSuccessMsg: action.payload.message,
                error: false
            };
        }
        case USER_ACTIONS.CHANGE_PASSWORD_FAILED: {
            return {
                ...state,
                error: action.error,
                passwordRequestFailed: true,
                passwordRequest: false,
                passwordSuccess: false
            };
        }
        case USER_ACTIONS.CHANGE_PASSWORD_REQUEST : {
            return {...state, passwordRequest: true, passwordRequestFailed: false, passwordSuccess: false, error: false}
        }

        //get users
        case USER_ACTIONS.GET_USERS: {
            return {
                ...state,
                usersRequest: false,
                usersRequestFailed: false,
                users: action.payload.data
            };
        }
        case USER_ACTIONS.GET_USERS_FAILED: {
            return {...state, error: action.error, usersRequestFailed: true, usersRequest: false};
        }
        case USER_ACTIONS.GET_USERS_REQUEST : {
            return {...state, usersRequest: true, usersRequestFailed: false}
        }
        /* update profile */
        case USER_ACTIONS.UPDATE_PROFILE_REQUEST: {
            return {
                ...state,
                requestUpdateProfile: true,
                failedUpdateProfile: false,
                updateProfile: false
            };
        }
        case USER_ACTIONS.UPDATE_PROFILE_FAILED: {
            return {
                ...state,
                requestUpdateProfile: false,
                updateProfile: false,
                failedUpdateProfile: true,
                error: action.error
            };
        }
        case USER_ACTIONS.UPDATE_PROFILE: {
            let {data} = action.payload;
            return {
                ...state,
                details: data,
                requestUpdateProfile: false,
                failedUpdateProfile: false,
                updateProfile: true
            };
        }

        //add family
        case USER_ACTIONS.ADD_FAMILY_REQUEST: {
            return {...state, familyRequest: true, familyFailed: false, familySuccess: false};
        }
        case USER_ACTIONS.ADD_FAMILY: {
            return {
                ...state,
                familyRequest: false,
                familyFailed: false,
                familySuccess: true,
                familySuccessMsg: action.payload.message
            };

        }
        case USER_ACTIONS.ADD_FAMILY_FAILED: {
            return {...state, familyRequest: false, error: action.error, familyFailed: true, familySuccess: false};
        }

        //get members
        case USER_ACTIONS.GET_MEMBERS: {
            return {
                ...state,
                membersRequest: false,
                membersRequestFailed: false,
                familyMembers: action.payload.data.data
            };
        }
        case USER_ACTIONS.GET_MEMBERS_FAILED: {
            return {...state, error: action.error, membersRequestFailed: true, membersRequest: false};
        }
        case USER_ACTIONS.GET_MEMBERS_REQUEST : {
            return {...state, membersRequest: true, deleteMemberSuccess: false, membersRequestFailed: false}
        }
        //delete members
        case USER_ACTIONS.DELETE_MEMBER: {
            return {
                ...state,
                deleteMemberRequest: false,
                deleteMemberFailed: false,
                deleteMemberSuccess: true
            };
        }
        case USER_ACTIONS.DELETE_MEMBER_FAILED: {
            return {
                ...state,
                error: action.error,
                deleteMemberFailed: true,
                deleteMemberSuccess: false,
                deleteMemberRequest: false
            };
        }
        case USER_ACTIONS.DELETE_MEMBER_REQUEST : {
            return {...state, deleteMemberRequest: true, deleteMemberFailed: false}
        }

        //dentist registration
        case USER_ACTIONS.DENTIST_REGISTRATION: {
            let {data} = action.payload;
            return {
                ...state,
                dentist_id: data.id,
                dentistRegistrationRequest: false,
                dentistRegistrationFailed: false,
                dentistRegistrationSuccess: true,
                error: false
            };
        }
        case USER_ACTIONS.DENTIST_REGISTRATION_FAILED: {
            return {
                ...state,
                error: action.error,
                dentistRegistrationFailed: true,
                dentistRegistrationRequest: false,
                dentistRegistrationSuccess: false
            };
        }
        case USER_ACTIONS.DENTIST_REGISTRATION_REQUEST : {
            return {
                ...state,
                dentistRegistrationRequest: true,
                dentistRegistrationFailed: false,
                dentistRegistrationSuccess: false,
                error: false
            }
        }
        //update dentist
        case USER_ACTIONS.UPDATE_DENTIST: {
            return {
                ...state,
                updateDentistRequest: false,
                updateDentistFailed: false,
                updateDentistSuccess: true
            };
        }
        case USER_ACTIONS.UPDATE_DENTIST_FAILED: {
            return {...state, error: action.error, updateDentistFailed: true, updateDentistRequest: false};
        }
        case USER_ACTIONS.UPDATE_DENTIST_REQUEST : {
            return {...state, updateDentistRequest: true, updateDentistFailed: false}
        }

        //get dentist
        case USER_ACTIONS.GET_DENTIST: {
            return {
                ...state,
                getDentistRequest: false,
                getDentistRequestFailed: false,
                dentist: action.payload.data.data
            };
        }
        case USER_ACTIONS.GET_DENTIST_FAILED: {
            return {...state, error: action.error, getDentistRequestFailed: true, getDentistRequest: false};
        }
        case USER_ACTIONS.GET_DENTIST_REQUEST : {
            return {...state, getDentistRequest: true, getDentistRequestFailed: false}
        }

        // delete dentist from store
        case USER_ACTIONS.DELETE_DENTIST : {
            return {...state, dentist: null, dentist_id: null, dentistDeleted: true, dentistDeleteRequest: false}
        }
        case USER_ACTIONS.DELETE_DENTIST_REQUEST : {
            return {...state, dentistDeleteRequest: true}
        }
        // stripe charge
        case SUBSCRIPTION_ACTIONS.STRIPE_CHARGE: {
            let {data} = action.payload;
            return {
                ...state,
                package_status: data.package_status
            };
        }

        //get user
        case USER_ACTIONS.GET_USER: {
            let {data} = action.payload.data;
            let newState = {
                ...state,
                package_status: data.package_status,
                package_expire_at: data.package_expire_at
            };
            return newState;
        }
        case USER_ACTIONS.GET_USER_FAILED: {
            return {...state, error: action.error, getUserFailed: true, getUserRequest: false};
        }
        case USER_ACTIONS.GET_USER_REQUEST : {
            return {...state, getUserRequest: true, getUserFailed: false}
        }


        //get member
        case USER_ACTIONS.GET_MEMBER_REQUEST : {
            return {
                ...state,
                getMemberRequest: true,
                getMemberRequestFailed: false,
                getMemberSuccess: false,
                error: false,
                member: false
            }
        }
        case USER_ACTIONS.GET_MEMBER: {
            return {
                ...state,
                getMemberRequest: false,
                getMemberRequestFailed: false,
                getMemberSuccess: true,
                member: action.payload.data.data,
                error: false
            };
        }
        case USER_ACTIONS.GET_MEMBER_FAILED: {
            return {...state, error: action.error, getMemberFailed: true, getMemberRequest: false, member: false};
        }
        case USER_ACTIONS.GET_USER_REQUEST : {
            return {...state, getMemberRequest: true, getMemberFailed: false, error: false, member: false}
        }
        // report dentist
        case USER_ACTIONS.REPORT_DENTIST_REQUEST : {
            return {
                ...state,
                reportDentistRequest: true,
                reportDentistFailed: false,
                reportDentistSuccess: false
            }
        }
        case USER_ACTIONS.REPORT_DENTIST: {
            let report = action.payload.data.data
            let users = state.users
            for (let user of users) {
                if (user.id == report.dentist_id) {
                    user.details.is_reported = 1
                }
            }

            return {
                ...state,
                reportDentistRequest: false,
                reportDentistFailed: false,
                reportDentistSuccess: true,
                reportDentistSuccessMsg: action.payload.data.message,
                users: users
            };
        }
        case USER_ACTIONS.REPORT_DENTIST_FAILED: {
            return {
                ...state,
                error: action.error,
                reportDentistFailed: true,
                reportDentistRequest: false,
                reportDentistSuccess: false,
            };
        }
        default:
            return state;


    }

}

