import React, {Component} from 'react';
import DashboardHeader from "../components/DashboardHeader";
import Map from "../components/Map";
import Autocomplete from "react-google-autocomplete";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import ProfileDetail from "../components/ProfileDetail";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";
import connect from "react-redux/es/connect/connect";
import Image from "react-shimmer"
import {GOOGLE_API_KEY, PACKAGE_STATUSES} from "../constants";
import {NavLink} from "react-router-dom";
import {getUser} from "../actions/user";
import {ToastContainer} from "react-toastify";
import ChangePassword from "../components/ChangePassword";
import Sidebar from "../components/Sidebar";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.handleImage = this.handleImage.bind(this);
        this.handleAddress = this.handleChangeText.bind(this, "address");
        this.state = {
            first_name: props.user.details.first_name,
            last_name: props.user.details.last_name,
            image: props.user.details.image_url,
            city: props.user.details.city,
            address: props.user.details.address,
            state: props.user.details.state,
            country: props.user.details.country,
            zipcode: props.user.details.zipcode,
            file: null,
            url: null,
        }
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }


    handleImage(e) {
        this.setState({
            url: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        })
    }

    componentDidMount() {
        this.props.getUser(this.props.user.access.token);
    }

    render() {
        let user = this.props.user || null;
        if (user == null) {
            return <Loader/>;
        }

        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="dash-ban">
                    <div className="prof-det wd-95 pd-top-60">
                        <div className="container-fluid">
                            {/*<form>*/}
                            <div className="row">
                                <Sidebar/>

                                <div className="col-md-9">
                                    <div className="main-area add-mem">
                                        <div className="area-top">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <h3>Profile Details</h3>
                                                </div>
                                                <div className="col-md-4 offset-md-3 text-right">
                                                    <NavLink className="add-fam" to="/edit-profile"><i
                                                        className="fa fa-pencil-square-o"
                                                        aria-hidden="true"></i> Edit
                                                        Details</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="area-btm edit-prof">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>First Name</label>
                                                                <input type="text" name=""
                                                                       value={user.details.first_name} readOnly/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Last Name</label>
                                                                <input type="text" name=""
                                                                       value={user.details.last_name} readOnly/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Phone Number</label>
                                                                <input type="text" name="" value={user.details.phone}
                                                                       readOnly/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Email Address</label>
                                                                <input type="email" name=""
                                                                       value={user.email} readOnly/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Age</label>
                                                                <input type="number" name=""
                                                                       value={user.details.age} readOnly/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Gender</label>
                                                                <input readOnly type="text" name=""
                                                                       value={user.details.gender_text}/>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label>Other Medical Details</label>
                                                                <textarea value={user.details.other_details}
                                                                          readOnly></textarea>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*</form>*/}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users,
            request: state.general.profileRequest,
            success: state.general.profileSucces,
            failed: state.general.profileFailed,
            error: state.general.error,
            successMsg: 'Your Profile has been Updated',
            settings: state.settings ? state.settings.settings : state.general.settings,
        };
    }, {getUser}
)(Profile);