export const loadState = () => {
    try {
        const serialized = localStorage.getItem("state");
        if (serialized === null) {
            return undefined;
        }
        return JSON.parse(serialized);
    } catch (e) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serialized = JSON.stringify(getStateToSave(state));
        localStorage.setItem("state", serialized);
    } catch (e) {
        // LOG
    }
};

const getStateToSave = (state) => {
    // Return all the values we want to save at local storage;
    return {
        // Save the user details and access objects as well as email.
        user: {
            details: state.user.details,
            access: state.user.access,
            email: state.user.email,
            package_status: state.user.package_status,
            package_expire_at: state.user.package_expire_at,
            user_id: state.user.user_id,
            role: state.user.role,
            loggedIn: state.user.loggedIn,
            dentist_id: state.user.dentist_id || null
        },
        settings: {
            settings: state.general.settings
        },
        subscriptions: state.subscriptions.membership_info
    }
};
