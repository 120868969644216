import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Loader from "./Loader";
import {NavLink} from "react-router-dom";
import {addQuery} from "../actions/general";
import Alerts from "./Alerts";
import {DEFAULT_EMAIL, DEFAULT_FAX, DEFAULT_PHONE} from "../constants";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.handleChangeEmail = this.handleChangeText.bind(this, "email");
        this.state = {
            email: "",
            currentYear: new Date().getFullYear(),
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('email', this.state.email);
        this.props.addQuery(form_data);
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.querySuccess && nextProps.querySuccess) {
            this.setState({email: ""})
        }
    }

    render() {
        let settings = this.props.settings || null;
        let phone = settings ? "tel:" + settings.phone : "tel:" + DEFAULT_PHONE;
        let fax = settings ? "tel:" + settings.fax : "tel:" + DEFAULT_FAX;
        let email = settings ? "mailto:" + settings.email : "mailto:" + DEFAULT_EMAIL;
        return (
            <section>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="ft-col-1">
                                    <NavLink to="/">
                                        <img src={require("../../images/ft-logo.png")}/>
                                    </NavLink>
                                    <ul className="footer-ul">
                                        <li>
                                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                            <a href={email}>{settings ? settings.email : DEFAULT_EMAIL}</a>
                                        </li>
                                        <li>
                                            <i className="fa fa-fax" aria-hidden="true"></i>
                                            <a className="email-class" href={fax}>{settings ? settings.fax : DEFAULT_FAX}</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="ft-col-2">
                                    <h3>Important</h3>
                                    <ul>
                                        <li><NavLink to="/">Home</NavLink></li>
                                        <li><NavLink to="/membership">Sign Up Now</NavLink></li>
                                        <li><NavLink to="/professionals">Professionals</NavLink></li>
                                        <li><NavLink to="/faqs">FAQs</NavLink></li>
                                        <li><NavLink to="/verify-eligibility">Verify Eligibility</NavLink></li>
                                        <li><NavLink to="/fee-schedules">Fee Schedule</NavLink></li>
                                        <li><NavLink to="/example-of-savings">Example Of Savings</NavLink></li>
                                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="ft-col-2">
                                    <h3>Legal</h3>
                                    <ul>
                                        <li><NavLink to="/terms-and-conditions">Terms & Condition</NavLink></li>
                                        <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="ft-col-3">
                                    <h3>Stay connected for future updates</h3>
                                    <div className="newslt">
                                        {this.props.error &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.props.error.message
                                        }]}/>}
                                        {this.props.querySuccess &&
                                        <Alerts alerts={[{
                                            type: "success",
                                            message: "Subscribed Successfully"
                                        }]}/>}
                                        <form onSubmit={this.handleSubmit} style={{position: "relative"}}>
                                            <input type="email" name="email" placeholder="Enter Your Email"
                                                   value={this.state.email} onChange={this.handleChangeEmail}
                                                   required/>
                                            {this.props.queryRequest ?
                                                <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                                <input type="submit" name=""/>}
                                        </form>
                                    </div>
                                    <ul className="soc">
                                        <li><a
                                            href="https://www.facebook.com/pages/Mainline-Dental-Plan/193138224059330"
                                            target="_blank"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://plus.google.com/109791109016979256553" target="_blank"><i
                                            className="fa fa-google"></i></a></li>
                                        <li><a href="https://twitter.com/mainlinedental" target="_blank"><i
                                            className="fa fa-twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row brdr">
                            <div className="col-md-6">
                                <div className="copy">
                                    <p>&copy; Copyright {this.state.currentYear} Mainline Dental Plan, All Right Reserved</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="copy text-right">
                                    <p>Site maintained by Mainline Dental Plan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            queryRequest: state.general.queryRequest || false,
            queryFailed: state.general.queryRequest || false,
            querySuccess: state.general.querySuccess || false,
            error: state.general.error || false,
            settings: state.settings ? state.settings.settings : state.general.settings
        };
    },
    {addQuery}
)(Footer);
