import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {NavLink} from "react-router-dom";
import {
    AUTHORIZE_NET_ENV,
    AUTHORIZE_NET_LOGIN_ID,
    AUTHORIZE_NET_PUBLIC_KEY,
    GENDER_FEMALE,
    GENDER_MALE
} from "../constants";
import DatePicker from "react-datepicker";
import PaymentSection from "../components/PaymentSection";
import {FormContainer, FormComponent} from "react-authorize-net";
import {toast} from "react-toastify";
import {stripeCharge} from "../actions/subscription";
import Spinner from "react-spinners/FadeLoader";

class Payment extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            toast: false,
            status: "paid" | "unpaid" | "failure"
        }
        this.state = {
            first_name: this.props.user.details.first_name,
            last_name: this.props.user.details.last_name,
            work_phone: this.props.user.details.phone,
            home_phone: this.props.user.details.phone,
            email: this.props.user.email,
            dob: this.props.user.details.dob ? new Date(this.props.user.details.dob) : '',
            gender: this.props.user.details.gender,
            address: this.props.user.details.address,
            city: this.props.user.details.city,
            state: this.props.user.details.state,
            zipcode: this.props.user.details.zipcode,
            billing_same: true
        }
    }

    componentDidMount() {
        if (!this.props.subscription.hasOwnProperty('amount')) {
            this.props.history.push("/membership");
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.stripeChargeSuccess && nextProps.stripeChargeSuccess) {
            toast(nextProps.successMsg)
            this.setState({toast: true})
        } else if (!this.props.error && nextProps.error) {
            toast.error(nextProps.error.message)
            this.setState({toast: true})
        }
    }

    handleAge = date => {
        this.setState({
            dob: date
        });
    };

    handleChangeText(key, txt) {
        this.setState({[key]: txt});
    }

    onErrorHandler = (response) => {
        toast.error(response.messages.message.map(err => err.text)[0])
        this.setState({
            status: ["failure", response.messages.message.map(err => err.text)]
        });
    };

    onSuccessHandler = (response) => {
        let payload = {
            opaqueData: response.opaqueData,
            membership_info: this.props.subscription,
            payment_info: JSON.stringify(this.state)
        }
        let form = document.getElementById("payment-form");
        // Process API response on your backend...
        if (form.checkValidity()) {
            this.props.stripeCharge(this.props.user.access.token, payload)
            this.setState({toast: false})
        }
    };


    handleSubmit(e) {
        e.preventDefault();
        console.log('checking....')
    }

    render() {
        let user = this.props.user;
        const ExampleCustomInput = ({value, onClick}) => (
            <input type="text" className="def example-custom-input" onClick={onClick} value={value} readOnly/>
        );
        return (
            <div>
                <section className="inner-banner payment-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Payment</h1>
                                <ul className="bread">
                                    <li>
                                        <NavLink to="/">Home</NavLink>
                                    </li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/payment">Payment Form</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="sec-payment">
                    <div className="container">
                        <form id="payment-form" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Personal Information</h4>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>First Name*</label>
                                            <input className="def" type="text" value={this.state.first_name} name=""
                                                   placeholder="First Name" onChange={(e) => {
                                                this.handleChangeText('first_name', e.target.value)
                                            }} required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Last Name*</label>
                                            <input className="def" type="text" onChange={(e) => {
                                                this.handleChangeText('last_name', e.target.value)
                                            }} value={this.state.last_name} name=""
                                                   placeholder="Last Name" required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Middle Initial:</label>
                                            <input className="def" type="text" onChange={(e) => {
                                                this.handleChangeText('middle_initial', e.target.value)
                                            }} name=""/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Title:</label>
                                            <input className="def" type="text" name="" onChange={(e) => {
                                                this.handleChangeText('title', e.target.value)
                                            }} placeholder="Title"/>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Gender*</label>
                                            <select className="def" value={this.state.gender} onChange={(e) => {
                                                this.handleChangeText('gender', e.target.value)
                                            }} required>
                                                <option value={GENDER_MALE}>Male</option>
                                                <option value={GENDER_FEMALE}>Female</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Date of Birth: *</label>
                                            <DatePicker
                                                selected={new Date(this.state.dob)}
                                                onChange={this.handleAge}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={<ExampleCustomInput/>}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Home Phone: *</label>
                                            <input className="def" value={this.state.home_phone} type="number" name=""
                                                   placeholder="(xxx) xxx-xxxx" onChange={(e) => {
                                                this.handleChangeText('home_phone', e.target.value)
                                            }} required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Work Phone: *</label>
                                            <input className="def" value={this.state.work_phone} type="number" name=""
                                                   placeholder="(xxx) xxx-xxxx" onChange={(e) => {
                                                this.handleChangeText('work_phone', e.target.value)
                                            }} required/>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Email Address: *</label>
                                            <input className="def" value={this.state.email} type="email"
                                                   placeholder="your@emailaddress.com" required/>
                                        </div>
                                        <div className="col-md-12 mrgn-top">
                                            <h5>Your Address:</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Address: *</label>
                                            <input className="def" type="text" value={this.state.address} name=""
                                                   placeholder="Address" onChange={(e) => {
                                                this.handleChangeText('address', e.target.value)
                                            }} required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>City: *</label>
                                            <input className="def" value={this.state.city} type="text" name=""
                                                   placeholder="City" onChange={(e) => {
                                                this.handleChangeText('city', e.target.value)
                                            }} required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>State: *</label>
                                            <input className="def" type="text" value={this.state.state} name=""
                                                   placeholder="State" onChange={(e) => {
                                                this.handleChangeText('state', e.target.value)
                                            }} required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>ZIP Code: *</label>
                                            <input className="def" type="text" name="" value={this.state.zipcode}
                                                   placeholder="ZIP Code" onChange={(e) => {
                                                this.handleChangeText('zipcode', e.target.value)
                                            }} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <h5>Billing Information</h5>
                                        </div>
                                        <div className="col-md-7 text-right">
                                            <input type="checkbox" id="billing" name="billing" value=""
                                                   onClick={() => {
                                                       if (this.state.billing_same) {
                                                           this.setState({billing_same: false});
                                                       } else {
                                                           this.setState({billing_same: true});
                                                       }
                                                   }}/>
                                            <label htmlFor="billing" className="sm"> Same as Personal
                                                Information.</label>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.billing_same ? "row billing-area" : "row billing-area hide"}>
                                        <div className="col-md-6">
                                            <label>Address: *</label>
                                            {this.state.billing_same ?
                                                <input className="def" type="text" name="" placeholder="Address"
                                                       onChange={(e) => {
                                                           this.handleChangeText('billing_address', e.target.value)
                                                       }} required/> :
                                                <input className="def" type="text" name="" placeholder="Address"
                                                       onChange={(e) => {
                                                           this.handleChangeText('billing_address', e.target.value)
                                                       }}/>}
                                        </div>
                                        <div className="col-md-6">
                                            <label>City: *</label>
                                            {this.state.billing_same ?
                                                <input className="def" type="text" name="" onChange={(e) => {
                                                    this.handleChangeText('billing_city', e.target.value)
                                                }} placeholder="City" required/> :
                                                <input className="def" type="text" name="" onChange={(e) => {
                                                    this.handleChangeText('billing_city', e.target.value)
                                                }} placeholder="City"/>}

                                        </div>
                                        <div className="col-md-6">
                                            <label>State: *</label>
                                            {this.state.billing_same ?
                                                <input className="def" type="text" name="" onChange={(e) => {
                                                    this.handleChangeText('billing_state', e.target.value)
                                                }} placeholder="State" required/> :
                                                <input className="def" type="text" name="" onChange={(e) => {
                                                    this.handleChangeText('billing_state', e.target.value)
                                                }} placeholder="State"/>}
                                        </div>
                                        <div className="col-md-6">
                                            <label>ZIP Code: *</label>
                                            {this.state.billing_same ?
                                                <input className="def" type="text" name="" onChange={(e) => {
                                                    this.handleChangeText('billing_zipcode', e.target.value)
                                                }} placeholder="ZIP Code" required/> :
                                                <input className="def" type="text" name="" onChange={(e) => {
                                                    this.handleChangeText('billing_zipcode', e.target.value)
                                                }} placeholder="ZIP Code"/>}
                                        </div>
                                    </div>
                                    <div className="row payment mrgn-top">
                                        <div className="col-md-12">
                                            <h5>Payment Information</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <img className="pad-15" style={{width: "100%", height: "auto"}}
                                                 src={require("../../images/accepted-cards.png")}/>
                                        </div>
                                        <div className="col-md-6 text-right">

                                            <a href="https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/"
                                               onMouseOver="window.status='http://www.authorize.net/'; return true;"
                                               onMouseOut="window.status=''; return true;"
                                               onClick="window.open('https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;"
                                               rel="noopener noreferrer" target="_blank">
                                                <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
                                                     width="90"
                                                     height="72" border="0"
                                                     alt="Authorize.Net Merchant - Click to Verify"/>
                                            </a>
                                        </div>
                                        {this.props.stripeChargeRequest ?
                                            <div className="payment-form spinner-loader"><Spinner
                                                size={200}
                                                color={"#4A90E2"}
                                            /></div> :
                                            <FormContainer apiLoginId={AUTHORIZE_NET_LOGIN_ID}
                                                           environment={AUTHORIZE_NET_ENV}
                                                           clientKey={AUTHORIZE_NET_PUBLIC_KEY}
                                                           amount={this.props.subscription.amount}
                                                           component={FormComponent} onError={this.onErrorHandler}
                                                           onSuccess={this.onSuccessHandler}/>}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row">*/}
                            {/*<div className="col-md-12 text-right">*/}
                            {/*<input type="submit" name="" value="Complete Registration"/>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </section>

            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            subscription: state.subscriptions.membership_info || false,
            stripeChargeSuccess: state.subscriptions.stripeChargeSuccess || false,
            stripeChargeRequest: state.subscriptions.stripeChargeRequest || false,
            successMsg: state.subscriptions.successMsg,
            error: state.subscriptions.error || false
        };
    },
    {stripeCharge}
)(Payment);