import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class LoginSignupHeader extends Component {
    renderNavLink(url, text, txt) {
        return (<li><NavLink className={txt + (this.props.pathname == url ? " active" : "")} to={url}>{text}</NavLink>
        </li>);
    }

    render() {
        return (
            <header className="head-login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="logo">
                                <NavLink to="/"><img src={require("../../images/logo.png")}/></NavLink>
                            </div>
                        </div>
                        {/*<div className="col-md-3 offset-md-6">
                            <div className="top-btn">
                                <ul>
                                    {this.renderNavLink("/login", "Login", "login")}
                                    {this.renderNavLink("/register", "Sign Up", "signup")}
                                </ul>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </header>
        );
    }
}

export default LoginSignupHeader;