import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {getPage} from "../actions/general";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {Markup} from "interweave";
import Loader from "../components/Loader";

class About extends Component {

    componentWillMount() {
        let settings = this.props.settings || null;
        if (settings == null) {
            this.props.getPage();
        }
    }

    render() {
        let headerBanner = this.props.headerBanner || null;
        let page = this.props.page || null;

        return (
            <div>
                <ScrollToTopOnMount/>
                <div className="about-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text_style">
                                <h2 className="box-title after_bar">About US</h2>
                                {page
                                && <Markup content={page.translations[0].content}/>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            page: state.general.page,
            headerBanner: state.general.about_header

        };
    },
    {getPage}
)(About);