import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getSettings} from "../actions/general";
import HomeBanner from "../components/HomeBanner";
import Testimonials from "../components/Testimonials";
import HomeSignUpSection from "../components/HomeSignUpSection";
import HomeBlog from "../components/HomeBlog";
import FooterTop from "../components/FooterTop";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class App extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        /*this.props.getSettings();*/
    }

    render() {

        return (
            <section>
                <ScrollToTopOnMount/>
                <HomeBanner/>
                <HomeBlog/>
                <FooterTop/>
                <Testimonials/>

                <HomeSignUpSection/>
            </section>

        );
    }
}


export default connect(
    (state) => state,
    {getSettings}
)
(App);
