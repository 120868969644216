import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class TermsAndConditions extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="inner-banner inner-banner-faq-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Terms & Condition</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/terms-and-conditions">Terms & Condition</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="secure-desc terms-cond">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="desc">
                                    <p>The use of this site and purchasing a membership indicates your acceptance of
                                        these terms and conditions . The terms and conditions listed shall supersede any
                                        subsequent terms or conditions included with any purchase order, whether or not
                                        such conditions are signed by Mainline Dental Plan, Inc herein known as MDP. MDP
                                        reserves the right to amend this site and these terms and conditions at any
                                        time. In these terms and conditions, “you” and “your” will mean each person who
                                        has purchased a MDP membership. “We,” “our,” “ours,” and “us” represent Mainline
                                        Dental Plan, Inc. Please read the following information thoroughly.</p>
                                    <h3>Membership</h3>
                                    <p>Upon signup a member is immediately active in the plan. If the member needs to
                                        seek dental treatment before a card has been issued they can go to any
                                        participating provider and the provider can call MDP headquarters at
                                        877-880-7526 or go online to verify eligibility. A welcome packet will be mailed
                                        to the member via USPS with 48 hours of the time of cleared payment. Based on
                                        geographic location a member should expect their packet within 7 business days
                                        providing there are no postal irregularities such as weather.</p>
                                    <ul className="points">
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Membership is
                                            for one year and will be billed monthly or quarterly.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>If you choose
                                            NOT to renew or cancel your membership, billing will be stopped immediately.
                                        </p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member
                                            understands that these reduced fees are only valid if treatment is provided
                                            by a licensed participating MDP, Inc. dentist.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member
                                            understands that if annual payment is not current, treatment will not be
                                            provided at the MDP, Inc. fees until membership payment is made current. If
                                            MDP, Inc. membership is terminated, plan fees are no longer in effect and
                                            Member is responsible for entire dentist’s fee.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Member must
                                            contact MDP, Inc. to renew plan when membership expires.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>MDP, Inc.
                                            reserves the right to review fees and adjust accordingly in the first
                                            quarter of each calendar year. Member has the right to terminate membership
                                            at that time without any penalties.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Payment must
                                            be made to the MDP, Inc. participating Dentist when services are rendered
                                            unless other financial arrangements have been made in advance and member’s
                                            signature is on file agreeing to payment terms.</p></li>
                                        <li><p><i className="fa fa-check-square-o" aria-hidden="true"></i>Treatment must
                                            be started and completed while Member is enrolled in MDP, Inc.</p></li>
                                    </ul>
                                    <h3>Restrictions</h3>
                                    <p>MDP is a discount dental program. This is not an insurance company. MDP, Inc is
                                        constantly adding new providers to our list. Occasionally a provider will no
                                        longer wish to participate but may still be listed on the website or on other
                                        literature. Because of this all Members must verify services with each
                                        individual provider prior to your dental visit. Members must be enrolled in the
                                        Plan prior to visiting a participating provider to guarantee benefits.
                                        Participating providers are independent contractors and are solely responsible
                                        for all treatment provided. MDP makes no verbal or unspoken warranties regarding
                                        services received, and disclaims all liability for any and all services
                                        performed by participating providers and their associates. There are no
                                        out-of-network benefits. MDP DOES NOT PAY CLAIMS.</p>
                                    <h3>Refund and Cancellation Policy</h3>
                                    <h4>Annual Membership</h4>
                                    <p>All MDP membership fees are fully refundable within 48 business hours of your
                                        application receipt. Requests for cancellation must be by either e-mail or
                                        calling us at 201-357-7147, between the hours of 9 AM to 5 PM EST.
                                        If you find you need to cancel beyond the allowable 48 hour period, requests
                                        must be made in writing and is subject to a 25% cancellation processing fee and
                                        monthly prorate. Final determinations are at the discretion of the
                                        management.</p>
                                    <h4>Monthly Membership</h4>
                                    <p>If you wish for any reason to discontinue your monthly Plan, simply contact us at
                                    201-357-7147. Please note that monthly memberships are not eligible for a
                                        prorated refund of any portion of the current month’s paid membership fee.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default TermsAndConditions;