import React, {Component} from 'react';
import FeeScheduleTypes from "../components/FeeScheduleTypes";
import FeeDetails from "../components/FeeDetails";
import connect from "react-redux/es/connect/connect";
import {getServices} from "../actions/general";
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class FeeSchedules extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.getServices();
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="inner-banner inner-banner-fee-schedule-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Fee Schedule</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="fee-schedules">Fee Schedule</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="fees-sch-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="top-desc">
                                    <h2>General / Specialist Dentist Fees</h2>
                                </div>
                            </div>
                        </div>

                        {/*FEE SCHEDULE SECTION*/}
                        <div className="row cust">
                            {/*Left Accordian*/}
                            <FeeScheduleTypes/>
                            {/*Right side Details Section*/}
                            <FeeDetails/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {};
    },
    {getServices}
)(FeeSchedules);