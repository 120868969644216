import React, {Component} from 'react';

class BlogDetailItem extends Component {
    render() {
        return (
            <div className="col-md-8">
                <div className="lf">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>There are many variations of passages of Lorem Ipsum available.</h1>
                            <p>Learn more about what we have been up to. Discover recent events, activities,
                                and insights for technology and innovation.</p>
                            <ul>
                                <li><span>By Inhype</span></li>
                                <li><span><i className="fa fa-clock-o" aria-hidden="true"></i> Jan 11th, 2020</span>
                                </li>
                                <li><span>5mint Read</span></li>
                            </ul>
                            <img src={require("../../images/detailed.png")}/>
                            <div className="meta">
                                <ul>
                                    <li><span><i className="fa fa-comments-o"
                                                 aria-hidden="true"></i> 0</span>
                                    </li>
                                    <li><span><i className="fa fa-heart-o"
                                                 aria-hidden="true"></i> 2</span></li>
                                </ul>
                            </div>
                            <div className="more-desc">
                                <p>There are many variations of passages of Lorem Ipsum available, but
                                    the majority have suffered alteration in some form, by injected
                                    humour, or randomised words which don't look even slightly
                                    believable.</p>
                                <p>If you are going to use a passage of Lorem Ipsum, you need to be sure
                                    there isn't anything embarrassing hidden in the middle of text. All
                                    the Lorem Ipsum generators on the Internet tend to repeat predefined
                                    chunks as necessary, making this the first true generator on the
                                    Internet. It uses a dictionary of over 200 Latin words, combined
                                    with a handful of model sentence structures, to generate Lorem Ipsum
                                    which looks reasonable. The generated Lorem Ipsum is therefore
                                    always free from repetition, injected humour, or non-characteristic
                                    words etc.</p>
                            </div>
                            <div className="focused">
                                <h2>Future -focused</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but
                                    the majority have suffered alteration in some form, by injected
                                    humour, or randomised words which don't look even slightly
                                    believable.</p>
                                <p>If you are going to use a passage of Lorem Ipsum, you need to be sure
                                    there isn't anything embarrassing hidden in the middle of text. All
                                    the Lorem Ipsum generators on the Internet tend to repeat predefined
                                    chunks as necessary, making this the first true generator on the
                                    Internet. It uses a dictionary of over 200 Latin words, combined
                                    with a handful of model sentence structures, to generate Lorem Ipsum
                                    which looks reasonable. The generated Lorem Ipsum is therefore
                                    always free from repetition, injected humour, or non-characteristic
                                    words etc.</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src={require("../../images/ff1.png")}/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={require("../../images/ff2.png")}/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={require("../../images/ff3.png")}/>
                                    </div>
                                </div>
                                <p>There are many variations of passages of Lorem Ipsum available, but
                                    the majority have suffered alteration in some form, by injected
                                    humour, or randomised words which don't look even slightly
                                    believable.</p>
                                <p>If you are going to use a passage of Lorem Ipsum, you need to be sure
                                    there isn't anything embarrassing hidden in the middle of text. All
                                    the Lorem Ipsum generators on the Internet tend to repeat predefined
                                    chunks as necessary, making this the first true generator on the
                                    Internet. It uses a dictionary of over 200 Latin words, combined
                                    with a handful of model sentence structures, to generate Lorem Ipsum
                                    which looks reasonable. The generated Lorem Ipsum is therefore
                                    always free from repetition, injected humour, or non-characteristic
                                    words etc.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogDetailItem;