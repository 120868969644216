import React, {Component} from 'react';
import Loader from "./Loader";
import {getFaqsQuestions} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import FaqItems from "./FaqItems";

class FaqsQuestions extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getFaqsQuestions();
    }

    render() {
        let faqs = this.props.faqs || null;
        if (faqs == null) {
            return <Loader/>;
        }
        // console.log(faqs);
        let Faqs = faqs.map((faq, key) => {
            // console.log(faq);
            return (
                <FaqItems faq={faq} key={key} open={key} hash={this.props.hash}/>
            )
        });
        return (
            <section className="faq-1 accordian" id="accordionExample">
                <ScrollToTopOnMount/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Frequently Asked Questions</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="box">
                                <div className="row">
                                    <div className="accordion" id="accordionExample">
                                        {Faqs}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            faqs: state.general.faqs
        };
    },
    {getFaqsQuestions}
)(FaqsQuestions);