import React, {Component} from 'react';
import DentistSidebar2 from "./DentistSidebar2";
import {getEquipments} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import {updateDentist} from "../actions/user";
import Loader from "./Loader";
import {EXCELLENT, FAIR, GOOD, POOR} from "../constants";
import {toast} from 'react-toastify';

const selectedEquipments = [];

class DentistRegistrationTwo extends Component {
    constructor(props) {
        super(props)
        this.handleEquipmentAge = this.handleChangeText.bind(this, 'equipments_age');
        this.handleEquipmentComment = this.handleChangeText.bind(this, 'equipments_comments');
        this.state = {
            selectedEquipments: [],
            equipments_condition: EXCELLENT
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    componentDidMount() {
        this.props.getEquipments();
    }

    handleEquipmentCondition(condition) {
        this.setState({equipments_condition: condition})
    }

    async attachEquipment(equipment_id, e) {
        if (!selectedEquipments.includes(equipment_id)) {
            await selectedEquipments.push(equipment_id)
        } else {
            for (var i = selectedEquipments.length - 1; i--;) {
                if (selectedEquipments[i] === equipment_id) selectedEquipments.splice(i, 1);
            }
        }
        await this.setState({selectedEquipments: JSON.stringify(selectedEquipments)})
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.user.dentist_id) {
            toast.error('unable to proceed your request');
        } else {
            this.props.updateDentist(this.props.user.dentist_id, this.state, 3);
        }
    }

    render() {
        let equipments = this.props.equipments || null;
        if (equipments == null) {
            return <Loader/>;
        }
        let equipmentElement = equipments.map((equipment, key) => {
            return (
                <div key={equipment.id}
                     className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                    <input className="custom-control-input" onChange={this.attachEquipment.bind(this, equipment.id)}
                           type="checkbox" value=""
                           id={"checkbox" + equipment.id} value={equipment.id}/>
                    <label className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                           htmlFor={"checkbox" + equipment.id}>{equipment.name}</label>
                </div>
            );
        });
        return (
            <section className="sec-reg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm">Please circle the equipment that you currently
                                            have in your office:</label>
                                        {equipmentElement}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm mrgn-top">Stage age and condition of your
                                            equipment:</label>
                                    </div>
                                    <div className="col-md-12">
                                        <select className="def" onChange={this.handleEquipmentAge} required>
                                            <option>Age</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm mrgn-top">Condition:</label>
                                        <div className="custom-control custom-radio pmd-radio custom-control-inline">
                                            <input type="radio" id="customRadioInline1" name="customRadio"
                                                   className="custom-control-input"
                                                   onClick={this.handleEquipmentCondition.bind(this, EXCELLENT)}
                                                   checked={this.state.equipments_condition === EXCELLENT}/>
                                            <label className="custom-control-label pmd-radio-ripple-effect"
                                                   htmlFor="customRadioInline1">Excellent</label>
                                        </div>
                                        <div className="custom-control custom-radio pmd-radio custom-control-inline">
                                            <input type="radio" id="customRadioInline2" name="customRadio"
                                                   className="custom-control-input"
                                                   onClick={this.handleEquipmentCondition.bind(this, GOOD)}
                                                   checked={this.state.equipments_condition === GOOD}/>
                                            <label className="custom-control-label pmd-radio-ripple-effect"
                                                   htmlFor="customRadioInline2">Good</label>
                                        </div>
                                        <div className="custom-control custom-radio pmd-radio custom-control-inline">
                                            <input type="radio" id="customRadioInline3" name="customRadio"
                                                   className="custom-control-input"
                                                   onClick={this.handleEquipmentCondition.bind(this, FAIR)}
                                                   checked={this.state.equipments_condition === FAIR}/>
                                            <label className="custom-control-label pmd-radio-ripple-effect"
                                                   htmlFor="customRadioInline3">Fair</label>
                                        </div>
                                        <div className="custom-control custom-radio pmd-radio custom-control-inline">
                                            <input type="radio" id="customRadioInline4" name="customRadio"
                                                   className="custom-control-input"
                                                   onClick={this.handleEquipmentCondition.bind(this, POOR)}
                                                   checked={this.state.equipments_condition === POOR}/>
                                            <label className="custom-control-label pmd-radio-ripple-effect"
                                                   htmlFor="customRadioInline4">Poor</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mrgn-top">
                                        <textarea className="def" placeholder="Comments"
                                                  onChange={this.handleEquipmentComment}></textarea>
                                    </div>
                                    <div className="field">
                                        {this.props.request ?
                                            <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                            <input type="submit" name="login" value="Next" className="submit"/>
                                        }
                                    </div>

                                </div>

                            </form>
                        </div>
                        {/* <DentistSidebar2/> */}
                    </div>
                </div>
            </section>
        );
    }
}


export default connect(
    (state) => {
        return {
            user: state.user,
            request: state.user.updateDentistRequest,
            success: state.user.updateDentistSuccess,
            failed: state.user.updateDentistFailed,
            error: state.user.error,
            successMsg: "",
            equipments: state.general.equipments
        };
    },
    {getEquipments, updateDentist}
)(DentistRegistrationTwo);