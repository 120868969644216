import React, {Component} from 'react';
import Loader from "./Loader";
import {connect} from "react-redux";
import {logout} from "../actions/user";
import {NavLink} from "react-router-dom";

class DashboardHeader extends Component {
    constructor(props) {
        super(props)
        this.handleLogout = this.handleLogout.bind(this);
        // this.renderNavLink = this.renderNavLink.bind(this);

    }

    handleLogout(e) {
        e.preventDefault();
        this.props.logout();
    }

    renderLink(url, text) {
        return (<li className={this.props.pathname == url ? " active" : ""}>
            <NavLink to={url}>{text}</NavLink>
        </li>);
    }

    renderNavLink(url, text) {
        var element = document.getElementsByTagName("html")[0];
        element.classList.remove("open-menu");
        return (<li className={this.props.pathname == url ? " active" : ""}>
            <NavLink to={url}>{text}</NavLink>
        </li>);
    }

    render() {
        let user = this.props.user || null;
        if (user == null) {
            return <Loader/>;
        }
        return (
            <div>
                <a id="hamburg" href="#" onClick={(e) => {
                    e.preventDefault()
                    var element = document.getElementsByTagName("html")[0];
                    element.classList.toggle("open-menu");
                }}>
                    <div className="nav-cross">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <div id="menuoverlay"></div>
                <nav className="nav1">
                    <div className="container nopad">
                        <div className="row">
                            <div className="menu-main-menu-container">
                                <div className="logo">
                                    <a href="/"><img src={require("../../images/logo.png")}/></a>
                                </div>
                                <ul id="menu-main-menu" className="menu">
                                    {this.renderNavLink('/profile', 'Profile')}
                                    {this.renderNavLink('/add-family', 'Family Members')}
                                    {this.renderNavLink('/search-dentist', 'Search Dentists')}
                                    {this.renderNavLink('/subscription', 'Subscription')}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <header className="dashboard-header">
                    <div className="container-fluid wd-95">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="logo">
                                    <NavLink to="/"><img src={require("../../images/logo.png")}/></NavLink>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="dash-menu">
                                    <ul>
                                        {this.renderLink('/profile', 'Profile')}
                                        {this.renderLink('/add-family', 'Family Members')}
                                        {this.renderLink('/search-dentist', 'Search Dentists')}
                                        {this.renderLink('/subscription', 'Subscription')}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="head-info text-right">
                                    <ul>
                                        {/*<li className="notif">*/}
                                        {/*    <a href="#"><i className="fa fa-bell" aria-hidden="true"></i><span>2</span></a>*/}
                                        {/*</li>*/}
                                        <li className="prof-opt">
                                            <div className="image-box">
                                                <NavLink to="/profile">
                                                    <img src={user.details.image_url}
                                                         className="header-image"/>
                                                </NavLink>

                                            </div>

                                            <div className="dropdown dash-profile">
                                                <NavLink to="/profile"
                                                         className="dropbtn">{user.details.first_name} {user.details.last_name}</NavLink>
                                                <div className="dropdown-content">
                                                    <a href="#" onClick={this.handleLogout}>Logout</a>
                                                </div>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>


                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users
        };
    },
    {logout}
)(DashboardHeader);