import {requestSuccessAction, requestFailureAction, curl_get, curl_post} from "./http";
import {BASE_URL, ENDPOINTS, PAGES} from "../constants";
import {gotoHome, gotoGame, gotoNews, gotoProfile} from "./router";

// Action Types
const types = {

    CONTACT_US_REQUEST: "CONTACT_US_REQUEST",
    CONTACT_US: "CONTACT_US",
    CONTACT_US_FAILED: "CONTACT_US_FAILED",

    PROFILE_UPDATE_REQUEST: "PROFILE_UPDATE_REQUEST",
    PROFILE_DETAILS: "PROFILE_DETAILS",
    PROFILE_UPDATE_FAILED: "PROFILE_UPDATE_FAILED",

    ADD_QUERY_REQUEST: "ADD_QUERY_REQUEST",
    ADD_QUERY: "ADD_QUERY",
    ADD_QUERY_FAILED: "ADD_QUERY_FAILED",

    GET_SETTINGS: "GET_SETTINGS",
    GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
    GET_SETTINGS_FAILED: "GET_SETTINGS_FAILED",

    GET_PAGE: "GET_PAGE",
    GET_PAGE_REQUEST: "GET_PAGE_REQUEST",
    GET_PAGE_FAILED: "GET_PAGE_FAILED",

    RESET_REQUEST_RESPONSE: "RESET_REQUEST_RESPONSE",
    REQUEST_REVIEW_POSTING: "REQUEST_REVIEW_POSTING",

    RESET_DETAILS: "RESET_DETAILS",
    GAME_DETAILS: "GAME_DETAILS",
    NEWS_DETAILS: "NEWS_DETAILS",

    GET_BLOGS_REQUEST: "GET_BLOGS_REQUEST",
    GET_BLOGS: "GET_BLOGS",
    GET_BLOGS_FAILED: "GET_BLOGS_FAILED",

    GET_BLOG_DETAIL_REQUEST: "GET_BLOG_DETAIL_REQUEST",
    GET_BLOG_DETAIL: "GET_BLOG_DETAIL",
    GET_BLOG_DETAIL_FAILED: "GET_BLOG_DETAIL_FAILED",

    GET_TESTIMONIALS_REQUEST: "GET_TESTIMONIALS_REQUEST",
    GET_TESTIMONIALS: "GET_TESTIMONIALS",
    GET_TESTIMONIALS_FAILED: "GET_TESTIMONIALS_FAILED",

    GET_FAQS_REQUEST: "GET_FAQS_REQUEST",
    GET_FAQS: "GET_FAQS",
    GET_FAQS_FAILED: "GET_FAQS_FAILED",

    GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
    GET_SERVICES: "GET_SERVICES",
    GET_SERVICES_FAILED: "GET_SERVICES_FAILED",

    GET_EQUIPMENTS_REQUEST: "GET_EQUIPMENTS_REQUEST",
    GET_EQUIPMENTS: "GET_EQUIPMENTS",
    GET_EQUIPMENTS_FAILED: "GET_EQUIPMENTS_FAILED",

    GET_DEGREES_REQUEST: "GET_DEGREES_REQUEST",
    GET_DEGREES: "GET_DEGREES",
    GET_DEGREES_FAILED: "GET_DEGREES_FAILED",

    GET_MISCELLANEOUS_REQUEST: "GET_MISCELLANEOUS_REQUEST",
    GET_MISCELLANEOUS: "GET_MISCELLANEOUS",
    GET_MISCELLANEOUS_FAILED: "GET_MISCELLANEOUS_FAILED",


    GET_SAVINGS_REQUEST: "GET_SAVINGS_REQUEST",
    GET_SAVINGS: "GET_SAVINGS",
    GET_SAVINGS_FAILED: "GET_SAVINGS_FAILED",

    GET_DENTISTS_REQUEST: "GET_DENTISTS_REQUEST",
    GET_DENTISTS: "GET_DENTISTS",
    GET_DENTISTS_FAILED: "GET_DENTISTS_FAILED",

    GET_STATES_REQUEST: "GET_STATES_REQUEST",
    GET_STATES: "GET_STATES",
    GET_STATES_FAILED: "GET_STATES_FAILED",

    VERIFY_COUPON_REQUEST: "VERIFY_COUPON_REQUEST",
    VERIFY_COUPON: "VERIFY_COUPON",
    VERIFY_COUPON_FAILED: "VERIFY_COUPON_FAILED"

};
export default types;


export function getSettings() {
    return (dispatch) => {
        dispatch({type: types.GET_SETTINGS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_SETTINGS, {}, types.GET_SETTINGS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_SETTINGS))
        })
    }
}

// My Functions Starts here - Qasim
export function getBlogs() {
    return (dispatch) => {
        dispatch({type: types.GET_BLOGS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_BLOGS, {}, types.GET_BLOGS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_BLOGS))
        })
    }
}

export function getBlogDetails(id) {
    return (dispatch) => {
        dispatch({type: types.GET_BLOG_DETAIL_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_BLOG_DETAIL + '/' + id, {}, types.GET_BLOG_DETAIL_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_BLOG_DETAIL))
        })
    }
}

export function getFaqsQuestions() {
    return (dispatch) => {
        dispatch({type: types.GET_FAQS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_FAQS, {}, types.GET_FAQS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_FAQS))
        })
    }
}

export function getServices() {
    return (dispatch) => {
        dispatch({type: types.GET_SERVICES_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_SERVICES, {}, types.GET_SERVICES_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_SERVICES))
        })
    }
}

export function contact(payload) {
    return (dispatch) => {
        dispatch({type: types.CONTACT_US_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.ADD_QUERY, payload,
            types.CONTACT_US_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.CONTACT_US))
            });
    }
}

/*// UPDATE USER PROFILE
export function profileDetails(token, payload) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.PROFILE_UPDATE_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.PROFILE_DETAILS, payload,
            types.PROFILE_UPDATE_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.PROFILE_DETAILS))
            });
    }
}*/


// My function ends here
export function addQuery(formdata) {
    return (dispatch) => {
        dispatch({type: types.ADD_QUERY_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.ADD_QUERY, formdata,
            types.ADD_QUERY_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.ADD_QUERY))
            });
    }
}


export function getTestimonials() {
    return (dispatch) => {
        dispatch({type: types.GET_TESTIMONIALS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_TESTIMONIALS, {}, types.GET_TESTIMONIALS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_TESTIMONIALS))
        })
    }
}


export function getPage() {
    return (dispatch) => {
        dispatch({type: types.GET_PAGE_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_PAGE, {slug: PAGES.ABOUT}, types.GET_PAGE_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_PAGE))
        })
    }
}

export function resetRequest(request_type) {
    return (dispatch) => {
        dispatch({type: types.RESET_REQUEST_RESPONSE, request_type});
    }
}

export function resetDetails(details_type, prop) {
    return (dispatch) => {
        dispatch({type: types.RESET_DETAILS, details_type, prop});
    }
}

export function getEquipments() {
    return (dispatch) => {
        dispatch({type: types.GET_EQUIPMENTS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_EQUIPMENTS, {}, types.GET_EQUIPMENTS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_EQUIPMENTS))
        })
    }
}

export function getDegrees() {
    return (dispatch) => {
        dispatch({type: types.GET_DEGREES_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_DEGREES, {}, types.GET_DEGREES_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_DEGREES))
        })
    }
}

export function getMiscellaneousServices() {
    return (dispatch) => {
        dispatch({type: types.GET_MISCELLANEOUS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_MISCELLANEOUS, {}, types.GET_MISCELLANEOUS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_MISCELLANEOUS))
        })
    }
}

export function getSavings() {
    return (dispatch) => {
        dispatch({type: types.GET_SAVINGS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_SAVINGS, {}, types.GET_SAVINGS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_SAVINGS))
        })
    }
}

export function getDentists(payload) {
    return (dispatch) => {
        dispatch({type: types.GET_DENTISTS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_DENTISTS, payload, types.GET_DENTISTS_FAILED, (data) => {
            dispatch(requestSuccessAction(data, types.GET_DENTISTS))
        }, {})
    }
}

export function getStates() {
    return (dispatch) => {
        dispatch({type: types.GET_STATES_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_STATES, {}, types.GET_STATES_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_STATES))
        })
    }
}

export function verifyCoupon(payload) {
    return (dispatch) => {
        dispatch({type: types.VERIFY_COUPON_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.VERIFY_COUPON, payload, types.VERIFY_COUPON_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.VERIFY_COUPON))
        })
    }
}