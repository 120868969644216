import React, {Component} from 'react';

class DentistSidebar extends Component {
    render() {
        return (
            <div className="col-md-5">
                <div className="image">
                    <img src={require("../../images/reg-1.png")}/>
                </div>
            </div>
        );
    }
}

export default DentistSidebar;