import {combineReducers} from 'redux-loop';
import {connectRouter} from 'connected-react-router'

import user from "./user";
import general from "./general";
import subscriptions from "./subscriptions";

const reducer = (history) => combineReducers({
    router: connectRouter(history),
    // ...add Other Reducers Below
    user,
    general,
    subscriptions
});

export default reducer;