import {DATE_FORMAT_OPTIONS} from "./constants";
import starImg from "../images/star.png";
import React from "react";

export function getDateFromCreatedAt(date) {
    return (new Date(date)).toLocaleDateString('en-US', DATE_FORMAT_OPTIONS)
}

export function scrollToElement(ref) {
    if (ref != null) {
        window.scroll({left: 0, top: ref.offsetTop, behavior: "smooth"});
    }
}

export function checkString(data) {
    if (data && data != "undefined" && data != "null") {
        return data
    }
    return "";
}

// export function ratings(rating) {
//     if (rating == 1) {
//         return <div className="rating">
//             <img src={starImg}/>
//             <div>
//                 }
//                 }