import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';

import {spring, AnimatedSwitch} from 'react-router-transition';

import Layout from "./app/pages/Layout"
import Home from "./app/pages/Home"
import About from "./app/pages/About";
import Login from "./app/pages/Login";
import ForgotPassword from "./app/pages/ForgotPassword";
import VerifyResetCode from "./app/pages/VerifyResetCode";
import ResetPassword from "./app/pages/ResetPassword";
import Registration from "./app/pages/Registration";
import ContactUs from "./app/pages/ContactUs";
// My Custom imports - Pages
import MembershipInfo from "./app/pages/MembershipInfo";
import Professionals from "./app/pages/Professionals";
import FaQs from "./app/pages/FAQs";
import VerifyEligibility from "./app/pages/VerifyEligibility";
import FeeSchedules from "./app/pages/FeeSchedules";

import {history} from "./app/store";
import './css/custom.min.css';
import {getSettings} from "./app/actions/general";
import BlogDetail from "./app/pages/BlogDetail";
import Profile from "./app/pages/Profile";
import AddFamily from "./app/pages/AddFamily";
import SearchDentist from "./app/pages/SearchDentist";
import Auth from "./app/components/Auth";
import DentistRegistration from "./app/pages/DentistRegistration";
import DentistRegistrationCompleted from "./app/pages/DentistRegistrationCompleted";
import MembershipDetails from "./app/pages/MembershipDetails";
import StripePayment from "./app/components/StripePayment";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EditProfile from "./app/pages/EditProfile";
import Subscription from "./app/pages/Subscription";
import PrivacyPolicy from "./app/pages/PrivacyPolicy";
import ExampleOfSavings from "./app/pages/ExampleOfSavings";
import TermsAndConditions from "./app/pages/TermsAndConditions";
import Payment from "./app/pages/Payment";
import FindDentist from "./app/pages/FindDentist";
import PaymentSuccess from "./app/pages/PaymentSuccess";
import CookieConsent from 'react-cookie-consent';
import { initializeGTM } from '../src/utils/gtm'

let settings = null;
toast.configure({
    autoClose: 2000,
    draggable: false,
});

class App extends Component {

    constructor(props) {
        super(props);
        this.fade = this.fade.bind(this);
        this.fadeMapStyles = this.fadeMapStyles.bind(this);
    }
    componentWillMount() {
        settings = this.props.settings || null;
        if (settings == null) {
            this.props.getSettings();
        }
    }

    fadeTransitions = {
        atEnter: {
            opacity: 0,
        },
        atLeave: {
            opacity: this.fade(0)
        },
        atActive: {
            opacity: this.fade(1),
        },
    };

    renderWithLayout(component, extraAttr, ownProps) {
        return (
            <div>
                {React.createElement(component, (!!ownProps) ? {...ownProps, ...extraAttr} : extraAttr)}
                {/*<Footer/>*/}
            </div>
        )
    }

    fade(val) {
        return spring(val, {
            stiffness: 250,
            damping: 46,
            precision: 0.10
        });
    }

    fadeMapStyles(styles) {
        return {
            opacity: styles.opacity,
        }
    }

    handleAccept = () => {
        console.log('Cookies accepted!');
      };

    handleDecline = () => {
        console.log('Cookies declined!');
    };

    render() {
        return (
            <ConnectedRouter history={history}>
                <AnimatedSwitch className="animatedSwitch"
                                {...this.fadeTransitions}
                                mapStyles={this.fadeMapStyles}>
                    {/*All Routes that does not need to render layout.*/}
                    <Route exact
                           path={["/login", "/forgot-password", "/verify-reset-code", "/reset-password", "/register", "/profile", "/subscription", "/add-family", "/search-dentist", "/payment", "/edit-profile", "/payment-new"]}
                           render={(location) => {
                               return (<AnimatedSwitch
                                   className="animatedSwitch"
                                   {...this.fadeTransitions}
                                   runOnMount={location.pathname === '/'}
                                   mapStyles={this.fadeMapStyles}
                               >
                                   <Route exact path={"/login"} component={Login}/>
                                   <Route exact path={"/forgot-password"} component={ForgotPassword}/>
                                   <Route exact path={"/verify-reset-code"} component={VerifyResetCode}/>
                                   <Route exact path={"/reset-password"} component={ResetPassword}/>
                                   {/*<Route exact path={"/register"} component={Registration}/>*/}
                                   <Route exact path={"/register"} component={Login}/>

                                   {/*     Dashboard Routes      */}

                                   <Auth>
                                       <Route exact path="/profile" component={Profile}/>
                                       <Route exact path="/subscription" component={Subscription}/>
                                       <Route exact path="/edit-profile" component={EditProfile}/>
                                       <Route exact path={"/add-family"} component={AddFamily}/>
                                       <Route exact path={"/search-dentist"} component={SearchDentist}/>
                                       <Route exact path={"/payment"}
                                              component={Payment}/>
                                       {/*<Route exact path={"/payment-new"}*/}
                                       {/*component={Payment}/>*/}

                                   </Auth>
                               </AnimatedSwitch>)
                           }}/>

                    {/*All Routes that does need to render layout.*/}
                    <Route render={({location}) => (
                        <Layout>
                            <AnimatedSwitch
                                className="animatedSwitch"
                                {...this.fadeTransitions}
                                runOnMount={location.pathname === '/'}
                                mapStyles={this.fadeMapStyles}
                            >
                                <Route exact path="/" component={this.renderWithLayout.bind(this, Home)}/>
                                <Route exact path="/home" component={this.renderWithLayout.bind(this, Home)}/>
                                <Route exact path="/membership"
                                       component={this.renderWithLayout.bind(this, MembershipInfo)}/>
                                <Route exact path={["/membership/:id"]}
                                       component={this.renderWithLayout.bind(this, MembershipDetails)}/>

                                <Route exact path="/professionals"
                                       component={this.renderWithLayout.bind(this, Professionals)}/>
                                <Route exact path="/faqs"
                                       component={this.renderWithLayout.bind(this, FaQs)}/>
                                <Route exact path="/verify-eligibility"
                                       component={this.renderWithLayout.bind(this, VerifyEligibility)}/>
                                <Route exact path="/fee-schedules"
                                       component={this.renderWithLayout.bind(this, FeeSchedules)}/>
                                <Route exact path="/find-dentist"
                                       component={this.renderWithLayout.bind(this, FindDentist)}/>
                                <Route exact path="/privacy-policy"
                                       component={this.renderWithLayout.bind(this, PrivacyPolicy)}/>
                                <Route exact path="/terms-and-conditions"
                                       component={this.renderWithLayout.bind(this, TermsAndConditions)}/>
                                <Route exact path="/example-of-savings"
                                       component={this.renderWithLayout.bind(this, ExampleOfSavings)}/>
                                <Route exact path="/about" component={this.renderWithLayout.bind(this, About)}/>
                                <Route exact path="/contact"
                                       component={this.renderWithLayout.bind(this, ContactUs)}/>
                                <Route exact path={["/blog/:id"]}
                                       component={this.renderWithLayout.bind(this, BlogDetail)}/>

                                <Route exact path={["/dentist-registration/:id"]}
                                       component={this.renderWithLayout.bind(this, DentistRegistration)}/>
                                <Route exact path="/dentist-registered"
                                       component={this.renderWithLayout.bind(this, DentistRegistrationCompleted)}/>
                                <Route exact path="/payment-success"
                                       component={this.renderWithLayout.bind(this, PaymentSuccess)}/>
                                {/*<Route exact path={["/dentist-registration/1"]}*/}
                                {/*       component={this.renderWithLayout.bind(this, DentistRegistrationOne)}/>*/}
                                {/*<Route exact path={["/dentist-registration/2"]}*/}
                                {/*       component={this.renderWithLayout.bind(this, DentistRegistrationTwo)}/>*/}
                                {/*<Route exact path={["/dentist-registration/3"]}*/}
                                {/*       component={this.renderWithLayout.bind(this, DentistRegistrationThree)}/>*/}
                                {/*<Route exact path={["/dentist-registration/4"]}*/}
                                {/*       component={this.renderWithLayout.bind(this, DentistRegistrationFour)}/>*/}
                                {/*<Route exact path={["/dentist-registration/5"]}*/}
                                {/*       component={this.renderWithLayout.bind(this, DentistRegistrationFive)}/>*/}
                                {/*<Route exact path={"/card-element"}*/}
                                {/*component={this.renderWithLayout.bind(this, StripePayment)}/>*/}

                            </AnimatedSwitch>
                        </Layout>

                    )}/>


                </AnimatedSwitch>
                <div className="cookieContainer">
                    <CookieConsent
                        location="bottom"
                        buttonText="I understand"
                        cookieName="mainlinedentalCookie1"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        expires={150}
                        onAccept={this.handleAccept}
                        onDecline={this.handleDecline}
                        >
                            This website uses cookies to enhance the user experience.
                    </CookieConsent>
                </div>
                
            </ConnectedRouter>
        );
    }
}


export default connect(
    (state) => {
        return {
            settings: state.settings ? state.settings.settings : state.general.settings
        };
    },
    {getSettings}
)(App);
