import React, {Component} from 'react';
import DentistNavigation from "../components/DentistNavigation";
import DentistRegistrationOne from "../components/DentistRegistrationOne";
import DentistRegistrationTwo from "../components/DentistRegistrationTwo";
import DentistRegistrationThree from "../components/DentistRegistrationThree";
import DentistRegistrationFour from "../components/DentistRegistrationFour";
import DentistRegistrationFive from "../components/DentistRegistrationFive";
import DentistRegistrationBanner from "../components/DentistRegistrationBanner";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import connect from "react-redux/es/connect/connect";
import {withRouter} from 'react-router-dom';


class DentistRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        if (this.props.user.dentist_id === null && this.props.match.params.id != 1) {
            this.props.history.push("/dentist-registration/1");
        }
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <DentistRegistrationBanner/>
                {/*Scroll Navigation*/}
                <DentistNavigation step={this.props.match.params.id}/>
                <ScrollToTopOnMount/>
                {/*Navigation Components*/}
                {this.props.match.params.id == 1 ? <DentistRegistrationOne/> : null}

                {this.props.match.params.id == 2 ? <DentistRegistrationTwo/> : null}

                {this.props.match.params.id == 3 ? <DentistRegistrationThree/> : null}

                {this.props.match.params.id == 4 ? <DentistRegistrationFour/> : null}

                {this.props.match.params.id == 5 ? <DentistRegistrationFive/> : null}
            </div>

        );
    }
}


export default connect(
    (state) => {
        return {
            user: state.user,
        };
    },
    {}
)(DentistRegistration);