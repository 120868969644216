import React, {Component} from 'react';
import {
    GENDER_FEMALE,
    GENDER_MALE, GENDER_OTHER,
    RELATION_CHILD,
    RELATION_PARENT,
    RELATION_PARTNER,
    RELATION_SIBLING
} from "../constants";
import Modal from "react-bootstrap/Modal";

class AddSubMember extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let today_date = new Date()
        let month = today_date.getMonth() + 1
        month = month > 9 ? month : "0" + month
        let day = today_date.getDate()
        day = day > 9 ? day : "0" + day
        today_date = today_date.getFullYear() + "-" + month + "-" + day
        return (
            <div>
                <button className="btn btn-secondary" onClick={(e) => {
                    e.preventDefault()
                    this.props.handleOpenModal()
                }}>+ Add Sub Member
                </button>
                <Modal show={this.props.showModal} onHide={() => {
                    this.props.handleClose()
                }} dialogClassName="modal-150w"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered animation>
                    <form role="form" onSubmit={this.props.addMember}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Sub-Member</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInputName">Name: *</label>
                                <input type="text" maxLength="25" className="form-control"
                                       id="exampleFormControlInputName" name="name"
                                       placeholder="Enter Name" required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelectRelation">Select RelationShip: *</label>
                                <select className="form-control" name="relationship"
                                        id="exampleFormControlSelectRelation" required>
                                    <option value={RELATION_CHILD}>Child</option>
                                    <option value={RELATION_PARENT}>Parent</option>
                                    <option value={RELATION_PARTNER}>Partner</option>
                                    <option value={RELATION_SIBLING}>Sibling</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Description</label>
                                <textarea className="form-control" name="description" id="exampleFormControlTextarea1"
                                          rows="3"></textarea>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => {
                                this.props.handleClose()
                            }}>Close
                            </button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default AddSubMember;