import React, {Component} from 'react';
import Alerts from "./Alerts";
import {addQuery} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import {toast} from "react-toastify";

class MembershipInfoQuote extends Component {
    constructor(props) {
        super(props);
        this.handleChangeEmail = this.handleChangeText.bind(this, "email");
        this.state = {
            email: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('email', this.state.email);
        this.props.addQuery(form_data);
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.querySuccess && nextProps.querySuccess) {
            toast("Subscribed Successfully")
            this.setState({email: ""})
        }
    }

    render() {
        return (
            <>
                <section className="member-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Please Call Our Office For a Quote</h2>
                            </div>
                            <div className="col-md-6">
                                <div className="rt">

                                    <form onSubmit={this.handleSubmit}>
                                        <input type="email" name="" placeholder="Enter Email"
                                               onChange={this.handleChangeEmail} value={this.state.email} required/>
                                        <input type="submit" name="" placeholder="Subscribe"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            queryRequest: state.general.queryRequest || false,
            queryFailed: state.general.queryRequest || false,
            querySuccess: state.general.querySuccess || false,
            error: state.general.error || false,
            settings: state.settings ? state.settings.settings : state.general.settings
        };
    },
    {addQuery}
)(MembershipInfoQuote);
