
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class FooterTop extends Component {
    render() {
        return (
            <section className="foot-cta">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <h2>Are you an exceptional dentist?</h2>
                            <h5>Do patients love your practice? You are perfect for this platform.</h5>
                            <p>Serve more families by building & communicating trust online.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="rt">
                                <NavLink to="/dentist-registration/1" className="btn-wt">Get Registered</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FooterTop;

