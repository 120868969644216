import React, {Component} from 'react';
import {getDegrees, getEquipments} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import {updateDentist} from "../actions/user";
import {toast} from "react-toastify";
import Loader from "./Loader";

const selectedDegrees = [];

class DentistRegistrationThree extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            selectedDegrees: []
        }
    }

    componentDidMount() {
        this.props.getDegrees();
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.user.dentist_id) {
            toast.error('unable to proceed your request');
        } else {
            this.props.updateDentist(this.props.user.dentist_id, this.state, 4);
        }
    }

    handleCheckBoxClick(key, event) {
        this.setState({[key]: event ? 1 : 0});
    }

    handleChangeText(key, txt) {
        this.setState({[key]: txt});
    }

    async attachDegrees(degree_id, e) {
        if (!selectedDegrees.includes(degree_id)) {
            await selectedDegrees.push(degree_id)
        } else {
            for (var i = selectedDegrees.length - 1; i--;) {
                if (selectedDegrees[i] === degree_id) selectedDegrees.splice(i, 1);
            }
        }
        await this.setState({selectedDegrees: JSON.stringify(selectedDegrees)})
    }

    render() {
        let degrees = this.props.degrees || null;
        if (degrees == null) {
            return <Loader/>;
        }
        let degreeElements = degrees.map((degree, key) => {
            return (
                <div key={degree.id}
                     className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                    <input className="custom-control-input" type="checkbox" value=""
                           id={"checkbox1" + degree.id} onChange={this.attachDegrees.bind(this, degree.id)}/>
                    <label
                        className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                        htmlFor={"checkbox1" + degree.id}>{degree.name}</label>
                </div>
            );
        });
        return (
            <section className="sec-reg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm">Do you have the following? (for G.P.'s)</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="main">Periodontics</label>
                                    </div>
                                    <div className="col-md-9">
                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox1" onChange={(e) => {
                                                this.handleCheckBoxClick('full_case_periodontics', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox1">Full Case</label>
                                        </div>

                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox2" onChange={(e) => {
                                                this.handleCheckBoxClick('partial_case_periodontics', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox2">Partial Case</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="def" type="text" name="" placeholder="Comments"
                                               onChange={(e) => {
                                                   this.handleChangeText('periodontics_comments', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="main">Endodontics </label>
                                    </div>
                                    <div className="col-md-9">
                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox3" onChange={(e) => {
                                                this.handleCheckBoxClick('single_endodontics', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox3">Single</label>
                                        </div>

                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox4" onChange={(e) => {
                                                this.handleCheckBoxClick('double_endodontics', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox4">Double</label>
                                        </div>
                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox5" onChange={(e) => {
                                                this.handleCheckBoxClick('triple_endodontics', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox5">Triple</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="def" type="text" name="" placeholder="Comments"
                                               onChange={(e) => {
                                                   this.handleChangeText('endodontics_comments', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="main">Oral Surgery</label>
                                    </div>
                                    <div className="col-md-9">
                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox6" onChange={(e) => {
                                                this.handleCheckBoxClick('simple_oral_surgery', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox6">Simple Impactions</label>
                                        </div>

                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox7" onChange={(e) => {
                                                this.handleCheckBoxClick('complex_oral_surgery', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox7">Complex Impactions</label>
                                        </div>
                                        <div
                                            className="custom-control custom-checkbox custom-control-inline pmd-checkbox mrgn-btm">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                   id="checkbox8" onChange={(e) => {
                                                this.handleCheckBoxClick('apicoectomies_oral_surgery', e.target.checked)
                                            }}/>
                                            <label
                                                className="custom-control-label pmd-checkbox-ripple-effect check-clr"
                                                htmlFor="checkbox8">Apicoectomies</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="def" type="text" name="" placeholder="Comments"
                                               onChange={(e) => {
                                                   this.handleChangeText('oral_surgery_comments', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm mrgn-top">Please list specialists to whom you
                                            refer in the following areas:</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Periodontist</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder="Name" onChange={(e) => {
                                            this.handleChangeText('periodontist_name', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="number" name="" placeholder="Phone"
                                               onChange={(e) => {
                                                   this.handleChangeText('periodontist_phone', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Endodontist </label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder="Name" onChange={(e) => {
                                            this.handleChangeText('endodontist_name', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="number" name="" placeholder="Phone"
                                               onChange={(e) => {
                                                   this.handleChangeText('endodontist_phone', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Oral Surgeon</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder="Name" onChange={(e) => {
                                            this.handleChangeText('oral_surgeon_name', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="number" name="" placeholder="Phone"
                                               onChange={(e) => {
                                                   this.handleChangeText('oral_surgeon_phone', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Covering Dentist</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder="Name" onChange={(e) => {
                                            this.handleChangeText('covering_dentist_name', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="def" type="number" name="" placeholder="Phone"
                                               onChange={(e) => {
                                                   this.handleChangeText('covering_dentist_phone', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>(vacations and emergencies)</label>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="main mrgn-btm mrgn-top">Please circle any special degrees, or
                                            experience in the following areas:</label>
                                    </div>
                                    <div className="col-md-12">
                                        {degreeElements}
                                    </div>
                                    <div className="field">
                                        <input type="submit" name="login" value="Next" className="submit"/>
                                    </div>
                                </div>
                                {/*form row end -->*/}
                            </form>
                        </div>
                        <div className="col-md-5">
                            <div className="image">
                                <img src={require("../../images/reg-3.png")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            request: state.user.updateDentistRequest || false,
            success: state.user.updateDentistSuccess,
            failed: state.user.updateDentistFailed,
            error: state.user.error,
            successMsg: "",
            degrees: state.general.degrees
        };
    },
    {getDegrees, updateDentist}
)(DentistRegistrationThree);