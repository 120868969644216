import React, {Component} from 'react';
import DashboardHeader from "./DashboardHeader";
import MapPointers from "./MapPointers";
import {connect} from 'react-redux';

class NoDentist extends Component {
    render() {
        return (
            <div>
                <section className="search-1">
                    <div className="container-fluid nopad">
                        <div className="row">
                            <div className="col-md-5 nopad">
                                <div className="s-area">
                                    {/*<div className="field">*/}
                                    {/*<input type="search" name="" placeholder="Search Dentist"/>*/}
                                    {/*</div>*/}
                                    <div className="meta">
                                        <h4>no dentist found<span>{this.props.user.details.address}</span>
                                        </h4>
                                    </div>
                                    <div className="s-det">
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 nopad">
                                <div className="m-area">
                                    {/*<MapPointers users={[]} marker={false}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user
        };
    },
    {}
)(NoDentist);