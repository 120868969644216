import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {registration, socailLogin} from "../actions/user";
import Alerts from "../components/Alerts";
import SocialButton from '../components/SocialButton';
import {GoogleLogin} from 'react-google-login';
import LoginSignupHeader from "../components/loginSignupHeader";


class Registration extends Component {
    constructor(props) {
        super(props);
        this.handlefName = this.handleChangeText.bind(this, "fname");
        this.handlelName = this.handleChangeText.bind(this, "lname");
        this.handleEmail = this.handleChangeText.bind(this, "email");
        this.handlePassword = this.handleChangeText.bind(this, "password");
        this.handleRegistration = this.handleRegistration.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.handleSocialLogin = this.handleSocialLogin.bind(this);
        this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
        this.state = {
            fname: "",
            lname: "",
            email: "",
            password: ""
        }
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    responseGoogle = (response) => {
        let data = {
            "platform": 'google',
            "client_id": response.profileObj.googleId,
            "token": response.tokenObj.access_token,
            "username": response.profileObj.name,
            "email": response.profileObj.email,
            "image": response.profileObj.imageUrl,
            "device_type": "web",
            // "expires_at": user.token.expiresAt
        }
        this.props.socailLogin(data);

    }
    handleSocialLogin = (user) => {
        let data = {
            "platform": user.provider,
            "client_id": user.profile.id,
            "token": user.token.accessToken,
            "username": user.profile.name,
            "email": user.profile.email,
            // "image": user.profile.profilePicURL,
            "image": "https://graph.facebook.com/" + user.profile.id + "/picture?type=large&width=150&height=150",
            "device_type": "web",
            // "expires_at": user.token.expiresAt
        }
        this.props.socailLogin(data);
    }

    handleSocialLoginFailure = (err) => {
        console.error(err)
    }

    handleRegistration(e) {
        e.preventDefault();
        let fname = this.state.fname;
        let lname = this.state.lname;
        let email = this.state.email;
        let password = this.state.password;
        if (fname.length > 0 && lname.length > 0 && email.length > 0 && password.length > 0) {
            this.setState({alert: false, message: ''})
            this.props.registration(this.state.fname, this.state.lname, this.state.email, this.state.password, this.state.password);
        } else {
            this.setState({alert: true, message: 'Please fill required fields'})
        }

        return false;
    }

    render() {
        return (
            <div>
                <LoginSignupHeader/>
                <section className="sec-login signup">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 padright">
                                <div className="lf">
                                    <img src={require("../../images/acount-banner.png")}/>

                                </div>
                            </div>
                            <div className="col-md-7 padleft">
                                <div className="rt">
                                    <div className="inner">
                                        <NavLink to="/"><img className="logo"
                                                             src={require("../../images/logo.png")}/></NavLink>
                                        <h2>Welcome to SignUp</h2>

                                        <form onSubmit={this.handleRegistration}>
                                            {this.state.alert &&
                                            <Alerts alerts={[{
                                                type: "danger",
                                                message: this.state.message
                                            }]}/>}
                                            {this.props.error &&
                                            <Alerts alerts={[{
                                                type: "danger",
                                                message: this.props.error.message
                                            }]}/>}
                                            {this.props.success &&
                                            <Alerts alerts={[{type: "success", message: this.props.successMsg}]}/>}
                                            <div className="field user">
                                                <input type="text" name="fname" placeholder="First Name"
                                                       onChange={this.handlefName} required/>
                                            </div>
                                            <div className="field user">
                                                <input type="text" name="lname" placeholder="Last Name"
                                                       onChange={this.handlelName} required/>
                                            </div>
                                            <div className="field email">
                                                <input type="email" name="email" placeholder="email"
                                                       onChange={this.handleEmail} required/>
                                            </div>
                                            <div className="field pass">
                                                <input type="password" name="password" placeholder="Password"
                                                       onChange={this.handlePassword} required/>
                                            </div>
                                            <div className="field">
                                                <a className="rem" href="#"><img
                                                    src={require("../../images/remember.png")}/>Remember
                                                    Me</a>
                                            </div>
                                            <div className="field">
                                                {this.props.request
                                                    ?
                                                    <input type="submit" name="Submit" value="Submitting"
                                                           className="btn_style" disabled/>
                                                    : <input type="submit" name="Submit" value="Register"/>
                                                }
                                            </div>
                                            <div className="field">
                                                <p>Already Member?<NavLink to="/login">Login</NavLink></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

Registration.propTypes = {};

export default connect(
    (state) => {
        return {
            request: state.user.userRegistrationRequest || state.user.socailLoginRequest,
            success: state.user.userRegistrationSuccess || state.user.socailLoginSuccess,
            failed: state.user.userRegistrationFailed || state.user.socailLoginFailed,
            failedSocial: state.user.socailLoginFailed,
            error: state.user.error,
            successMsg: state.user.userRegistrationMsg || state.user.socailLoginMsg

        };
    },
    {registration, socailLogin}
)(Registration);
