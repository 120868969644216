import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {changePassword} from "../actions/user";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.handleCurrentPassword = this.handleChangeText.bind(this, "current_password");
        this.handlelNewPassword = this.handleChangeText.bind(this, "password");
        this.handleConformPassword = this.handleChangeText.bind(this, "password_confirmation");
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            current_password: "",
            password: "",
            password_confirmation: ""
        }
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    async handleSubmit(e) {
        e.preventDefault();
        await this.props.changePassword(this.state.current_password, this.state.password, this.state.password_confirmation, this.props.user.access.token);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.success && nextProps.success) {
            toast(nextProps.successMsg);
        } else if (!this.props.error && nextProps.error) {
            toast.error(nextProps.error.message)
        }
    }

    render() {

        return (
            <div id="change-pass" className="tab-pane ">
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Current Password</label>
                            <input type="password" name="current_password" placeholder="Current Password"
                                   onChange={this.handleCurrentPassword} autocomplete="off" required/>
                        </div>
                        <div className="col-md-6">
                            <label>New Password</label>
                            <input type="password" name="new_password" placeholder="New Password"
                                   onChange={this.handlelNewPassword} autocomplete="off" required/>
                        </div>
                        <div className="col-md-6">
                            <label>Confirm Password Password</label>
                            <input type="password" name="password_confirmation" placeholder="Confirm Password"
                                   onChange={this.handleConformPassword} autocomplete="off" required/>
                        </div>

                        <div className="col-md-12">
                            {this.props.request ?
                                <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                <input type="submit" name="" value="Update"/>}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            request: state.user.passwordRequest,
            success: state.user.passwordSuccess,
            failed: state.user.passwordRequestFailed,
            error: state.user.error,
            successMsg: state.user.passwordSuccessMsg,
        };
    }, {changePassword}
)(ChangePassword);