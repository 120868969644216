import React, {Component} from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import ProfileDetail from "../components/ProfileDetail";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";
import connect from "react-redux/es/connect/connect";
import Image from "react-shimmer"
import {GOOGLE_API_KEY, PACKAGE_STATUSES} from "../constants";
import {NavLink} from "react-router-dom";
import {getUser, logout} from "../actions/user";
import ChangePassword from "../components/ChangePassword";

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.handleImage = this.handleImage.bind(this);
        this.handleAddress = this.handleChangeText.bind(this, "address");
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            first_name: props.user.details.first_name,
            last_name: props.user.details.last_name,
            image: props.user.details.image_url,
            city: props.user.details.city,
            address: props.user.details.address,
            state: props.user.details.state,
            country: props.user.details.country,
            zipcode: props.user.details.zipcode,
            file: null,
            url: null,
        }
    }

    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }


    handleImage(e) {
        this.setState({
            url: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        })
    }

    componentDidMount() {
        this.props.getUser(this.props.user.access.token);
    }

    handleLogout(e) {
        e.preventDefault();
        this.props.logout();
    }

    render() {
        let user = this.props.user || null;
        if (user == null) {
            return <Loader/>;
        }
        let lat = user.details.latitude != null && user.details.latitude != "" ? user.details.latitude : 18.5204;
        let lng = user.details.longitude != null && user.details.longitude != "" ? user.details.longitude : 73.8567;
        let url = "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_API_KEY + "&libraries=places";
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap style={{
                        position: 'relative',
                    }} google={this.props.google}
                               defaultZoom={15}
                               defaultCenter={{
                                   lat: Number(lat),
                                   lng: Number(lng)
                               }}>

                        <Marker google={this.props.google}
                                name={'Dolores park'}
                                draggable={false}
                                position={{
                                    lat: Number(lat),
                                    lng: Number(lng)
                                }}
                        />
                        <Marker/>

                    </GoogleMap>

                )
            )
        );
        let map = <div>
            <AsyncMap
                googleMapURL={url}
                loadingElement={
                    <div style={{height: `100%`}}/>
                }
                containerElement={
                    <div style={{height: 300}}/>
                }
                mapElement={
                    <div style={{height: `100%`}}/>
                }
            />
        </div>
        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="dash-ban">
                    <div className="prof-det wd-95 pd-top-60">
                        <div className="container-fluid">
                            {/*<form>*/}
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="side">
                                        <div className="pic">
                                            <Image
                                                src={this.state.url != null ? this.state.url : user.details.image_url + '?w=120&h=120'}
                                                width={120} height={120}/>
                                            <div className="edit">
                                                <i className="fa fa-camera" aria-hidden="true"></i>
                                                <input type="file" name="img" onChange={this.handleImage}/>
                                            </div>
                                        </div>
                                        <div className="det text-center">
                                            <h2>{this.state.first_name} {this.state.last_name}</h2>
                                            {/*<h5>{user.role}</h5>*/}
                                            <p><i className="fa fa-map-marker"
                                                  aria-hidden="true"></i>{this.state.address}
                                                {this.state.city}
                                                {/*{this.state.zipcode}*/}
                                                {/*{this.state.country}*/}
                                            </p>
                                        </div>
                                        <div className="map-box">
                                            {map}
                                        </div>
                                        <div className="add-btn">
                                            <a href="#" onClick={this.handleLogout}>Logout</a>
                                        </div>
                                    </div>
                                    <div className="side-btm">
                                        <ul>
                                            <li><a href="#">Settings & Privacy</a></li>
                                            <li><a href="#">Help</a></li>
                                            <li><a href="#">Language</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-9">
                                    <div className="main-area">
                                        <div className="area-top">
                                            {/*Nav tabs*/}
                                            <ul className="nav">
                                                <li className="">
                                                    <a className="active" data-toggle="tab" href="#prof-det">Profile
                                                        Details</a>
                                                </li>
                                                <li className="">
                                                    <a className="" data-toggle="tab" href="#change-pass">Change
                                                        Password</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="area-btm">
                                            {/*Tab panes*/}
                                            <div className="tab-content">
                                                <ProfileDetail image={this.state.file}/>
                                                <ChangePassword/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*</form>*/}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users,
            request: state.general.profileRequest,
            success: state.general.profileSucces,
            failed: state.general.profileFailed,
            error: state.general.error,
            successMsg: 'Your Profile has been Updated',
            settings: state.settings ? state.settings.settings : state.general.settings,
        };
    }, {getUser, logout}
)(EditProfile);