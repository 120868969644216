import React, {Component} from 'react';
import ProfessionalForms from "../components/ProfessionalForms";
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class Professionals extends Component {
    render() {
        return (
            <>
                <ScrollToTopOnMount/>
                {/*Banner Section*/}
                <section className="inner-banner inner-banner-professional-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Professionals</h1>
                                <ul className="bread">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><span><i className="fa fa-angle-right"></i></span></li>
                                    <li><NavLink to="/professionals">Professionals</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Professionals Forms Section*/}
                <ProfessionalForms/>
            </>
        );
    }
}

export default Professionals;