import React, {Component} from 'react';
import DashboardHeader from "../components/DashboardHeader";
import MapPointers from "../components/MapPointers";
import {getUsers} from "../actions/user";
import {connect} from 'react-redux';
import Loader from "../components/Loader";
import NoDentist from "../components/NoDentist";
import {NavLink} from "react-router-dom";
import DentistDetailModal from "../components/DentistDetailModal";
import ReportModal from "../components/ReportModal";
import {toast} from "react-toastify";

class SearchDentist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedMarker: false,
            query: "",
            dentist_details: null,
            showModal: false,
            reportModal: false,
            report_dentist_id: null
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleQuery = this.handleQuery.bind(this);
        this.viewDetails = this.viewDetails.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleReportModal = this.handleReportModal.bind(this);
        this.openReportModal = this.openReportModal.bind(this);
    }

    componentDidMount() {
        let payload = {
            role: 4,
            latitude: this.props.user.details.latitude,
            longitude: this.props.user.details.longitude
        }
        this.props.getUsers(this.props.user.access.token, payload);
    }

    async handleQuery(e) {
        await this.setState({query: e.target.value});
        let user = this.props.user;
        let query = this.state.query;
        let getUsers = this.props.getUsers;
        let token = this.props.user.access.token;
        if (query.length >= 3 && !this.props.request) {
            await setTimeout(async function () {
                let payload = {
                    role: 4,
                    latitude: user.details.latitude,
                    longitude: user.details.longitude,
                    keyword: query
                }
                await getUsers(token, payload);
            }, 2000, {user, query, getUsers, token})

        } else if (query.length == 0) {
            await setTimeout(async function () {
                let payload = {
                    role: 4,
                    latitude: user.details.latitude,
                    longitude: user.details.longitude
                }
                await getUsers(token, payload);
            }, 2000, {user, query, getUsers, token})
        }

    }

    handleClick(user) {
        this.setState({selectedMarker: user})
    }

    viewDetails(user) {
        this.setState({dentist_details: user});
        this.setState({showModal: true});
    }

    handleModal(status) {
        if (!status) {
            this.setState({showModal: status, dentist_details: null});
        }
    }

    handleReportModal(status) {
        if (!status) {
            this.setState({reportModal: status, dentist_id: null});
        }
    }

    openReportModal(dentist_id) {
        this.setState({reportModal: true, dentist_id: dentist_id})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.reportDentistSuccess && nextProps.reportDentistSuccess) {
            toast(nextProps.reportDentistSuccessMsg);
            this.setState({reportModal: false, dentist_id: null});
        }
        if (!this.props.reportDentistFailed && nextProps.reportDentistFailed) {
            toast.error(nextProps.error.message)
        }
    }

    render() {
        let users = this.props.users || [];
        // if (users.length == 0) {
        //     return <NoDentist/>;
        // }
        let dentistElement = users.map((user, key) => {
            return (
                <div className="login-user set" onClick={() => this.handleClick(user)} key={user.id}>
                    <div className="icon">
                        <img src={user.details.image_url + "?w=70&h=70"}/>
                    </div>
                    <div className="det">
                        {/*<p>CIGNAPlus Savings</p>*/}
                        <h2 className="firm">{user.dentist.practice_name}
                            <span className="name">{user.details.full_name}</span>
                            <span className="address">{user.details.address}</span>
                        </h2>

                    </div>
                    <div className="call">
                        <a href={"tel:" + (user.details.phone)}><i className="fa fa-phone" aria-hidden="true"></i>Make
                            a call</a>
                    </div>
                    <div className="call">
                        <div>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.viewDetails(user)
                            }}>View Details</a>
                        </div>

                    </div>
                    {user.details.is_reported ? null :
                        <div className="new-danger" onClick={() => {
                            this.openReportModal(user.id)
                        }}>Report
                        </div>}
                </div>
            );
        });
        return (
            <div>
                {this.state.dentist_details != null ?
                    <DentistDetailModal dentist={this.state.dentist_details} showModal={this.state.showModal}
                                        handleModal={this.handleModal}/> : null}
                <ReportModal handleReportModal={this.handleReportModal} dentist_id={this.state.dentist_id}
                             reportModal={this.state.reportModal}/>
                <section className="search-1">
                    <div className="container-fluid nopad">
                        <div className="row">
                            <div className="col-md-5 nopad">
                                <div className="s-area">
                                    <div className="field">
                                        <input id="search" type="search" name="" placeholder="Search Dentist"
                                               onChange={this.handleQuery}/>
                                        {this.props.request ?
                                            <i className="fas fa-spinner fa-spin custom-spinner"></i> : null}

                                    </div>
                                    <div className="meta">
                                        <h4>{users.length} Result{users.length > 1 ? 's' : null}
                                            {/*<span>{this.props.user.details.address}</span>*/}
                                        </h4>
                                    </div>
                                    <div className="s-det">
                                        {dentistElement}
                                    </div>
                                </div>
                            </div>
                            {users.length > 0 ? <div className="col-md-7 nopad">
                                <div className="m-area">
                                    <MapPointers users={this.props.users} marker={this.state.selectedMarker}
                                                 currentUser={this.props.user}/>
                                </div>
                            </div> : null}

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            users: state.user.users,
            request: state.user.usersRequest,
            reportDentistRequest: state.user.reportDentistRequest,
            reportDentistFailed: state.user.reportDentistFailed,
            reportDentistSuccess: state.user.reportDentistSuccess,
            reportDentistSuccessMsg: state.user.reportDentistSuccessMsg,
            error: state.user.error,
        };
    },
    {getUsers}
)(SearchDentist);