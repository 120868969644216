import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Loader from "./Loader";
import Markup from "interweave/lib/Markup";

class FeeDetails extends Component {
    render() {
        let services = this.props.services || null;
        if (services == null) {
            return <Loader/>;
        }
        let serviceDetailElement = services.map((service, key) => {
            let className = key == 0 ? "tab-pane active show" : "tab-pane fade"
            return (
                <div id={"top-" + service.id} className={className} key={key}>
                    <Markup content={service.description} key={key}/>
                </div>
            );
        });
        return (
            <div className="col-md-7">
                <div className="det-area">
                    <div className="tab-content">
                        {serviceDetailElement}
                    </div>
                    <div className="outter">
                        <p>Non-listed procedures performed by General Dentists are provided to Members
                            at a Courtesy Fee
                            of 25% off the General Dentist’s usual and customary fees. Fees effective
                            January 1, 2024.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            services: state.general.services
        };
    },
    {}
)(FeeDetails);