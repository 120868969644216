import React, {Component} from 'react';
import NewPrintableForm from '../../files/appform.pdf';
import OldForm from '../../files/oldform.pdf';
import {NavLink} from "react-router-dom";

class ProfessionalForms extends Component {
    render() {
        return (
            <>
                <section className="prof-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box">
                                    <h2>Professionals</h2>
                                    <p>Print out our application form and fill it out, or fill out our NEW* form online
                                        for added
                                        convenience.</p>
                                    <a href="/dentist-registration/1">
                                        <div className="inner">

                                            <div className="icon">
                                                <img src={require("../../images/prof-icon-1.png")}/>
                                            </div>
                                            <h4>New Online Form</h4>
                                        </div>
                                    </a>
                                    <a href={OldForm} >
                                        <div className="inner">

                                            <div className="icon">
                                                <img src={require("../../images/prof-icon-2.png")}/>
                                            </div>
                                            <h4>old Printable Form</h4>

                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ProfessionalForms;