import React, {Component} from 'react';
import {contact} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import Alerts from "../components/Alerts";
import GoogleMapReact from 'google-map-react';
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import ContactForm from "../components/ContactForm";
import Professionals from "./Professionals";


const AnyReactComponent = ({text}) => <div>{text}</div>;
const Marker = props => {
    return <div className="SuperAwesomePin"></div>
}

class ContactUs extends Component {
    render() {

        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="inner-banner .inner-banner-contact-us-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Contact us</h1>
                                <p>Get in touch, we’re user-friendly!</p>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactForm/>
            </div>
        );
    }
}

export default ContactUs;
