import {GENERAL_ACTIONS} from "../actions";

export default function general(state = {}, action) {
    switch (action.type) {

        //contact us
        case GENERAL_ACTIONS.CONTACT_US_REQUEST: {
            return {...state, contactRequest: true, contactFailed: false, contactSucces: false};
        }
        case GENERAL_ACTIONS.CONTACT_US: {
            return {
                ...state,
                contactRequest: false,
                contactFailed: false,
                contactSucces: true,
                contactSuccessMsg: action.payload.message
            };

        }
        case GENERAL_ACTIONS.CONTACT_US_FAILED: {
            return {...state, contactRequest: false, error: action.error, contactFailed: true, contactSucces: false};
        }


        //profile details
        case GENERAL_ACTIONS.PROFILE_UPDATE_REQUEST: {
            return {...state, profileRequest: true, profileFailed: false};
        }
        case GENERAL_ACTIONS.PROFILE_DETAILS: {
            return {
                ...state,
                profileRequest: false,
                profileFailed: false,
                profileSucces: true,
                profileSuccessMsg: action.payload.message
            };

        }
        case GENERAL_ACTIONS.PROFILE_UPDATE_FAILED: {
            return {...state, profileRequest: false, error: action.error, profileFailed: true};
        }

        //get settings
        case GENERAL_ACTIONS.GET_SETTINGS_REQUEST: {
            return {...state, request: true, failed: false};
        }
        case GENERAL_ACTIONS.GET_SETTINGS: {
            return {
                ...state,
                request: false,
                failed: false,
                settings: action.payload.data.data[0]
            };

        }
        case GENERAL_ACTIONS.GET_SETTINGS_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }

        //get page
        case GENERAL_ACTIONS.GET_PAGE_REQUEST: {
            return {...state, request: true, failed: false};
        }
        case GENERAL_ACTIONS.GET_PAGE: {
            return {
                ...state,
                request: false,
                failed: false,
                page: action.payload.data.data[0]
            };
        }
        case GENERAL_ACTIONS.GET_PAGE_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }

        //get blogs
        case GENERAL_ACTIONS.GET_BLOGS_REQUEST: {
            return {...state, blogRequest: true, blogFailed: false};
        }
        case GENERAL_ACTIONS.GET_BLOGS: {
            return {
                ...state,
                blogRequest: false,
                blogFailed: false,
                blogs: action.payload.data.data
            };
        }

        case GENERAL_ACTIONS.GET_BLOGS_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }

        //get blog details
        case GENERAL_ACTIONS.GET_BLOG_DETAIL_REQUEST: {
            return {...state, blogDetailRequest: true, blogDetailFailed: false};
        }
        case GENERAL_ACTIONS.GET_BLOG_DETAIL: {
            return {
                ...state,
                blogDetailRequest: false,
                blogDetailFailed: false,
                blogDetails: action.payload.data.data
            };
        }

        case GENERAL_ACTIONS.GET_BLOG_DETAIL_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }

        //get FAQs
        case GENERAL_ACTIONS.GET_FAQS_REQUEST: {
            return {...state, faqRequest: true, faqFailed: false};
        }
        case GENERAL_ACTIONS.GET_FAQS: {
            return {
                ...state,
                faqRequest: false,
                faqFailed: false,
                faqs: action.payload.data.data
            };
        }

        case GENERAL_ACTIONS.GET_FAQS_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }
        //Add Query
        case GENERAL_ACTIONS.ADD_QUERY_REQUEST: {
            return {...state, queryRequest: true, queryFailed: false, querySuccess: false};
        }
        case GENERAL_ACTIONS.ADD_QUERY: {
            return {
                ...state,
                queryRequest: false,
                queryFailed: false,
                querySuccess: true
            };

        }
        case GENERAL_ACTIONS.ADD_QUERY_FAILED: {
            return {...state, queryRequest: false, error: action.error, queryFailed: true, querySuccess: true};
        }


        //get Testimonials
        case GENERAL_ACTIONS.GET_TESTIMONIALS_REQUEST: {
            return {...state, testimonialRequest: true, testimonialFailed: false};
        }
        case GENERAL_ACTIONS.GET_TESTIMONIALS: {
            return {
                ...state,
                testimonialRequest: false,
                testimonialFailed: false,
                testimonials: action.payload.data.data
            };

        }
        case GENERAL_ACTIONS.GET_TESTIMONIALS_FAILED: {
            return {...state, testimonialRequest: false, error: action.error, testimonialFailed: true};
        }

        //get Services
        case GENERAL_ACTIONS.GET_SERVICES_REQUEST: {
            return {...state, servicesRequest: true, servicesFailed: false};
        }
        case GENERAL_ACTIONS.GET_SERVICES: {
            return {
                ...state,
                servicesRequest: false,
                servicesFailed: false,
                services: action.payload.data.data
            };

        }
        case GENERAL_ACTIONS.GET_SERVICES_FAILED: {
            return {...state, equipmentRequest: false, error: action.error, equipmentFailed: true};
        }
        //get equipments
        case GENERAL_ACTIONS.GET_EQUIPMENTS_REQUEST: {
            return {...state, equipmentRequest: true, equipmentFailed: false};
        }
        case GENERAL_ACTIONS.GET_EQUIPMENTS: {
            return {
                ...state,
                equipmentRequest: false,
                equipmentFailed: false,
                equipments: action.payload.data.data
            };

        }
        case GENERAL_ACTIONS.GET_EQUIPMENTS_FAILED: {
            return {...state, equipmentRequest: false, error: action.error, equipmentFailed: true};
        }

        //get degrees
        case GENERAL_ACTIONS.GET_DEGREES_REQUEST: {
            return {...state, degreesRequest: true, degreesFailed: false};
        }
        case GENERAL_ACTIONS.GET_DEGREES: {
            return {
                ...state,
                degreesRequest: false,
                degreesFailed: false,
                degrees: action.payload.data.data
            };

        }
        case GENERAL_ACTIONS.GET_DEGREES_FAILED: {
            return {...state, degreesRequest: false, error: action.error, degreesFailed: true};
        }

        //getMiscellaneousServices
        case GENERAL_ACTIONS.GET_MISCELLANEOUS_REQUEST: {
            return {...state, miscellaneousRequest: true, miscellaneousFailed: false};
        }
        case GENERAL_ACTIONS.GET_MISCELLANEOUS: {
            return {
                ...state,
                miscellaneousRequest: false,
                miscellaneousFailed: false,
                miscellaneous_services: action.payload.data.data
            };

        }
        case GENERAL_ACTIONS.GET_MISCELLANEOUS_FAILED: {
            return {...state, miscellaneousRequest: false, error: action.error, miscellaneousFailed: true};
        }
        // get savings
        case GENERAL_ACTIONS.GET_SAVINGS_REQUEST: {
            return {...state, savingsRequest: true, savingsFailed: false};
        }
        case GENERAL_ACTIONS.GET_SAVINGS: {
            return {
                ...state,
                savingsRequest: false,
                savingsFailed: false,
                savings: action.payload.data.data
            };

        }
        case GENERAL_ACTIONS.GET_SAVINGS_FAILED: {
            return {...state, savingsRequest: false, error: action.error, savingsFailed: true};
        }
        // get dentists
        case GENERAL_ACTIONS.GET_DENTISTS_REQUEST: {
            return {...state, dentistsRequest: true, dentistsFailed: false, dentistSuccess: false};
        }
        case GENERAL_ACTIONS.GET_DENTISTS: {
            return {
                ...state,
                dentistsRequest: false,
                dentistsFailed: false,
                dentists: action.payload.data,
                dentistSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_DENTISTS_FAILED: {
            return {...state, dentistsRequest: false, error: action.error, dentistsFailed: true, dentistSuccess: false};
        }
        // get states
        case GENERAL_ACTIONS.GET_STATES_REQUEST: {
            return {...state, statesRequest: true, statesFailed: false, statesSuccess: false};
        }
        case GENERAL_ACTIONS.GET_STATES: {
            return {
                ...state,
                statesRequest: false,
                statesFailed: false,
                states: action.payload.data.data,
                statesSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_STATES_FAILED: {
            return {...state, statesRequest: false, error: action.error, statesFailed: true, statesSuccess: false};
        }
        // verify coupon
        case GENERAL_ACTIONS.VERIFY_COUPON_REQUEST: {
            return {
                ...state,
                verifyCouponRequest: true,
                verifyCouponFailed: false,
                verifyCouponSuccess: false
            };
        }
        case GENERAL_ACTIONS.VERIFY_COUPON: {
            return {
                ...state,
                verifyCouponRequest: false,
                verifyCouponFailed: false,
                verifyCoupon: action.payload.data.data,
                verifyCouponSuccess: true
            };

        }
        case GENERAL_ACTIONS.VERIFY_COUPON_FAILED: {
            return {
                ...state,
                verifyCouponRequest: false,
                error: action.error,
                verifyCouponFailed: true,
                verifyCouponSuccess: false
            };
        }

        default:
            return state;
    }
}