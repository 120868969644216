import React, {Component} from 'react';
import {toast} from "react-toastify";
import {getDegrees, getMiscellaneousServices} from "../actions/general";
import connect from "react-redux/es/connect/connect";
import {updateDentist, getDentist} from "../actions/user";
import Loader from "./Loader";
import {DAYS, MONTHS} from "../constants";

const selectedMiscellaneous = {};

class DentistRegistrationFour extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            selectedMiscellaneous: [],
        }
    }

    componentDidMount() {
        this.props.getMiscellaneousServices();
        this.props.getDentist(this.props.user.dentist_id);

    }

    async handleSubmit(e) {
        e.preventDefault();
        await this.setState({agreement_date: new Date()});
        if (!this.props.user.dentist_id) {
            await toast.error('unable to proceed your request');
        } else {
            await this.props.updateDentist(this.props.user.dentist_id, this.state, 5);
        }
    }

    handleChangeText(key, txt) {
        this.setState({[key]: txt});
    }

    async attachMiscellaneous(data, e) {
        if (!(data[1] in selectedMiscellaneous) && data[0] == "yes") {
            selectedMiscellaneous[data[1]] = {explanation: null};
        } else if (data[1] in selectedMiscellaneous && data[0] == "no") {
            delete selectedMiscellaneous[data[1]];
        } else if (data[1] in selectedMiscellaneous && data[0] == "text") {
            selectedMiscellaneous[data[1]] = {explanation: e.target.value};
        }
        await this.setState({selectedMiscellaneous: selectedMiscellaneous})
    }

    render() {
        let date = new Date();
        let miscellaneous_services = this.props.miscellaneous_services || null;
        if (miscellaneous_services == null) {
            return <Loader/>;
        }
        let dentist = this.props.dentist || null;
        if (dentist == null) {
            return <Loader/>;
        }
        let miscellaneousElements = miscellaneous_services.map((service, key) => {
            return (
                <div className="col-md-12">
                    <div className="row" key={service.id}>
                        <div className="col-md-8">
                            <label className="mrgn-top">{service.name} </label>
                        </div>
                        <div className="col-md-4 mrgn-btm text-right">
                            <div className="rad-box">
                                <div
                                    className="custom-control custom-radio pmd-radio custom-control-inline">
                                    <input type="radio" id={"customRadioInlineyes" + service.id}
                                           name={"customRadio" + service.id}
                                           className="custom-control-input"
                                           onChange={this.attachMiscellaneous.bind(this, ['yes', service.id])}/>
                                    <label className="custom-control-label pmd-radio-ripple-effect"
                                           htmlFor={"customRadioInlineyes" + service.id}>Yes</label>
                                </div>
                                <div
                                    className="custom-control custom-radio pmd-radio custom-control-inline">
                                    <input type="radio" id={"customRadioInlineno" + service.id}
                                           name={"customRadio" + service.id}
                                           className="custom-control-input"
                                           onChange={this.attachMiscellaneous.bind(this, ['no', service.id])}/>
                                    <label className="custom-control-label pmd-radio-ripple-effect"
                                           htmlFor={"customRadioInlineno" + service.id}>No</label>
                                </div>
                            </div>
                        </div>
                        {service.need_explanation == 1 && service.id in selectedMiscellaneous ?
                            <div className="col-md-12">
                                <input className="def" type="text" name="" placeholder="(If yes, explain)"
                                       onChange={this.attachMiscellaneous.bind(this, ['text', service.id])}/>
                            </div> : null}

                    </div>
                </div>

            );
        });

        return (
            <section className="sec-reg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    {miscellaneousElements}
                                    <div className="col-md-7">
                                        <input className="def" type="text" name="" placeholder="Name"
                                               value={dentist.name}/>
                                    </div>
                                    <div className="col-md-5">
                                        <input className="def" type="text" name="" placeholder="Signature"
                                               onChange={(e) => {
                                                   this.handleChangeText('signature', e.target.value)
                                               }}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="mrgn-btm mrgn-top">THIS AGREEMENT is made and entered into
                                            this</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={DAYS[date.getDay()]}
                                               disabled/>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mrgn-top-10 text-center">day of</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={MONTHS[date.getMonth()]} disabled/>
                                    </div>
                                    {/* <div className="col-md-1">
                                        <label className="mrgn-top-10 text-center">20</label>
                                    </div>*/}
                                    <div className="col-md-4">
                                        <input className="def" type="text" name="" placeholder=""
                                               value={date.getFullYear()} disabled/>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="mrgn-top-10 text-center">By and between </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="def" type="text" name="" placeholder="" value={dentist.name}
                                               disabled/>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="mrgn-btm">(hereinafter referred to as the “DENTIST”), who is
                                            duly qualified and Licensed to
                                            practice Dentistry is this state with professional offices</label>
                                    </div>
                                    <div className="col-md-1">
                                        <label className="mrgn-top-10 text-center">At</label>
                                    </div>
                                    <div className="col-md-11">
                                        <input className="def" type="text" name="" placeholder="" onChange={(e) => {
                                            this.handleChangeText('professional_offices', e.target.value)
                                        }}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>And MAINLINE DENTAL PLAN, INC. A New Jersey Corporation incorporated
                                            under the
                                            laws of the State of New Jersey and duly authorized to do business in the
                                            state
                                            wherein dentist maintains his/her office(s) (hereinafter to as the
                                            “PLAN”)</label>
                                    </div>

                                    <div className="field">
                                        <input type="submit" name="login" value="Next" className="submit"/>
                                    </div>
                                </div>
                                {/*form row end -->*/}
                            </form>
                        </div>
                        <div className="col-md-5">
                            <div className="image">
                                <img src={require("../../images/reg-4.png")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            dentist: state.user.dentist,
            request: state.user.updateDentistRequest || false,
            success: state.user.updateDentistSuccess,
            failed: state.user.updateDentistFailed,
            error: state.user.error,
            successMsg: "",
            miscellaneous_services: state.general.miscellaneous_services
        };
    },
    {getMiscellaneousServices, updateDentist, getDentist}
)(DentistRegistrationFour);