import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import connect from "react-redux/es/connect/connect";
import {reportDentist} from "../actions/user";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class ReportModal extends Component {
    constructor() {
        super()
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        let token = this.props.user.access.token
        let payload = {
            dentist_id: this.props.dentist_id,
            description: this.state.description
        }
        this.props.reportDentist(payload, token)
    }

    render() {
        return (
            <Modal show={this.props.reportModal} onHide={() => {
                this.props.handleReportModal(false);
            }} dialogClassName="modal-150w"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered animation>
                <form role="form" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Report Dentist</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Description</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" name="description"
                                      rows="3" onChange={(e) => {
                                this.setState({description: e.target.value})
                            }}></textarea>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        {this.props.reportDentistRequest ?
                            <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                            <button type="submit" className="btn btn-primary">Submit
                            </button>}
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            this.props.handleReportModal(false);
                        }}>Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default connect(
    (state) => {
        return {
            reportDentistRequest: state.user.reportDentistRequest,
            reportDentistFailed: state.user.reportDentistFailed,
            reportDentistSuccess: state.user.reportDentistSuccess,
            reportDentistSuccessMsg: state.user.reportDentistSuccessMsg,
            error: state.user.error,
            user: state.user,
        };
    },
    {reportDentist}
)(ReportModal);