import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import "./Stripe/styles.css";
import {stripeCharge} from "../actions/subscription";
import {FormContainer, FormComponent} from "react-authorize-net";
import {toast} from "react-toastify";
import {AUTHORIZE_NET_LOGIN_ID, AUTHORIZE_NET_PUBLIC_KEY, AUTHORIZE_NET_ENV} from "../constants";
import PaymentForm from "./PaymentForm";
import Spinner from "react-spinners/FadeLoader";

class StripePayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toast: false,
            status: "paid" | "unpaid" | "failure"
        }
        this.handleCharge = this.handleCharge.bind(this)
    }

    onErrorHandler = (response) => {
        toast.error(response.messages.message.map(err => err.text)[0])
        this.setState({
            status: ["failure", response.messages.message.map(err => err.text)]
        });
    };

    onSuccessHandler = (response) => {
        let payload = {
            opaqueData: response.opaqueData,
            membership_info: this.props.subscription
        }
        // Process API response on your backend...
        this.props.stripeCharge(this.props.user.access.token, payload)
        this.setState({toast: false})
    };

    handleCharge(data) {
        this.props.stripeCharge(this.props.user.access.token, data)
        this.setState({toast: false})
    }

    componentDidMount() {
        // const script = document.createElement("script");
        //
        // script.src = "https://js.authorize.net/v3/AcceptUI.js";
        // // script.async = true;
        // document.body.appendChild(script);

        if (!this.props.subscription.hasOwnProperty('amount')) {
            this.props.history.push("/membership");
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.stripeChargeSuccess && nextProps.stripeChargeSuccess) {
            toast(nextProps.successMsg)
            this.setState({toast: true})
        } else if (!this.props.error && nextProps.error) {
            toast.error(nextProps.error.message)
            this.setState({toast: true})
        }
    }

    render() {
        let amount = this.props.subscription && this.props.subscription.hasOwnProperty('amount') ? this.props.subscription.amount : 0;
        return (
            <section className="payment-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img className="paymet-img" src={require("../../images/payment-page.jpg")} alt=""/>
                        </div>
                        <div className="col-12 col-md-6 payment-details">

                            <div className="AuthorizeNetSeal" align="left" width="175px">
                                <script type="text/javascript"
                                        language="javascript">ANS_customer_id = "453f5e47-b89b-4ddf-805d-feddc228757b";</script>
                                <script type="text/javascript" language="javascript"
                                        src="//verify.authorize.net/anetseal/seal.js"></script>

                                <a href="https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/"
                                   onMouseOver="window.status='http://www.authorize.net/'; return true;"
                                   onMouseOut="window.status=''; return true;"
                                   onClick="window.open('https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;"
                                   rel="noopener noreferrer" target="_blank">
                                    <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" width="90"
                                         height="72" border="0" alt="Authorize.Net Merchant - Click to Verify"/>
                                </a>

                            </div>
                            <div className="payment-image">
                                <h3>Payment Details</h3>
                                <img style={{width: "100%", height: "auto"}}
                                     src={require("../../images/accepted-cards.png")}/>
                            </div>
                            {this.props.stripeChargeRequest ? <div className="spinner-loader"><Spinner
                                    size={200}
                                    color={"#4A90E2"}
                                /></div> :
                                <FormContainer apiLoginId={AUTHORIZE_NET_LOGIN_ID} environment={AUTHORIZE_NET_ENV}
                                               clientKey={AUTHORIZE_NET_PUBLIC_KEY}
                                               component={FormComponent} onError={this.onErrorHandler}
                                               onSuccess={this.onSuccessHandler} amount={amount}/>}

                        </div>
                    </div>
                </div>


                {/*<BrowserRouter>
                    <section className="payment-section">
                        <Elements stripe={stripePromise}>
                            <ElementDemos demos={demos} membership_info={this.props.subscription}
                                          handleCharge={this.handleCharge}
                                          chargeRequest={this.props.stripeChargeRequest}
                                          success={this.state.toast} successMsg={this.props.successMsg}/>
                        </Elements>
                    </section>
                </BrowserRouter>*/}
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            subscription: state.subscriptions.membership_info || false,
            stripeChargeSuccess: state.subscriptions.stripeChargeSuccess || false,
            stripeChargeRequest: state.subscriptions.stripeChargeRequest || false,
            successMsg: state.subscriptions.successMsg,
            error: state.subscriptions.error || false
        };
    },
    {stripeCharge}
)(StripePayment);