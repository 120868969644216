import React, {Component} from 'react';
import Spinner from "react-spinners/FadeLoader";

class Loader extends Component {
    render() {

        return (
            <div className="sweet-loading">
                <Spinner
                    size={200}
                    color={"#4A90E2"}
                />
            </div>);
    }
}

Loader.propTypes = {};

export default Loader;
