import React, {Component} from 'react';
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";
import connect from "react-redux/es/connect/connect";
import Sidebar from "../components/Sidebar";
import {getUserSubscription} from "../actions/subscription";
import {NavLink} from "react-router-dom";
import {getDateFromCreatedAt} from "../helpers";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.getUserSubscription(this.props.user.access.token);
    }

    render() {
        let user = this.props.user || null;
        let user_subscription = this.props.user_subscription || null;
        if (user == null) {
            return <Loader/>;
        }

        return (
            <div>
                <ScrollToTopOnMount/>
                <section className="dash-ban">
                    <div className="prof-det wd-95 pd-top-60">
                        <div className="container-fluid">
                            {/*<form>*/}
                            <div className="row">
                                <Sidebar/>

                                <div className="col-md-9">
                                    <div className="main-area add-mem">
                                        <div className="area-top">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <h3>Subscription Details</h3>
                                                </div>
                                                <div className="col-md-4 offset-md-3 text-right">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="area-btm edit-prof">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {user_subscription == null ?
                                                        <div>Not subscribed to any subscription <NavLink
                                                            to="/membership">(Subscribe Now)</NavLink></div> : <form>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label>Subscription Name</label>
                                                                    <input type="text" name=""
                                                                           value={user_subscription.subscription.name}/>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Subscription Type</label>
                                                                    <input type="text" name=""
                                                                           value={user_subscription.membership_type_text}/>
                                                                </div>
                                                                <div className="col-md-12 subs-exp">
                                                                    <label>Subscription Expiry</label>
                                                                    <input type="text" name=""
                                                                           value={getDateFromCreatedAt(user_subscription.expired_at)}/>
                                                                    <span className="expiry">(<NavLink to="/membership">Change Subscription?</NavLink>)</span>
                                                                </div>

                                                            </div>
                                                        </form>}

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*</form>*/}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            user_subscription: state.subscriptions.user_subscription,
            settings: state.settings ? state.settings.settings : state.general.settings,
        };
    }, {getUserSubscription}
)(Subscription);