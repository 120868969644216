import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Loader from "./Loader";

class FeeScheduleTypes extends Component {
    constructor(props) {
        super(props)
        this.checkTypeGeneral = this.checkTypeGeneral.bind(this);
        this.checkTypeSpecialist = this.checkTypeSpecialist.bind(this);
    }

    checkTypeGeneral(type) {
        return type.type == 10;
    }

    checkTypeSpecialist(type) {
        return type.type == 20;
    }

    render() {
        let services = this.props.services || null;
        if (services == null) {
            return <Loader/>;
        }
        let general_services = services.filter(this.checkTypeGeneral);
        let specialist_services = services.filter(this.checkTypeSpecialist);

        let generalServicesElement = null;
        if (general_services != null) {
            generalServicesElement = general_services.map((service, key) => {
                return (
                    <li className="" key={service.id + 1}>
                        <a className={key == 0 ? "active" : null} data-toggle="tab"
                           href={"#top-" + service.id}>{service.title}
                            <span>{service.sub_title}</span>
                        </a>

                    </li>
                );
            });
        }
        let specialistServicesElement = null;
        if (specialist_services != null) {
            specialistServicesElement = specialist_services.map((service, key) => {
                return (
                    <li className="" key={service.id + 1}>
                        <a className="" data-toggle="tab"
                           href={"#top-" + service.id}>{service.title}
                            <span>{service.sub_title}</span>
                        </a>
                    </li>
                );
            });
        }

        return (

            <div className="col-md-5">
                <div className="info-area accordion" id="accordionExample">
                    <div className="upper-box">
                        <div className="box-inner" id="headingOne">

                            <a className="btn-link" href="#" data-toggle="collapse"
                               data-target="#collapseOne"
                               aria-expanded="true" aria-controls="collapseOne">
                                <i className="fa fa-caret-right" aria-hidden="true"></i>General Dentist
                                Fees</a>

                        </div>

                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                             data-parent="#accordionExample">
                            <div className="box-body">

                                <ul className="nav">
                                    {generalServicesElement}
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="upper-box">
                        <div className="box-inner" id="headingTwo">

                            <a className="btn-link collapsed" href="#" data-toggle="collapse"
                               data-target="#collapseTwo"
                               aria-expanded="false" aria-controls="collapseTwo">
                                <i className="fa fa-caret-right" aria-hidden="true"></i>Specialist Fees</a>

                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                             data-parent="#accordionExample">
                            <div className="box-body">
                                <ul className="nav">
                                    {specialistServicesElement}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            services: state.general.services
        };
    },
    {}
)(FeeScheduleTypes);