import React, {Component} from 'react';
import Image from "react-shimmer";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import Loader from "./Loader";
import connect from "react-redux/es/connect/connect";
import {addMember, getMembers} from "../actions/user";
import {GOOGLE_API_KEY} from "../constants";
import {NavLink} from "react-router-dom";
import {logout} from "../actions/user";

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.handleLogout = this.handleLogout.bind(this);
        // this.renderNavLink = this.renderNavLink.bind(this);

    }

    handleLogout(e) {
        e.preventDefault();
        this.props.logout();
    }

    render() {
        let user = this.props.user || null;
        if (user == null) {
            return <Loader/>;
        }
        let lat = user.details.latitude != null && user.details.latitude != "" ? user.details.latitude : 18.5204;
        let lng = user.details.longitude != null && user.details.longitude != "" ? user.details.longitude : 73.8567;
        let url = "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_API_KEY + "&libraries=places";
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap style={{
                        position: 'relative',
                    }} google={this.props.google}
                               defaultZoom={15}
                               defaultCenter={{
                                   lat: Number(lat),
                                   lng: Number(lng)
                               }}>

                        <Marker google={this.props.google}
                                name={'Dolores park'}
                                draggable={false}
                                position={{
                                    lat: Number(lat),
                                    lng: Number(lng)
                                }}
                        />
                        <Marker/>

                    </GoogleMap>

                )
            )
        );
        let map = <div>
            <AsyncMap
                googleMapURL={url}
                loadingElement={
                    <div style={{height: `100%`}}/>
                }
                containerElement={
                    <div style={{height: 300}}/>
                }
                mapElement={
                    <div style={{height: `100%`}}/>
                }
            />
        </div>
        return (
            <div className="col-md-3">
                <div className="side">
                    <div className="pic">
                        <Image
                            src={user.details.image_url + '?w=120&h=120'}
                            width={120} height={120}/>
                        {/* <a className="edit" href="#">
                                                    <i className="fa fa-camera" aria-hidden="true"></i>
                                                    <input type="file" name="img" onChange={this.handleImage}/>
                                                </a>*/}
                    </div>
                    <div className="det text-center">
                        <h2>{user.details.first_name} {user.details.last_name}</h2>
                        {/*<h5>{user.role}</h5>*/}
                        <p><i className="fa fa-map-marker"
                              aria-hidden="true"></i>{user.details.address}
                            {user.details.city}
                            {/*{this.state.zipcode}*/}
                            {/*{this.state.country}*/}
                        </p>
                    </div>
                    <div className="map-box">
                        {map}
                    </div>
                    <div className="add-btn">

                        <a href="#" onClick={this.handleLogout}>Logout</a>
                    </div>
                </div>
                <div className="side-btm">
                    <ul>
                        <li><a href="#">Settings & Privacy</a></li>
                        <li><a href="#">Help</a></li>
                        <li><a href="#">Language</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
        };
    }, {logout}
)(Sidebar);