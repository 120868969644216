import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {contact} from "../actions/general";
import Alerts from "./Alerts";
import axios from "axios";
import {toast} from "react-toastify";
import {DEFAULT_ADDRESS, DEFAULT_FAX, DEFAULT_PHONE, DEFAULT_TIMING, BASE_URL, ENDPOINTS} from "../constants";


class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.handleName = this.handleChangeText.bind(this, "name");
        this.handleEmail = this.handleChangeText.bind(this, "email");
        this.handlePhone = this.handleChangeText.bind(this, "phone");
        this.handleCity = this.handleChangeText.bind(this, "city");
        this.handleAddress = this.handleChangeText.bind(this, "address");
        this.handleState = this.handleChangeText.bind(this, "state");
        this.handleZip = this.handleChangeText.bind(this, "zip");
        this.handleText = this.handleChangeText.bind(this, "message");
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            name: '',
            email: '',
            phone: '',
            city: '',
            address: '',
            state: '',
            zip: '',
            message: '',
            states: []
        }
    }
    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.contact(this.state);
    }

    componentDidMount() {
        axios.get(BASE_URL + ENDPOINTS.GET_STATES).then((response) => {
            let data = response.data;
            let updatedStates = data.data.map(state => {
                return {
                    ...state,
                    twoCode: state["2_code"]
                };
            });
            this.setState({ states: updatedStates });
            console.log(this.state.states);

        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.success && nextProps.success) {
            toast.info(nextProps.successMsg)
            var elmnt = document.getElementById("contact-form");
            elmnt.reset();
            this.setState({email: ""})
            elmnt.scrollIntoView();
        }


    }

    render() {
        let settings = this.props.settings || false;
        return (
            <section className="contact-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="lf">
                                            <h3>Send a Message</h3>
                                            {/*{this.props.error &&
                                            <Alerts alerts={[{
                                                type: "danger",
                                                message: this.props.error.message
                                            }]}/>}
                                            {this.props.success &&
                                            <Alerts alerts={[{
                                                type: "success",
                                                message: this.props.successMsg
                                            }]}/>}*/}
                                            <form onSubmit={this.handleSubmit} id="contact-form"
                                                  style={{position: "relative"}}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="text" name="" placeholder="Your Name"
                                                               onChange={this.handleName} required/>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input type="email" name="" placeholder="Email"
                                                               value={this.state.email}
                                                               onChange={this.handleEmail}
                                                               required/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="number" name=""
                                                               placeholder="Phone Number" onChange={this.handlePhone}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" name="" placeholder="City"
                                                               onChange={this.handleCity}/>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input type="text" name="" placeholder="Address"
                                                               onChange={this.handleAddress}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleState} required>
                                                            <option disabled selected>Select a State
                                                            </option>
                                                            {this.state.states.map( (state, key) => {
                                                                return <option value={state.twoCode} key={key}>{state.name}</option>
                                                            }  )}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="number" name="" placeholder="Zip"
                                                               onChange={this.handleZip}/>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <textarea placeholder="How can we help?"
                                                                  onChange={this.handleText}></textarea>
                                                    </div>
                                                    <div className="col-md-12">
                                                        {this.props.request ?
                                                            <i className="fas fa-spinner fa-spin custom-spinner"></i> :
                                                            <input type="submit" name="" value="Send"/>}

                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="rt">
                                            <h3>Information</h3>
                                            <ul>
                                                <li>
                                                    <h5>Address:</h5>
                                                    <p>{settings ? settings.translations[0].address : DEFAULT_ADDRESS}</p>
                                                </li>
                                                {/* <li>
                                                    <h5>Phone: </h5>
                                                    {settings ?
                                                        <a href={"tel:" + settings.phone}>{settings.phone}</a> :
                                                        <a href={"tel:" + DEFAULT_PHONE}>{DEFAULT_PHONE}</a>}

                                                </li>*/}
                                                <li>
                                                    <h5>Phone: </h5>
                                                    <p>{settings ? settings.fax : DEFAULT_FAX}</p>
                                                </li>
                                                <li>
                                                    <h5>Contact Hours</h5>
                                                    <p>{settings ? settings.timing : DEFAULT_TIMING}</p>
                                                </li>
                                            </ul>
                                            <ul className="soc">
                                                <h5>Connect with us</h5>
                                                <li><a
                                                    href={settings ? settings.facebook : "https://www.facebook.com/pages/Mainline-Dental-Plan/193138224059330"}
                                                    target="_blank"><i
                                                    className="fa fa-facebook-official"></i></a>
                                                </li>
                                                <li><a
                                                    href={settings ? settings.google_plus : "https://plus.google.com/109791109016979256553"}
                                                    target="_blank"><i
                                                    className="fa fa-google"></i></a></li>
                                                <li><a
                                                    href={settings ? settings.twitter : "https://twitter.com/mainlinedental"}
                                                    target="_blank"><i
                                                    className="fa fa-twitter"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<ToastContainer position="top-center"/>*/}
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            request: state.general.contactRequest,
            success: state.general.contactSucces,
            failed: state.general.contactFailed,
            error: state.general.error,
            successMsg: 'Thanks for contacting us we will reach you soon',
            settings: state.settings ? state.settings.settings : state.general.settings,
        };
    }, {contact}
)(ContactForm);