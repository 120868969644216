import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {forgot} from "../actions/user";
import Alerts from "../components/Alerts";
import {NavLink} from "react-router-dom";
import DashboardHeader from "../components/DashboardHeader";
import Navigation from "../components/Navigation";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.handleChangeEmail = this.handleChangeText.bind(this, "email");
        this.handleForgot = this.handleForgot.bind(this);
        this.state = {
            email: ""
        }
    }


    handleChangeText(key, txt) {
        let state = {};
        state[key] = txt.target.value;
        this.setState(state);
    }

    handleForgot(e) {
        e.preventDefault();
        let email = this.state.email;
        if (email.length > 0) {
            this.setState({alert: false, message: ''})
            this.props.forgot(this.state.email);
        } else {
            this.setState({alert: true, message: 'Email field is required'})
        }

        return false;
    }

    render() {
        return (
            <div>
                <section className="sec-login">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-5 padright">
                                <div className="lf">
                                    <img src={require("../../images/acount-banner.png")}/>
                                </div>
                            </div>

                            <div className="col-md-7 padleft">
                                <div className="rt">
                                    <div className="inner">
                                        <NavLink to="/"><img className="logo" src={require("../../images/logo.png")}/></NavLink>

                                        {this.state.alert &&
                                        <Alerts alerts={[{
                                            type: "danger",
                                            message: this.state.message
                                        }]}/>}
                                        {this.props.failed &&
                                        <Alerts alerts={[{type: "danger", message: this.props.error.message}]}/>}
                                        {this.props.success &&
                                        <Alerts alerts={[{type: "success", message: this.props.successMsg}]}/>}
                                        <h3 className="tit_sign">Forgot Password</h3>
                                        <p className="txt_sign">Enter your email address here</p>
                                        <form onSubmit={this.handleForgot}>
                                            <div className="field user">
                                                <input name="email" type="email" value={this.state.email}
                                                       onChange={this.handleChangeEmail}
                                                       placeholder="Email"/>
                                            </div>

                                            <div className="btn_forgot field">
                                                {this.props.request
                                                    ?
                                                    <input type="submit" name="Submit" value="Submitting"
                                                           className="btn_style"
                                                           disabled/>
                                                    : <input type="submit" name="Submit" value="Submit"
                                                             className="btn_style"/>

                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

ForgotPassword.propTypes = {};

export default connect(
    (state) => {
        return {
            request: state.user.forgotRequest,
            success: state.user.forgotSuccess,
            failed: state.user.forgotFailed,
            error: state.user.error,
            successMsg: state.user.forgotMsg
        };
    },
    {forgot}
)(ForgotPassword);
