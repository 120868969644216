import React, {Component} from 'react';
import Markup from "interweave/lib/Markup";

class FaqItems extends Component {
    componentDidMount() {
        if (this.props.hash == "#cancellation") {
            var elmnt = document.getElementById("cancellation");
            if (elmnt) {
                //document.querySelectorAll('[data-target="#8collapse"]')[0].click()
                elmnt.scrollIntoView();
            }
        }
    }

    render() {
        let {faq} = this.props;
        let question = faq.question || null;
        return (
            <div className="card" id={question.includes("CANCELLATION") ? "cancellation" : null}>
                <div className="card-header" id={"heading" + faq.id}>
                    <button className={"btn-link " + (this.props.open == 0 ? "" : "collapsed")} type="button"
                            data-toggle="collapse"
                            data-target={"#" + faq.id + "collapse"}
                            aria-expanded="true" aria-controls="collapseOne">
                        {faq.question}
                    </button>
                </div>

                <div id={faq.id + "collapse"} className={"collapse " + (this.props.open == 0 ? "show" : "")}
                     aria-labelledby={"heading" + faq.id}
                     data-parent="#accordionExample">
                    <div className="card-body">
                        <p><Markup content={faq.answer}/></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqItems;