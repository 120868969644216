import {push} from 'connected-react-router'

export function gotoHome() {
    return push("/");
}

export function gotoVerifyResetCode() {
    return push("/verify-reset-code");
}

export function gotoResetPassword() {
    return push("/reset-password");
}

export function gotoLogin() {
    return push("/login");
}

/*export function gotoProfile(id) {
    return id !== undefined ? push("/profile/" + id) : push("/profile");
}*/

export function gotoAllGames() {
    return push("/games/all");
}

export function gotoGame(id) {
    return push("/game/" + id);
}

export function gotoNews(id) {
    return push("/news/" + id);
}

export function gotoEditProfile() {
    return push("/profile/");
}

export function gotoProfile() {
    return push("/profile");
}

export function gotoStep(step) {
    return push("/dentist-registration/" + step);
}

export function gotoThanks() {
    return push("/dentist-registered");
}

export function gotoStripe() {
    return push("/payment");
}

export function gotoSubscription() {
    return push("/subscription");
}

export function gotoPaymentSuccess() {
    return push("/payment-success");
}


// TODO: Add Route Action to Redirect User to Reset Password Screen