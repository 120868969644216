import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {getSettings} from "../actions/general";
import Loader from "./Loader";
import {logout} from "../actions/user";
import SweetAlert from 'sweetalert-react';
import '../../../node_modules/sweetalert/dist/sweetalert.css';
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import Image from "react-shimmer";
import {DEFAULT_ADDRESS, DEFAULT_EMAIL, DEFAULT_PHONE, DEFAULT_TIMING, DEFAULT_FAX} from "../constants";

class App extends Component {
    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleNav = this.handleNav.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            alert: false,
            show: false,
            mobile: false,
            search: false,
            showModal: false,
            selectedOption: null,
            searching: false,
            searchQuery: {value: null, games: false, news: false, people: false},
            searchCallback: null,
        };
    }

    renderNavLink(url, text) {
        return (<li className={"nav-item" + (this.props.pathname == url ? " active" : "")}>
            <NavLink to={url} className={"nav-link" + (this.props.pathname == url ? " active" : "")}>{text}</NavLink>
        </li>);
    }

    renderMobNavLink(url, text) {
        var element = document.getElementsByTagName("html")[0];
        element.classList.remove("open-menu");
        return (
            <li className={this.props.pathname == url ? " current-page" : ""}><NavLink to={url}>{text}</NavLink></li>);
    }


    handleLogout() {
        this.props.logout();
        this.setState({alert: true, show: true})
    }

    handleNav() {
        if (this.state.mobile) {
            this.setState({mobile: false});
        } else {
            this.setState({mobile: true});
        }
    }

    handleSearch() {
        this.setState({search: true, showModal: true})
    }

    handleShow(e) {
        this.setState({showModal: true});
    }

    handleClose() {
        this.setState({showModal: false});
    }

    render() {
        let {user} = this.props;
        let settings = this.props.settings || false;
        let phone = settings ? "tel:" + settings.phone : "tel:" + DEFAULT_PHONE;
        let fax = settings ? "tel:" + settings.fax : "tel:" + DEFAULT_FAX;
        let email_to = settings ? "mailto:" + settings.email : "mailto:" + DEFAULT_EMAIL;
        let email = settings ? settings.email : DEFAULT_EMAIL;
        return (
            <div>
                <a id="hamburg" href="#" onClick={(e) => {
                    e.preventDefault()
                    var element = document.getElementsByTagName("html")[0];
                    element.classList.toggle("open-menu");
                }}>
                    <div className="nav-cross">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <div id="menuoverlay"></div>
                <nav className="nav1">
                    <div className="container nopad">
                        <div className="row">
                            <div className="menu-main-menu-container">
                                <div className="logo">
                                    <NavLink to="/"><img src={require("../../images/logo.png")}/></NavLink>
                                </div>
                                <ul id="menu-main-menu" className="menu">
                                    {this.renderMobNavLink("/", "Home")}
                                    {this.renderMobNavLink("/membership", "Membership Info")}
                                    {this.renderMobNavLink("/professionals", "Professionals")}
                                    {this.renderMobNavLink("/faqs", "FAQs")}
                                    {this.renderMobNavLink("/verify-eligibility", "Verify Eligibility")}
                                    {this.renderMobNavLink("/fee-schedules", "Fee Schedule")}
                                    {/*{this.renderMobNavLink("/", "Blog")}*/}
                                    {this.renderMobNavLink("/contact", "Contact Us")}
                                    {/*                                    {this.renderMobNavLink("/register", "Sign Up")}*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <header>
                    {this.state.alert && <SweetAlert
                        show={this.state.show}
                        title=""
                        text="Logout Successfully"
                        onConfirm={() => this.setState({show: false})}
                    />}

                    <div className="head-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <ul className="cta text-center">
                                        <li>
                                            <i className="fa fa-fax" aria-hidden="true"></i>
                                            <span className="dt">Phone: </span>
                                            {settings ?
                                                <a className="email-class" href={fax}>{settings ? settings.fax : DEFAULT_FAX}</a> :
                                                <a className="email-class" href={"tel:" + DEFAULT_FAX}> {DEFAULT_FAX}</a>}

                                            <span
                                                className="time">{settings ? settings.timing : DEFAULT_TIMING}</span>
                                        </li>

                                        <li>
                                           <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                            <a href={email_to}>{email}</a>
                                        </li>
                                        <li><i className="fa fa-map-marker" aria-hidden="true"></i>
                                            {settings ? <span
                                                    className="dt">{settings ? settings.translations[0].address : DEFAULT_ADDRESS}</span> :
                                                <span className="dt">{DEFAULT_ADDRESS}</span>}

                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 padright">
                                    <div className="top-btn">
                                        {this.props.user.loggedIn ?
                                            <div className="head-info text-right">
                                                <ul>
                                                    <li className="prof-opt">
                                                        <div className="image-box">
                                                            <NavLink to="/profile">
                                                                <img src={user.details.image_url}
                                                                     className="header-image"/>
                                                            </NavLink>
                                                        </div>

                                                        <div className="dropdown">
                                                            <NavLink to="/profile"
                                                                     className="dropbtn">{user.details.first_name} {user.details.last_name}</NavLink>
                                                            <div className="dropdown-content">
                                                                <NavLink to="/search-dentist">Search Dentists</NavLink>
                                                                <a href="#" onClick={this.handleLogout}>Logout</a>
                                                            </div>
                                                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                        </div>


                                                    </li>
                                                </ul>
                                            </div> :
                                            <div>
                                                {/* <button className="signup"><NavLink to="/login">LOGIN</NavLink>
                                                </button> */}
                                                {/*<button className="signup"><NavLink to="/register">SIGN UP</NavLink>
                                                </button>*/}
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-nav">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="logo">
                                        <NavLink to="/"><img src={require("../../images/logo.png")}/></NavLink>
                                    </div>
                                </div>

                                <div className="col-md-9">

                                    <div className="main-nav website-menu">
                                        <nav className="navbar navbar-expand-sm  navbar-light bg-light">

                                            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                                                <ul className="navbar-nav ml-auto">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            className={this.props.pathname == "/" ? "nav-link active" : "nav-link"}
                                                            to="/home">Home</NavLink>
                                                    </li>

                                                    <li className="nav-item dropdown dmenu membership-menu">
                                                        <NavLink className="nav-link dropdown-toggle" to="/membership"
                                                                 id="navbardrop">
                                                            Membership
                                                        </NavLink>
                                                        <div className="dropdown-menu sm-menu">
                                                            {/*<NavLink className="dropdown-item" to="/register">Sign
                                                                Up</NavLink>*/}
                                                            <NavLink className="dropdown-item" to="/find-dentist">Find
                                                                a
                                                                Dentist</NavLink>
                                                            <NavLink className="dropdown-item" to="/fee-schedules">Fee Schedule</NavLink>
                                                            <NavLink className="dropdown-item" to="/example-of-savings">Examples
                                                                of
                                                                Savings</NavLink>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown dmenu eligibilty-menu">
                                                        <NavLink className="nav-link dropdown-toggle"
                                                                 to="/verify-eligibility" id="navbardrop">
                                                            Verify Eligibility
                                                        </NavLink>
                                                        <div className="dropdown-menu sm-menu">
                                                            <NavLink className="dropdown-item"
                                                                     to="/professionals">Professionals</NavLink>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown dmenu faq-menu">
                                                        <NavLink className="nav-link dropdown-toggle"
                                                                 to="/faqs" id="navbardrop">
                                                            FAQs
                                                        </NavLink>
                                                        <div className="dropdown-menu sm-menu">
                                                            <NavLink className="dropdown-item"
                                                                     to="/contact">Contact Us</NavLink>
                                                        </div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </nav>
                                        {/*                                        <ul className="text-right">
                                            {this.renderNavLink("/", "HOME")}
                                            {this.renderNavLink("/membership", "Membership Info")}
                                            {this.renderNavLink("/professionals", "Professionals")}
                                            {this.renderNavLink("/faqs", "FAQs")}
                                            {this.renderNavLink("/verify-eligibility", "Verify Eligibility")}
                                            {this.renderNavLink("/fee-schedules", "Fee Schedules")}
                                            {this.renderNavLink("/contact", "CONTACT US")}
                                        </ul>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </header>
            </div>
        )
            ;
    }
}


export default connect(
    (state) => {
        return {
            user: state.user, pathname: state.router.location.pathname,
            settings: state.general.settings
        };
    },
    {logout}
)(App);
